import { Button, Form } from "react-bootstrap"
import { ObservationChannel, ObservationChannelType } from "../../utils/types"
import { ChannelNameEditor } from "./ChannelNameEditor"
import { FC_CLASSES } from "./NetworkConfiguration"
import { PositionConfigEditor } from "./PositionConfigEditor"

export interface ExtendedConfigEditorProps {
    channelName: string
    channel: ObservationChannel
    updateChannel: (channelName: string, channelType: ObservationChannel) => void
    editable: boolean
}


function isPositionChannel(channel: ObservationChannel) {
    return channel.channelType === ObservationChannelType.POSITION
}

function ExtendedConfigEditor({ editable, channelName, channel, updateChannel }: ExtendedConfigEditorProps) {
    return <>
        {channel.unit && <>
        	<Form.Label htmlFor="unit">unit</Form.Label> 
        	<Form.Control id="unit" disabled={!editable} className={!editable ? "" : FC_CLASSES} type="text" value={channel.unit}
        		onChange={(e) => 
        			{
            			channel.unit = e.target.value
            			updateChannel(channel.name, channel)
        			}
        		} />
        </>}
        {isPositionChannel(channel) && <PositionConfigEditor editable={editable} channelName={channelName} channel={channel} updateChannel={updateChannel} />}
    </>

}


interface ChannelConfigurationProps {
    prefix?: string | undefined
    channel: ObservationChannel | undefined
    onDelete: (name: string) => void
    updateChannel: (channelName: string, channelType: ObservationChannel) => void
    fixed: boolean // the element must be there. It cannot be deleted and the type is preset
    nameEditingDisabled: boolean
    editable: boolean
}


export function ChannelConfiguration({ prefix, editable, channel, onDelete, updateChannel, nameEditingDisabled = true, fixed = false }: ChannelConfigurationProps) {
    if (channel) {
        return <>
            {prefix && <tr className="fw-bold"><td className="bg-insitu" colSpan={100}>{prefix}</td></tr>}
            <tr className={prefix ? "bg-insitu" : ""}>
                <td >
                    <ChannelNameEditor channelName={channel.name} onChange={(newName) => {
                        channel.name = newName
                        updateChannel(channel.name, channel)
                    }} disabled={nameEditingDisabled || !editable} />
                </td>
                <td >
                    <Form.Control disabled={!editable} className={!editable ? "" : FC_CLASSES} type="text" value={channel.label} onChange={(e) => {
                        channel.label = e.target.value
                        updateChannel(channel.name, channel)
                    }} />
                </td>
                <td >
                    <Form.Control disabled={!editable || fixed} className={!editable || fixed ? "" : FC_CLASSES} as="textarea"
                        defaultValue={channel.description} onChange={(e) => {
                            channel.description = e.target.value
                            updateChannel(channel.name, channel)
                        }} />
                </td>
                <td >
                    {fixed && <div>{channel.channelType}</div>}
                    {!fixed && <select disabled={!editable} className={!editable ? "" : FC_CLASSES} aria-label="Default select example"
                        defaultValue={channel.channelType} onChange={(e) => {
                            channel.channelType = ObservationChannelType[e.target.value as ObservationChannelType]
                            updateChannel(channel.name, channel)
                        }}>
                        <option>Select channel type</option>
                        {Object.keys(ObservationChannelType).map((key) => <option key={key} value={ObservationChannelType[key as keyof typeof ObservationChannelType]}>{key}</option>)}

                    </select>}
                </td>
                <td >
                    {prefix && <div className="fw-bold">{prefix}</div>}
                    <ExtendedConfigEditor editable={editable} channelName={channel.name} channel={channel} updateChannel={updateChannel} />
                </td>
                <td >
                    {(!fixed) && <Button disabled={!editable} onClick={() => onDelete(channel.name)}>Delete</Button>}
                </td>
            </tr>
        </>
    }
    else {
        return <div>Misconconfigured network!</div>
    }
}