/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import toBBox from 'geojson-bounding-box'
import { MapContainer, GeoJSON, LayerGroup, TileLayer } from "react-leaflet";
import { MERCATOR_PROJ, ARCTIC_PROJ, ANTARCTIC_PROJ, projections } from './Projections'
import { toPresentationGeometry } from './MapView';

/**
 * Visualizes a single product in a map.
 */
function ProductInMap({ product, pathClass }) {

	const featureData = {
		"type": "Feature",
		"properties": {},
		"geometry":  toPresentationGeometry(product.spatialCoverage)
	}
	return <GeoJSON className={pathClass} data={featureData} key={product.id} />
}

/**
 * A layer of footprints.
 */

function Footprint({ product }) {
	return <LayerGroup>
		<ProductInMap pathClass="product-footprint" product={product} key={product.id} />
	</LayerGroup>

}

function fitMapBoundsToProductWithMargin(map, product) {
	let minBox = toBBox(product.spatialCoverage)
	map.fitBounds([
		[minBox[1]-1, minBox[0]-1],
		[minBox[3]+1, minBox[2]+1]
	]);
}

function MapBaseLayers({ baseConfig, children }) {

	return <>
		<TileLayer
			url={baseConfig.layers[0].url}
			minZoom={1}
			maxZoom={baseConfig.layers[0].maxZoom}
			{... (baseConfig.tileSize && { tileSize: baseConfig.tileSize })}
		/>
		{children}
	</>

}

/**
 * Displays a map of product footprints.
 * Accepts zero or more products as input.
 */
export function MapViewSimple({ product }) {

	if (product.spatialCoverage == null) {
		return <div>Sorry, we do not have a footprint for this data product.</div>
	} else {

		let minBox = toBBox(product.spatialCoverage)
		let projection = projections.get(MERCATOR_PROJ)

		if (minBox[1] > 75) {
			projection = projections.get(ARCTIC_PROJ)
		}

		if (minBox[3] < -75) {
			projection = projections.get(ANTARCTIC_PROJ)
		}
		
		return <MapContainer className='h-100 w-100 simple-map' 
						center={projection.center} zoom={2} 
						dragging={false}
						zoomControl={false} attributionControl={false} scrollWheelZoom={false}
			whenReady={(event) => {
				fitMapBoundsToProductWithMargin(event.target, product);
			}
			}
			{... (projection.crs && { crs: projection.crs })}  >
			<MapBaseLayers baseConfig={projection} >
				<Footprint product={product} />
			</MapBaseLayers>
		</MapContainer>
	}
}




