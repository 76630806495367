/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { AttributesEnum } from "../utils/ApiTypes";
import { ProductFilter, ProductFilterOperator, ProductProductClassEnum } from "../utils/types";
import { ProductQuerySpecType } from "./storeTypes";

/**
 * Contains convenience functions for data products filter.
 */

export const OP_LT = ProductFilterOperator.Lt;
export const OP_LTE = ProductFilterOperator.Lte // "<=";
export const OP_GT = ProductFilterOperator.Gt  // ">";
export const OP_GTE = ProductFilterOperator.Gte // ">=";
export const OP_EQ = ProductFilterOperator.Eq // "==";
export const OP_IN = ProductFilterOperator.IN // "IN";
export const OP_CONTAINS = ProductFilterOperator.CONTAINS // "CONTAINS";
export const OP_LIKE = ProductFilterOperator.LIKE // "LIKE";
export const OP_NOT_LIKE = ProductFilterOperator.NOTLIKE
export const OP_NEQ = ProductFilterOperator.Neq
export const OP_NOT_CONTAINS = ProductFilterOperator.NOTCONTAINS
export const OP_NOT_IN = ProductFilterOperator.NOTIN

export const OPERATOR_PRESENTATION = new Map([
	[OP_LT, "<"],
	[OP_LTE, "<="],
	[OP_GT, ">"],
	[OP_GTE, ">="],
	[OP_EQ, "=="],
	[OP_NEQ, "!="],
	[OP_IN, "in"],
	[OP_NOT_IN, "!in"],
	[OP_CONTAINS, "contains"],
	[OP_NOT_CONTAINS, "!contains"],
	[OP_LIKE, "like"],
	[OP_NOT_LIKE, "!like"],
]) as Map<string, string>

export const CHANNEL_PREFIX = "channel."

export function inSituKeyPresentationName(key: string) {
	if (key.startsWith(CHANNEL_PREFIX)) {
		return getChannelShortKeyFromKey(key)
	} else {
		return key
	}
}

export function getChannelShortKeyFromKey(channelKey: string) {
	return channelKey.slice(CHANNEL_PREFIX.length, -4)
}

export function getChannelKeyWithoutPropertyFromKey(channelKey: string) {
	return channelKey.slice(0, -4) + ".values"
}


export function getChannelPropertyFromKey(channelKey: string) {
	return channelKey.slice(-3)
}


export function newAttributeFilter(key: string, operator: ProductFilterOperator = ProductFilterOperator.Eq, value: any = ""): ProductFilter {
	return { key: key, op: operator, value: value }
}

export function isStringValue(value: string | undefined) {
	// returns true if the input is a string and not a number
	// if there is no input yet, it can still become a number and true is returned
	if (value === "") {
		return false
	}
	else {
		return isNaN(parseFloat(value ?? "0"))
	}
}

export function classWithNameMatchCondition(query: ProductQuerySpecType) {
	if (hasExactNameMatchCondition(query.eoFilters)) {
		return ProductProductClassEnum.EO
	} else if (hasExactNameMatchCondition(query.insituFilters)) {
		return ProductProductClassEnum.InSitu
	} else {
		return null
	}
}

export function hasExactNameMatchCondition(attributeFilters: Array<ProductFilter>) {
	const productNameCondition = attributeFilters.find((cond) => cond.key === AttributesEnum.productName)
	if (productNameCondition?.value) {
		if (productNameCondition.value.length > 20) {
			if (productNameCondition.value.match(/[*,?]/g)) {
				return false
			} else {
				return true
			}
		} else {
			return false
		}
	} else {
		return false
	}
}

export function postProcessInsituFilters(attributeFilters: Array<ProductFilter>): Array<ProductFilter> {
	// correct filter for like operator

	return attributeFilters.map((af) => {
		if (!af.key.startsWith("channel.")) {
			return af
		}
		if (isStringValue(af.value)) {
			return { key: getChannelKeyWithoutPropertyFromKey(af.key), op: af.op, value: af.value ?? "" }
		} else {
			return af
		}
	}
	)


}

export function getEoProductAttributeCondition(productFilter: any, attributeName: string) {
	const filtered = productFilter.eoFilters.filter((cond: ProductFilter) => cond.key === attributeName);
	if (filtered.length > 0) {
		return filtered[0];
	} else {
		return null;
	}
}

export function getInsituProductAttributeCondition(productFilter: any, attributeName: string) {
	const filtered = productFilter.insituFilters.filter((cond: ProductFilter) => cond.key === attributeName);
	if (filtered.length > 0) {
		return filtered[0];
	} else {
		return null;
	}
}

export function getAttributeCondition(itemFilter: Array<ProductFilter>, attributeName: string) {
	const filtered = itemFilter.filter((cond) => cond.key === attributeName);
	if (filtered.length > 0) {
		return filtered[0];
	} else {
		return null;
	}
}

export function hasAttributeCondition(itemFilter: Array<ProductFilter>, attributeName: string) {
	return getAttributeCondition(itemFilter, attributeName) != null;
}

export function replaceEoProductAttributeCondition(productFilter: any, attributeName: string, newCondition: ProductFilter | null) {
	const filtered = replaceInArray(productFilter.eoFilters, attributeName, newCondition)
	return { ...productFilter, eoFilters: filtered };
}

export function replaceInsituProductAttributeCondition(productFilter: any, attributeName: string, newCondition: ProductFilter | null) {
	// for in situ conditions we need to maintain array order
	// we need a copy because the original may not be writable
	var conditionArray = [...productFilter.insituFilters] as Array<ProductFilter>
	if (newCondition) {
		const index = conditionArray.findIndex((cond) => cond.key === attributeName);
		if (index > -1) {
			conditionArray[index] = newCondition
		} else {
			conditionArray.push(newCondition)
		}
	} else {
		conditionArray = conditionArray.filter((cond) => cond.key !== attributeName);
	}
	return { ...productFilter, insituFilters: conditionArray };
}

export function replaceInArray(conditionArray: Array<ProductFilter>, attributeName: string, newCondition: ProductFilter | null) {

	const filtered = conditionArray.filter((cond) => cond.key !== attributeName);
	if (newCondition) {
		return [...filtered, newCondition];
	} else {
		return filtered
	}
}

export function hasEoProductAttributeCondition(productFilter: any, attributeName: string) {
	return getEoProductAttributeCondition(productFilter, attributeName) != null;
}

export function hasInsituProductAttributeCondition(productFilter: any, attributeName: string) {
	return getInsituProductAttributeCondition(productFilter, attributeName) != null
}
