/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { BDefault } from '../../common/StyleSettings';
import { InsituNetworkSelection, ProductTypesSelection } from './ProductTypesSelection'
import { ProductTypesListSelector } from './ProductTypesListSelector'
import { AreaOfInterestEditor } from '../AreaOfInterestEditor/AreaOfInterestEditor'

import {
	OP_LT, OP_LTE, OP_GT, OP_GTE, OP_EQ, OP_IN, OP_LIKE, getAttributeCondition, 
	hasAttributeCondition, newAttributeFilter, OP_NEQ, OP_NOT_LIKE, CHANNEL_PREFIX, getChannelShortKeyFromKey, 
	getChannelPropertyFromKey, isStringValue,
} from '../../../state/dataProducts-utils'
import { AttributesEnum } from '../../../utils/ApiTypes';
import { ProductFilter, ProductFilterOperator } from '../../../utils/types';
import { BoundingBoxType } from '../../../utils/leafletUtils';


interface ClearFilterButtonProps {
	clearFilter: any
}

function ClearFilterButton({ clearFilter }: ClearFilterButtonProps) {
	return <Button variant={BDefault} className="p-1 badge" onClick={clearFilter} >&times;</Button>
}

function ClearAdditionalFilterButton({ clearFilter }: ClearFilterButtonProps) {
	return <Button variant={BDefault} className="p-1 badge" onClick={clearFilter} >&times;</Button>
}

interface AttributeFilterWrapperProps {
	label: any
	operatorEditor: any
	valueEditor: any
	clearFilter: any
	preparationMode: boolean
}
function AttributeFilterWrapper({ label, operatorEditor, valueEditor, clearFilter, preparationMode }: AttributeFilterWrapperProps) {
	return < Form.Group as={Col} xs={12} md={preparationMode ? 12 : 6} lg={12} controlId="productName" className="">

		<Row className="mr-2 my-1 px-1 align-items-center">
			<Col className="pr-1">{label}</Col>
			<Col className="p-0 col-auto">{operatorEditor}</Col>
			<Col className="">{valueEditor}</Col>
			{(!preparationMode) && <Col className="col-auto"><ClearFilterButton clearFilter={clearFilter} /></Col>}
		</Row>
	</Form.Group>
}

interface OperatorItemProps {
	operatorKey: string
}
/**
  Generic filters
 */

function OperatorItem({ operatorKey }: OperatorItemProps) {
	return <Dropdown.Item eventKey={operatorKey} as="button" onClick={(e) => e.preventDefault()}>{operatorKey}</Dropdown.Item>
}

interface NumericalOperatorDropdownButtonProps {
	operator: string
	onSelect: any
}

/**
 * A generic numeric filter element.
 */
function NumericalOperatorDropdownButton({ operator, onSelect }: NumericalOperatorDropdownButtonProps) {

	return <DropdownButton variant={BDefault} id="dropdown-item-button" title={operator} onSelect={onSelect} >
		<OperatorItem operatorKey={OP_EQ} />
		<OperatorItem operatorKey={OP_LT} />
		<OperatorItem operatorKey={OP_LTE} />
		<OperatorItem operatorKey={OP_GT} />
		<OperatorItem operatorKey={OP_GTE} />
		<OperatorItem operatorKey={OP_NEQ} />
		{
			/*
			<OperatorItem operatorKey={OP_LIKE} />
			<OperatorItem operatorKey={OP_NOT_LIKE} />
			<OperatorItem operatorKey={OP_CONTAINS} />
			<OperatorItem operatorKey={OP_NOT_CONTAINS} />
			*/
		}
	</DropdownButton>
}

interface NumericalAggregationPropertySelectorProps {
	selector: string
	onSelect: any
}

/**
 * A generic numeric filter element.
 */
function NumericalAggregationPropertySelector({ selector, onSelect }: NumericalAggregationPropertySelectorProps) {

	return <DropdownButton variant={BDefault} id="dropdown-item-button" title={selector} onSelect={onSelect} >
		<OperatorItem operatorKey={"min"} />
		<OperatorItem operatorKey={"max"} />
		<OperatorItem operatorKey={"avg"} />
	</DropdownButton>
}

interface NumberInputProps {
	value: number
	setValue: (v: number) => void
}

function NumberInput({ value, setValue }: NumberInputProps) {
	return <Form.Control as="input" min="0" htmlSize={3} type="number"
		value={value ?? ""}
		onChange={(e) => {
			setValue(Number(e.target.value))
		}}
		onKeyDown={(event) => {
			if (event.key.toLowerCase() === "enter") {
				document.body.click()
			}
		}}
	/>

}


function PercentInput({ value, setValue }: NumberInputProps) {
	return <Form.Control as="input" htmlSize={3} min="0" max="100" step="1" type="number"
		value={value ?? ""}
		onChange={(e) => {
			setValue(Number(e.target.value))
		}}
		onKeyDown={(event) => {
			if (event.key.toLowerCase() === "enter") {
				document.body.click()
			}
		}}
	/>

}

interface AnyInputProps {
	value: string
	setValue: (v: string) => void
}


function AnyInput({ value, setValue }: AnyInputProps) {
	return <Form.Control as="input" type="text"
		value={value ?? ""}
		onChange={(e) => {
			setValue(e.target.value)
		}}
		onKeyDown={(event) => {
			if (event.key.toLowerCase() === "enter") {
				document.body.click()
			}
		}}
	/>

}


function operatorSetOrDefault(condition: ProductFilter | null) {
	return condition?.op ? condition?.op : OP_EQ
}

interface NumericFilterProps {
	attributeName: string
	label: any
	condition: ProductFilter | null
	onChange: any
	preparationMode: boolean
	percent: boolean
}

export function NumericFilter({ attributeName, label, condition, onChange, preparationMode, percent = false }: NumericFilterProps) {

	const onSelectOperator = (operator: ProductFilterOperator) => {
		const newCond = newAttributeFilter(attributeName, operator, condition?.value)
		onChange(attributeName, newCond);
	}

	const onChangeValue = (value: number) => {
		const op = operatorSetOrDefault(condition)
		const newCond = newAttributeFilter(attributeName, op, value)
		onChange(attributeName, newCond);
	}

	const clearFilter = () => {
		onChange(attributeName, null);
	}

	return <AttributeFilterWrapper
		preparationMode={preparationMode}
		label={label}
		operatorEditor={<NumericalOperatorDropdownButton operator={operatorSetOrDefault(condition)} onSelect={onSelectOperator} />}
		valueEditor={percent ? <PercentInput value={Number(condition?.value)} setValue={onChangeValue} /> : <NumberInput value={Number(condition?.value)} setValue={onChangeValue} />}
		clearFilter={clearFilter}
	/>
}

interface NewConditionProps {
	onChange: any
}

interface ConditionLabelProps {
	label: string
}

function ConditionLabel({ label }: ConditionLabelProps) {
	return <Col className="col-auto" style={{ minWidth: "6rem" }}>
		{label} :
	</Col>
}

export function NewCondition({ onChange }: NewConditionProps) {
	const [newAttribute, setNewAttribute] = useState("")

	const onChangeName = (newName: string) => {
		const newCond = newAttributeFilter(newName)
		const oldName = newAttribute
		setNewAttribute(newName)
		onChange(oldName, newCond);
	}

	return <Row className="align-items-center">
		<ConditionLabel label='Attribute' />
		<Col>
			<EnumPropose listkey="attribute" options={[
				{ value: AttributesEnum.productName, label: AttributesEnum.productName },
				{ value: AttributesEnum.thingId, label: AttributesEnum.thingId },
				{ value: AttributesEnum.thingName, label: AttributesEnum.thingName },
				{ value: AttributesEnum.ownerName, label: AttributesEnum.ownerName },
				{ value: AttributesEnum.numObservations, label: AttributesEnum.numObservations }
			]} value={newAttribute} onChange={onChangeName} />
		</Col>
	</Row>
}

const DEFAULT_CHANNEL_PROPERTY = ".avg"

export function NewChannelCondition({ onChange }: NewConditionProps) {
	const [newAttribute, setNewAttribute] = useState("")

	const onChangeName = (newName: string) => {
		const newAttributeName = CHANNEL_PREFIX + newName + DEFAULT_CHANNEL_PROPERTY
		const newCond = newAttributeFilter(newAttributeName)
		const oldName = newAttribute
		setNewAttribute(newName)
		onChange(CHANNEL_PREFIX + oldName + DEFAULT_CHANNEL_PROPERTY, newCond);
	}

	return <Row className="align-items-center">
		<ConditionLabel label="Channel" />
		<Col>
			<EnumPropose listkey="channel" options={[
				{ value: "counts_per_minute", label: "Counts per minute" },
				{ value: "pm10", label: "Particulate matter PM10" },
				{ value: "pm25", label: "Particulate matter PM25" },
				{ value: "sensorType", label: "Sensor Type" },
				{ value: "hv_pulses", label: "hv_pulses" },
				{ value: "counts", label: "counts" },
				{ value: "sample_time_ms", label: "sample_time_ms" },
				{ value: "pressure", label: "pressure" },
				{ value: "temperature", label: "temperature" },
				{ value: "humidity", label: "humidity" },
				{ value: "noise_LAeq", label: "noise_LAeq" },
				{ value: "noise_LA_min", label: "noise_LA_min" },
				{ value: "noise_LA_max", label: "noise_LA_max" }
			]} value={newAttribute} onChange={onChangeName} />
		</Col>
	</Row>
}


interface AnyConditionFilterProps {
	attributeName: string
	condition: ProductFilter
	onChange: any
	preparationMode: boolean

}


export function AnyConditionFilter({ attributeName, condition, onChange, preparationMode }: AnyConditionFilterProps) {

	const onSelectOperator = (operator: ProductFilterOperator) => {
		const newCond = newAttributeFilter(attributeName, operator, condition?.value)
		onChange(attributeName, newCond);
	}

	const onChangeValue = (value: string) => {
		const op = operatorSetOrDefault(condition)
		const newCond = newAttributeFilter(attributeName, op, value)
		onChange(attributeName, newCond);
	}

	const clearFilter = () => {
		onChange(attributeName, null);
	}


	return <AttributeFilterWrapper
		preparationMode={preparationMode}
		label={<span>{condition.key}</span>}
		operatorEditor={<NumericalOperatorDropdownButton operator={operatorSetOrDefault(condition)} onSelect={onSelectOperator} />}
		valueEditor={<AnyInput value={condition?.value ?? ""} setValue={onChangeValue} />}
		clearFilter={clearFilter}
	/>
}




export function ChannelConditionFilter({ attributeName, condition, onChange, preparationMode }: AnyConditionFilterProps) {

	const onSelectOperator = (operator: ProductFilterOperator) => {
		const newCond = newAttributeFilter(attributeName, operator, condition?.value)
		onChange(attributeName, newCond);
	}

	const onChangeValue = (value: string) => {
		const op = operatorSetOrDefault(condition)
		const newCond = newAttributeFilter(attributeName, op, value)
		onChange(attributeName, newCond);
	}

	const onChangeAggregationProperty = (value: string) => {
		const op = operatorSetOrDefault(condition)
		const newCond = newAttributeFilter(condition.key.slice(0, -3) + value, op, condition.value)
		onChange(attributeName, newCond);
	}


	const clearFilter = () => {
		onChange(attributeName, null);
	}

	const shortKey = getChannelShortKeyFromKey(condition.key)
	const propertySelector = getChannelPropertyFromKey(condition.key)
	const isNumericSearch = !isStringValue(condition.value)

	return <AttributeFilterWrapper
		preparationMode={preparationMode}
		label={<Row><Col><span>{shortKey}</span> </Col><Col className='col-auto'>
			{isNumericSearch && <NumericalAggregationPropertySelector selector={propertySelector} onSelect={onChangeAggregationProperty} />}
		</Col></Row>}
		operatorEditor={<NumericalOperatorDropdownButton operator={operatorSetOrDefault(condition)} onSelect={onSelectOperator} />}
		valueEditor={<AnyInput value={condition?.value ?? ""} setValue={onChangeValue} />}
		clearFilter={clearFilter}
	/>
}

interface LikeFilterProps {
	attributeName: string
	label: any
	condition: ProductFilter | null
	onChange: any
	preparationMode: boolean
}


export function LikeFilter({ attributeName, label, condition, onChange, preparationMode }: LikeFilterProps) {

	const clearFilter = () => {
		onChange(attributeName, null);
	}

	const onChangeValue = (e: any) => {
		var operator = OP_LIKE
		var value = e.target.value
		if (value.startsWith("!")) {
			operator = OP_NOT_LIKE
			value = value.substring(1)
		}
		const newCond = { key: attributeName, op: operator, value: value }
		onChange(attributeName, newCond);
	}


	var value = ""
	if (condition && condition.value) {
		value = condition.op === OP_NOT_LIKE ?  "!" + condition?.value : condition.value 
	}

	return < Form.Group as={Col} xs={12} md={12} lg={12} controlId={attributeName} >
		<Form.Label>{label}</Form.Label>
		<Row>
			<Col>
				<Form.Control
					placeholder="supports '*' and '?', start with '!' to negate"
					value={value}
					onChange={onChangeValue}
				/>
			</Col>
			{(!preparationMode) && <Col className="col-auto"><ClearAdditionalFilterButton clearFilter={clearFilter} /></Col>}
		</Row>

	</Form.Group>
}

function OperatorEqual() {
	return <Button variant={BDefault}>==</Button>
}

interface SelectOption {
	value: string
	label: string
}

interface SelectProps {
	listkey: string
	value: string
	options: Array<SelectOption>
	onChange: any
}

function EnumSelect({ listkey, value, options, onChange }: SelectProps) {
	const onChangeValue = (e: any) => {
		onChange(e.target.value);
	}
	return <Form.Control as={"select"} value={value} onChange={onChangeValue} className="m-1">
		{value ? null : <option value="">-</option>}
		{
			options.map((option) => {
				return <option key={option.value} value={option.value}>{option.label}</option>
			})
		}
	</Form.Control>
}

function EnumPropose({ listkey, value, options, onChange }: SelectProps) {
	const onChangeValue = (e: any) => {
		onChange(e.target.value);
	}
	return <><Form.Control list={"proplist" + listkey} value={value} onChange={onChangeValue} className="m-1">

	</Form.Control>
		<datalist id={"proplist" + listkey}>
			{
				options.map((option) => {
					return <option key={option.value} value={option.value} />
				})
			}
		</datalist>
	</>
}

interface EnumFilterProps {
	attributeName: string
	label: string
	condition: ProductFilter | null
	enumOptions: Array<SelectOption>
	onChange: any
	preparationMode: boolean
}

export function EnumFilter({ attributeName, label, condition, enumOptions, onChange, preparationMode }: EnumFilterProps) {

	const clearFilter = () => {
		onChange(attributeName, null);
	}

	const onChangeValue = (value: any) => {
		const newCond = { key: attributeName, op: OP_EQ, value: value }
		onChange(attributeName, newCond);
	}

	return <AttributeFilterWrapper label={label} operatorEditor={<OperatorEqual />}
		valueEditor={<EnumSelect listkey="unused" value={condition?.value ?? ""} options={enumOptions} onChange={onChangeValue} />} clearFilter={clearFilter}
		preparationMode={preparationMode} />
}

interface AreaOfInterestFilterProps {
	boundingBox: BoundingBoxType
	onChange: any
}

/** Specific Filters */
export function AreaOfInterestFilter({ boundingBox, onChange }: AreaOfInterestFilterProps) {
	return <Form.Group as={Col} xs={12} md={6} lg={12} className="py-2">
		<Row>
			<Col>
				<Form.Label>Area of Interest</Form.Label>
			</Col>
		</Row>
		<Row>
			<Col xs={12}>
				<AreaOfInterestEditor boundingBox={boundingBox} onChange={(aoi: any) => onChange(aoi)} />
			</Col>
		</Row>
	</Form.Group>
}

interface ProductTypesFilterProps {
	condition: ProductFilter
	onChange: any
	productTypesInfo: any
	label: string
}

export function ProductTypesFilter({ condition, onChange, productTypesInfo, label = "Product types" }: ProductTypesFilterProps) {

	const onSelectedProductTypes = (selectedProductTypes: any) => {
		if (selectedProductTypes?.length > 0) {
			const newCond = { key: "productType", op: OP_IN, values: selectedProductTypes }
			onChange("productType", newCond);
		} else {
			onChange("productType", null);
		}
	}

	return <Form.Group as={Col} xs={12} md={4} lg={12} controlId="productType" className="py-2">
		<Form.Label>{label}</Form.Label>
		<div className="border row p-1">
			<Form.Control
				as={ProductTypesSelection}
				productTypesInfo={productTypesInfo}
				selectedProductTypes={condition?.values ? condition?.values : []}
				onChange={onSelectedProductTypes}
			/>
			<ProductTypesListSelector productTypesInfo={productTypesInfo}
				selectedProductTypes={condition?.values ? condition?.values : []}
				onChange={onSelectedProductTypes} />
		</div>
	</Form.Group>
}


export function InsituTypesFilter({ condition, onChange, productTypesInfo, label = "In situ networks" }: ProductTypesFilterProps) {

	const onSelectedProductTypes = (selectedProductTypes: any) => {
		if (selectedProductTypes?.length > 0) {
			const newCond = { key: AttributesEnum.networkName, op: OP_IN, values: selectedProductTypes }
			onChange(AttributesEnum.networkName, newCond);
		} else {
			onChange(AttributesEnum.networkName, null);
		}
	}

	return <Form.Group as={Col} xs={12} md={4} lg={12} controlId="networkName" className="py-2">
		<Form.Label>{label}</Form.Label>
		<div className="border row p-1">
			<Form.Control
				as={InsituNetworkSelection}
				productTypesInfo={productTypesInfo}
				selectedProductTypes={condition?.values ? condition?.values : []}
				onChange={onSelectedProductTypes}
			/>
			<ProductTypesListSelector productTypesInfo={productTypesInfo}
				selectedProductTypes={condition?.values ? condition?.values : []}
				onChange={onSelectedProductTypes} />
		</div>
	</Form.Group>

}

interface FilterProps {
	eoDisplayItemFilter: Array<ProductFilter>
	eoEditItemFilter: Array<ProductFilter>
	onChange: any
	preparationMode: boolean
}

export function ProductNameFilter({ eoDisplayItemFilter, eoEditItemFilter, onChange, preparationMode }: FilterProps) {

	if (hasAttributeCondition(eoDisplayItemFilter, AttributesEnum.productName) !== preparationMode) {
		const condition = getAttributeCondition(eoEditItemFilter, AttributesEnum.productName)
		return <LikeFilter attributeName={AttributesEnum.productName} label="Product Name" condition={condition} onChange={onChange} preparationMode={preparationMode} />
	} else {
		return null
	}
}
export function MissionProgramFilter({ eoDisplayItemFilter, eoEditItemFilter, onChange, preparationMode }: FilterProps) {
	if (hasAttributeCondition(eoDisplayItemFilter, AttributesEnum.missionProgram) !== preparationMode) {
		const condition = getAttributeCondition(eoEditItemFilter, AttributesEnum.missionProgram)
		return <LikeFilter attributeName={AttributesEnum.missionProgram} label="Mission Program" condition={condition} onChange={onChange} preparationMode={preparationMode} />
	} else {
		return null
	}
}
export function MissionNameFilter({ eoDisplayItemFilter, eoEditItemFilter, onChange, preparationMode }: FilterProps) {
	if (hasAttributeCondition(eoDisplayItemFilter, AttributesEnum.missionName) !== preparationMode) {
		const condition = getAttributeCondition(eoEditItemFilter, AttributesEnum.missionName)
		return <LikeFilter attributeName={AttributesEnum.missionName} label="Mission Name" condition={condition} onChange={onChange} preparationMode={preparationMode} />
	} else {
		return null
	}
}
export function SatelliteFilter({ eoDisplayItemFilter, eoEditItemFilter, onChange, preparationMode }: FilterProps) {
	if (hasAttributeCondition(eoDisplayItemFilter, AttributesEnum.satellite) !== preparationMode) {
		const condition = getAttributeCondition(eoEditItemFilter, AttributesEnum.satellite)
		return <LikeFilter attributeName={AttributesEnum.satellite} label="Satellite" condition={condition} onChange={onChange} preparationMode={preparationMode} />
	} else {
		return null
	}
}

export function PolarizationFilter({ eoDisplayItemFilter, eoEditItemFilter, onChange, preparationMode }: FilterProps) {
	if (hasAttributeCondition(eoDisplayItemFilter, AttributesEnum.polarization) !== preparationMode) {
		const condition = getAttributeCondition(eoEditItemFilter, AttributesEnum.polarization)
		return <LikeFilter attributeName={AttributesEnum.polarization} label="Polarization" condition={condition} onChange={onChange} preparationMode={preparationMode} />
	} else {
		return null
	}
}

export function SensingOrbitDirectionFilter({ eoDisplayItemFilter, eoEditItemFilter, onChange, preparationMode }: FilterProps) {
	if (hasAttributeCondition(eoDisplayItemFilter, AttributesEnum.sensingOrbitDirection) !== preparationMode) {
		const condition = getAttributeCondition(eoEditItemFilter, AttributesEnum.sensingOrbitDirection)
		const enumOptions = [
			{ value: "ASCENDING", label: "Asc" },
			{ value: "DESCENDING", label: "Desc" },
		];
		return <EnumFilter attributeName={AttributesEnum.sensingOrbitDirection} label="Orbit direction"
			condition={condition}
			enumOptions={enumOptions}
			onChange={onChange}
			preparationMode={preparationMode} />
	} else {
		return null
	}
}

export function SensingDatatakeIdFilter({ eoDisplayItemFilter, eoEditItemFilter, onChange, preparationMode }: FilterProps) {
	if (hasAttributeCondition(eoDisplayItemFilter, AttributesEnum.sensingDatatakeId) !== preparationMode) {
		const condition = getAttributeCondition(eoEditItemFilter, AttributesEnum.sensingDatatakeId)
		return <NumericFilter attributeName={AttributesEnum.sensingDatatakeId} label="Datatake ID" condition={condition} onChange={onChange} preparationMode={preparationMode} percent={false} />
	} else {
		return null
	}
}
export function SensingCycleFilter({ eoDisplayItemFilter, eoEditItemFilter, onChange, preparationMode }: FilterProps) {
	if (hasAttributeCondition(eoDisplayItemFilter, AttributesEnum.sensingCycle) !== preparationMode) {
		const condition = getAttributeCondition(eoEditItemFilter, AttributesEnum.sensingCycle)
		return <NumericFilter attributeName={AttributesEnum.sensingCycle} label="Orbit Cycle" condition={condition} onChange={onChange} preparationMode={preparationMode} percent={false} />
	} else {
		return null
	}
}
export function SensingRelativeOrbitNumberFilter({ eoDisplayItemFilter, eoEditItemFilter, onChange, preparationMode }: FilterProps) {
	if (hasAttributeCondition(eoDisplayItemFilter, AttributesEnum.sensingRelativeOrbitNumber) !== preparationMode) {
		const condition = getAttributeCondition(eoEditItemFilter, AttributesEnum.sensingRelativeOrbitNumber)
		return <NumericFilter attributeName={AttributesEnum.sensingRelativeOrbitNumber} label="Relative orbit" condition={condition} onChange={onChange} preparationMode={preparationMode} percent={false} />
	} else {
		return null
	}

}
export function SensingAbsoluteOrbitNumberFilter({ eoDisplayItemFilter, eoEditItemFilter, onChange, preparationMode }: FilterProps) {
	if (hasAttributeCondition(eoDisplayItemFilter, AttributesEnum.sensingAbsoluteOrbitNumber) !== preparationMode) {
		const condition = getAttributeCondition(eoEditItemFilter, AttributesEnum.sensingAbsoluteOrbitNumber)

		return <NumericFilter attributeName={AttributesEnum.sensingAbsoluteOrbitNumber} label="Absolute orbit" condition={condition} onChange={onChange} preparationMode={preparationMode} percent={false} />
	} else {
		return null
	}
}
export function CoveragePercentCloudFilter({ eoDisplayItemFilter, eoEditItemFilter, onChange, preparationMode }: FilterProps) {
	if (hasAttributeCondition(eoDisplayItemFilter, AttributesEnum.coveragePercentCloud) !== preparationMode) {
		const condition = getAttributeCondition(eoEditItemFilter, AttributesEnum.coveragePercentCloud)
		return <NumericFilter attributeName={AttributesEnum.coveragePercentCloud} label="Cloud cover [%]" condition={condition} onChange={onChange} percent preparationMode={preparationMode} />
	} else {
		return null
	}
}
export function AssociationProductNameFilter({ eoDisplayItemFilter, eoEditItemFilter, onChange, preparationMode }: FilterProps) {
	if (hasAttributeCondition(eoDisplayItemFilter, AttributesEnum.association_productName) !== preparationMode) {
		const condition = getAttributeCondition(eoEditItemFilter, AttributesEnum.association_productName)
		return <LikeFilter attributeName={AttributesEnum.association_productName} label="Associated Product Name" condition={condition} onChange={onChange} preparationMode={preparationMode} />
	} else {
		return null
	}
}
