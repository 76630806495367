/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React, { ReactNode } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router';
import { SafetyButton } from '../../common/SafetyButton/SafetyButton'
import { deleteUserFilter } from '../../../state/store.userItems'
import { deleteIcon } from '../../common/Icons/Icons'
import { setProductFilterAndDoSearch } from '../../../state/store.dataProducts'
import { hasEoProductAttributeCondition, getEoProductAttributeCondition, inSituKeyPresentationName, OP_EQ, OPERATOR_PRESENTATION, OP_GT, OP_LT } from '../../../state/dataProducts-utils'
import { AoiInfoField } from '../../common/AoiInfoField/AoiInfoField'
import { productTypeIcon, temporalCoverageStartIcon, temporalCoverageEndIcon, productNameIcon, itemCountIcon } from '../DataProduct/DataProductAttributeIcons'
import { RelTimeDiff } from '../DataProductFilter/SensingTimeInputFilter';
import { BoundingBoxType } from '../../../utils/leafletUtils';
import { ProductFilter } from '../../../utils/types';
import { ProductQuerySpecType } from '../../../state/storeTypes';

interface UserFilterListProps {
	dispatch: any
	filterItems: any
}

export function UserFilterList({ dispatch, filterItems }: UserFilterListProps) {
	const navigate = useNavigate()

	return <UserFilterListUnconnected dispatch={dispatch} navigate={navigate} filterItems={filterItems} />

}

interface UserFilterListUnconnectedProps {
	dispatch: any
	navigate: any
	filterItems: any
}

export function UserFilterListUnconnected({ dispatch, navigate, filterItems }: UserFilterListUnconnectedProps) {

	return (<Container>
		{filterItems.map((filterItem: any) => (
			<UserFilterListEntry dispatch={dispatch} navigate={navigate} filterItem={filterItem}
				key={filterItem.uuid} />
		))}
	</Container>)
}



interface ItemWrapperProps {
	title: string
	children: any
	colorClass: string
}

function ItemWrapper({ title, children, colorClass }: ItemWrapperProps) {
	return (<Button title={title} className={`col col-auto border rounded-pill my-1 me-2 d-flex ${colorClass}`} >
		{children}
	</Button>)
}

interface SingleItemViewProps {
	title: string
	label: ReactNode
	operator: string
	children: any
	colorClass: string
}

function SingleItemView({ title, label, operator = OP_EQ, children, colorClass = "bg-secondary" }: SingleItemViewProps) {
	return (<ItemWrapper title={title} colorClass={" border-4 " + colorClass}>
		<span className="mx-1">{label}</span>
		<span className="">{OPERATOR_PRESENTATION.get(operator)}</span>
		<span className="mx-1">{children}</span>
	</ItemWrapper>)
}


function SingleInsituItemView({ title, label, operator = OP_EQ, children, colorClass = "bg-secondary" }: SingleItemViewProps) {
	return (<ItemWrapper title={title} colorClass={" border-insitu border-4 " + colorClass}>
		<span className="mx-1">{label}</span>
		<span className="">{OPERATOR_PRESENTATION.get(operator)}</span>
		<span className="mx-1">{children}</span>
	</ItemWrapper>)
}


interface RelTimeItemViewProps {
	title: string
	label: ReactNode
	operator: string
	relTime: RelTimeDiff
	colorClass: string
}

function RelTimeItemView({ title, label, operator, relTime, colorClass }: RelTimeItemViewProps) {
	return (<ItemWrapper title={title} colorClass={colorClass}>
		<span className="mx-1">{label}</span>
		<span className="">{operator}</span>
		<span className="mx-1">now {relTime.days !== 0 && relTime.days + " days"} {relTime.hours !== 0 && relTime.hours + " hours"} </span>
	</ItemWrapper>)
}

interface AoiItemViewProps {
	boundingBox: BoundingBoxType
}

function AoiItemView({ boundingBox }: AoiItemViewProps) {
	return (<ItemWrapper title="Area of interest" colorClass="filter-aoi" >
		<AoiInfoField boundingBox={boundingBox} noBoxContent="" />
	</ItemWrapper>)
}

interface FilterItemDataViewProps {
	productFilter: ProductQuerySpecType
}

function concatValues(values: Array<string> | undefined | null) {
	if (values) {
		return values.join(", ")
	} else {
		return ""
	}


}

function FilterItemDataView({ productFilter }: FilterItemDataViewProps) {

	const timeFilter = productFilter.timeFilter;
	const boundingBox = productFilter.boundingBox;
	const productNameCond = hasEoProductAttributeCondition(productFilter, "productName") ? getEoProductAttributeCondition(productFilter, "productName") : null;
	const productTypeCond = hasEoProductAttributeCondition(productFilter, "productType") ? getEoProductAttributeCondition(productFilter, "productType") : null;
	const otherEoConditions = productFilter.eoFilters.filter((cond: ProductFilter) => cond.key !== "productName" && cond.key !== "productType");

	return (
		<Row className="my-1">
			{/* General conditions */}
			{timeFilter.intervalStart && <SingleItemView title="Interval start" label={temporalCoverageStartIcon} operator={OP_GT} colorClass="filter-intervalStart">{timeFilter.intervalStart}</SingleItemView>}
			{timeFilter.intervalEnd && <SingleItemView title="Interval end" label={temporalCoverageEndIcon} operator={OP_LT} colorClass="filter-intervalEnd">{timeFilter.intervalEnd}</SingleItemView>}
			{timeFilter.relIntervalStart && <RelTimeItemView title="Relative interval start" label={temporalCoverageStartIcon} operator={OP_GT} relTime={timeFilter.relIntervalStart} colorClass="filter-intervalStart"></RelTimeItemView>}
			{timeFilter.relIntervalEnd && <RelTimeItemView title="Relative interval end" label={temporalCoverageEndIcon} operator={OP_LT} relTime={timeFilter.relIntervalEnd} colorClass="filter-intervalEnd"></RelTimeItemView>}
			{/* EO conditions */}
			{productTypeCond && <SingleItemView title="Product types" label={productTypeIcon} operator={OP_EQ} colorClass="border-eo filter-productTypes">{productTypeCond.values.map((t: string, index: number) => <span key={index}>{t}</span>)}</SingleItemView>}
			{productNameCond && <SingleItemView title="Product name" label={productNameIcon} operator={OP_EQ} colorClass="border-eo filter-name"> {productNameCond.value}</SingleItemView>}
			{boundingBox && <AoiItemView boundingBox={boundingBox} />}
			{otherEoConditions.length > 0 && <SingleItemView title="Count" label={itemCountIcon("# of other conditions")} operator={OP_EQ} colorClass="border-eo filter-noOfOtherConditions">{otherEoConditions.length}</SingleItemView>}
			{/* In-situ conditions */}
			{productFilter.insituFilters?.length > 0 && productFilter.insituFilters.map((item: ProductFilter) => <SingleInsituItemView key={item.key} title={inSituKeyPresentationName(item.key)} label={inSituKeyPresentationName(item.key)} colorClass='filter-name' operator={item.op}>{item.value ?? concatValues(item.values)}</SingleInsituItemView>)}

		</Row>
	)

}

interface UserFilterListEntryProps {
	dispatch: any
	navigate: any
	filterItem: any
}

export function UserFilterListEntry({ dispatch, navigate, filterItem }: UserFilterListEntryProps) {


	const deleteButtonClick = () => dispatch(deleteUserFilter(filterItem))

	const searchButtonClick = () => {
		dispatch(setProductFilterAndDoSearch(filterItem.data))
		navigate('/page/dataProducts')
	}

	return (<Row className="px-2 my-2 bg-light rounded" >
		<Col>
			<Row className="">
				<Button onClick={searchButtonClick} className="p-1 mr-2">
					<span className="mr-2">{filterItem.label}</span>&nbsp;
					<span className="text-muted">({filterItem.lastModified.slice(0, 19)})</span>
				</Button>
			</Row>
			<FilterItemDataView productFilter={filterItem.data} />
		</Col>
		<Col xs="auto">
			<SafetyButton onClick={deleteButtonClick} icon={deleteIcon()} label="Delete" />
		</Col>
	</Row>)

}
