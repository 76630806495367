/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { Fig } from '../components/Figure'
import { Tbl, TblHead, TblBody, TblCell, TblRow } from '../components/DocTable'
import WebSearchFigure from './web-search-component.png'
import AoIEditor from './aoi-editor-component.png'
import { deleteIcon, aoiBoxIcon, reloadIcon, visibleAreaIcon, hintIcon, layersIcon } from '../../common/Icons/Icons'
import { Helmet } from "react-helmet";
import {
	temporalCoverageStartIcon
} from '../../data/DataProduct/DataProductAttributeIcons'

import { InfoPointer } from '../components/InfoPointer'

const A_MORECONDITIONS = "moreconditions"
const A_AOIEDITOR = "aoieditor"
const A_PRODUCT_TYPES = "producttypes"


export default function Web() {
	return (<>
		<Helmet>
			<title>home / doc / search</title>
		</Helmet>
		<h1>Search</h1>

		<p>The search function allows to search for a data products based on</p>
		<ul>
			<li>the data product type</li>
			<li>the time of acquisition</li>
			<li>the data footprint</li>
			<li>additional product metadata</li>
		</ul>

		<Fig src={WebSearchFigure} label="Application main screen" width="300px" />

		<Tbl caption="Search elements">
			<TblHead labels={["Search field", "Description"]} />
			<TblBody>

				<TblRow>
					<TblCell>Interval (start, end)</TblCell>
					<TblCell>The time filter consists of a start and an end time, both
						can be set independently, e.g. to restrict the result set to only recent
						data.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>Interval setter {temporalCoverageStartIcon}</TblCell>
					<TblCell>Allows to quickly set intervals and to query stepwise through the timeline.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>Area of Interest (AOI)</TblCell>
					<TblCell>The area of interest that a product footprint shall cover or at least intersect
						is specified by a bounding box. To define the bounding box, a separate area of interest
						editor dialog is available.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>EO data</TblCell>
					<TblCell>Allows to switch on or off if EO remote sensing products are to be searched.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell><a href={"#" + A_PRODUCT_TYPES} >Product types</a></TblCell>
					<TblCell>The product type or mission filter filters on the mission
						specific product type code. There are some convenient
						type groups that can be selected, e.g. there are groups that cover all
						types of a particular mission or a specific type of instrument. The type filter can also be
						set from the context menue of result entries, to restrict there result to the same type as
						the selected entry.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell><a href={"#" + A_MORECONDITIONS} >Add more conditions (EO)</a></TblCell>
					<TblCell>Additional conditions based on product metadata can be set and accessed through this control element.
					</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>In-situ data</TblCell>
					<TblCell>Allows to switch on or off if in-situ sensor data is to be searched.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell><a href={"#" + A_PRODUCT_TYPES} >In-situ networks</a></TblCell>
					<TblCell>In-situ data is organised in networks of similar sensors. One or more networks or pre-defined network groups
						can be chosen.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell><a href={"#" + A_MORECONDITIONS} >Add more conditions (in-situ)</a></TblCell>
					<TblCell>Additional conditions based on aggregations attributes or channel data values can be set and accessed through
						this control element.</TblCell>
				</TblRow>
			</TblBody>
		</Tbl>


		<h1 id={A_AOIEDITOR}>Area of Interest dialogue</h1>

		<p>A dialogue for modifying the area of interest. The area of interest is defined as a bounding box. When used in a query, only products with a footprint that intersect the
			defined region of interest are listed.</p>

		<Fig src={AoIEditor} label="Area of Interest editor" />

		<Tbl caption="Area of Interest dialogue elements">
			<TblHead labels={["Eleent", "Description"]} />
			<TblBody>
				<TblRow>
					<TblCell>{visibleAreaIcon()}</TblCell>
					<TblCell>Selects the visible map as area of interest.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>{deleteIcon()}</TblCell>
					<TblCell>Deletes the currently defined area of interest.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>{aoiBoxIcon}</TblCell>
					<TblCell>Shows the area of interest bounding box as coordinates of the upper left and lower right corner.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>{reloadIcon()}</TblCell>
					<TblCell>Adjusts the map view to center around the region of interest bounding box. Does nothing if no region of interest is yet defined.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>{hintIcon}</TblCell>
					<TblCell>Provides a brief usage hint for the editor on functions that not immediatly obvious through other means.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>+/-</TblCell>
					<TblCell>Zoom in and out of the map.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>{layersIcon()}</TblCell>
					<TblCell>Select from the available background map layers.</TblCell>
				</TblRow>
			</TblBody>
		</Tbl>


		<h1 id={A_MORECONDITIONS}>Additional conditions</h1>
		<p>Additional filter conditions are available as listed in the following table. The availability of the parameters is type dependent. When adding
			an additional conditions on a paramater with a value, this is interpreted as a requirement that any product in the result set shall have the given
			parameter with the prescribed value.</p>

		<Tbl caption="Additional metadata search fields">
			<TblHead labels={["Search field", "Description"]} />
			<TblBody>
				<TblRow>
					<TblCell>Product name</TblCell>
					<TblCell>The name pattern can be specified with wildcard characters where "*"
						represents any sequence of characters, including an empty one and "." represents
						a single character.
					</TblCell>
				</TblRow>
			</TblBody>
		</Tbl>

		<h1 id={A_PRODUCT_TYPES}>Product type and type groups</h1>

		<p>When selecting product types, you can either select specific type codes or labelled type code groups.
			The available type groups and product types are listed here with information on type group content, a brief description of the codes
			and, where available, pointers to further information.
		</p>

		{/* Insert type data here (from pscout_typesinfo.py in central tools)*/}

		<h2>Missions</h2>
		<p>Mission related groupings</p>
		<Tbl caption="Missions">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>Petabite</TblCell><TblCell>Petabite</TblCell><TblCell>Products created and provided by Petabite.
				</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>Sentinel-1</TblCell><TblCell></TblCell><TblCell>The most used product types of the mission.</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>Sentinel-1 more</TblCell><TblCell></TblCell><TblCell>Less frequently requested product types of the mission.</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>Sentinel-2</TblCell><TblCell></TblCell><TblCell>The most used product types of the mission.</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>Sentinel-3</TblCell><TblCell></TblCell><TblCell>The most used product types of the mission.</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>Sentinel-3 more</TblCell><TblCell></TblCell><TblCell>Less frequently requested product types of the mission.</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>Sentinel-5P</TblCell><TblCell></TblCell><TblCell>The most used product types of the mission.</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>Sentinel-5P more</TblCell><TblCell></TblCell><TblCell>Less frequently requested product types of the mission.</TblCell><TblCell></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Popular</h2>
		<p>-</p>
		<Tbl caption="Popular">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>Popular</TblCell><TblCell></TblCell><TblCell>-</TblCell><TblCell></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Sensing types</h2>
		<p>Sensor related groupings</p>
		<Tbl caption="Sensing types">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>In-situ</TblCell><TblCell></TblCell><TblCell>-</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>Optical</TblCell><TblCell></TblCell><TblCell>Most used optical products
				</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>SAR</TblCell><TblCell></TblCell><TblCell>Most used SAR product types</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>Spectral</TblCell><TblCell></TblCell><TblCell>Products from Spectral and Hyperspectral Instruments</TblCell><TblCell></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Topics</h2>
		<p>Topic related groupings</p>
		<Tbl caption="Topics">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>Aerosols and Trace Gases</TblCell><TblCell></TblCell><TblCell>-</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>Marine</TblCell><TblCell></TblCell><TblCell>-</TblCell><TblCell></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Aerosols and Trace Gases</h2>
		<p>-</p>
		<Tbl caption="Aerosols and Trace Gases">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>AER_AI</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Ultraviolet Aerosol Index</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>AER_LH</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Aerosol Layer Height</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>CH4___</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Methane</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>CLOUD_</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Cloud</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>CO____</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Carbon Monoxide</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/2474726/Sentinel-5P-Level-2-Product-User-Manual-Carbon-Monoxide.pdf/dc93c3ae-6c77-4673-8fe7-459635aba4a3?version=1.2" /></TblCell></TblRow>
				<TblRow><TblCell>HCHO__</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Formaldehyde</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>NO2___</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Nitrogen Dioxide</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>O3_TCL</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Tropospheric Ozone</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>O3__PR</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Ozone Profile</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>O3____</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Ozone</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>SO2___</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Sulphur Dioxide Sentinel-5 Precursor Level 2 Sulphur Dioxide</TblCell><TblCell></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>In-situ</h2>
		<p>-</p>
		<Tbl caption="In-situ">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>PB_RLM_SHIP</TblCell><TblCell>Petabite</TblCell><TblCell>Ship sightings based on AIS data</TblCell><TblCell><InfoPointer href="https://gitlab.com/petabite.eu/documentation/petabite-datatypes" /></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Marine</h2>
		<p>-</p>
		<Tbl caption="Marine">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>EW_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>IW_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>SM_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>WV_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Optical</h2>
		<p>Most used optical products
		</p>
		<Tbl caption="Optical">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>MSIL1C</TblCell><TblCell>Sentinel-2</TblCell><TblCell>Orthorectified Top-Of-Atmosphere (TOA) reflectance</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/technical-guides/sentinel-2-msi/level-1c/product-formatting" /></TblCell></TblRow>
				<TblRow><TblCell>MSIL2A</TblCell><TblCell>Sentinel-2</TblCell><TblCell>Orthorectified Bottom-Of-Atmosphere (BOA) reflectance</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/technical-guides/sentinel-2-msi/level-2a/product-formatting" /></TblCell></TblRow>
				<TblRow><TblCell>OL_1_EFR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Full Resolution Top Of Atmosphere radiance</TblCell><TblCell><InfoPointer href="https://vnavigator.eumetsat.int/product/EO:EUM:DAT:SENTINEL-3:OL_1_EFR___NRT" /></TblCell></TblRow>
				<TblRow><TblCell>OL_1_ERR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Reduced Resolution Top Of Atmosphere radiance</TblCell><TblCell><InfoPointer href="https://vnavigator.eumetsat.int/product/EO:EUM:DAT:SENTINEL-3:OL_1_ERR___NTC?query=OL_1_ERR___&s=advanced" /></TblCell></TblRow>
				<TblRow><TblCell>OL_2_LFR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Full Resolution Land and Atmosphere parameters</TblCell><TblCell><InfoPointer href="https://navigator.eumetsat.int/product/EO:EUM:DAT:SENTINEL-3:OL_2_LFR___NTC/print" /></TblCell></TblRow>
				<TblRow><TblCell>OL_2_LRR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Reduced Resolution Land & Atmosphere geophysical products</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-olci/processing-levels" /></TblCell></TblRow>
				<TblRow><TblCell>OL_2_WFR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Full Resolution Water & Atmosphere geophysical products</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-olci/processing-levels" /></TblCell></TblRow>
				<TblRow><TblCell>OL_2_WRR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Reduced Resolution Water & Atmosphere geophysical products</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-olci/processing-levels" /></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Petabite</h2>
		<p>Products created and provided by Petabite.
		</p>
		<Tbl caption="Petabite">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>PB_RLM_SHIP</TblCell><TblCell>Petabite</TblCell><TblCell>Ship sightings based on AIS data</TblCell><TblCell><InfoPointer href="https://gitlab.com/petabite.eu/documentation/petabite-datatypes" /></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Popular</h2>
		<p>-</p>
		<Tbl caption="Popular">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>EW_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>EW_GRDM_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>EW_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>IW_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>IW_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>MSIL1C</TblCell><TblCell>Sentinel-2</TblCell><TblCell>Orthorectified Top-Of-Atmosphere (TOA) reflectance</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/technical-guides/sentinel-2-msi/level-1c/product-formatting" /></TblCell></TblRow>
				<TblRow><TblCell>MSIL2A</TblCell><TblCell>Sentinel-2</TblCell><TblCell>Orthorectified Bottom-Of-Atmosphere (BOA) reflectance</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/technical-guides/sentinel-2-msi/level-2a/product-formatting" /></TblCell></TblRow>
				<TblRow><TblCell>PB_RLM_SHIP</TblCell><TblCell>Petabite</TblCell><TblCell>Ship sightings based on AIS data</TblCell><TblCell><InfoPointer href="https://gitlab.com/petabite.eu/documentation/petabite-datatypes" /></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>SAR</h2>
		<p>Most used SAR product types</p>
		<Tbl caption="SAR">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>EW_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>EW_GRDM_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>EW_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>IW_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>IW_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>SM_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>SM_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>WV_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Sentinel-1</h2>
		<p>The most used product types of the mission.</p>
		<Tbl caption="Sentinel-1">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>EW_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>EW_GRDM_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>EW_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>IW_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>IW_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>SM_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>SM_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Sentinel-1 more</h2>
		<p>Less frequently requested product types of the mission.</p>
		<Tbl caption="Sentinel-1 more">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>EW_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>EW_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>IW_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>IW_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S1_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S1_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S1_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S1_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S2_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S2_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S2_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S2_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S3_GRDF_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S3_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S3_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S3_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S3_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S4_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S4_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S4_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S4_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S5_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S5_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S5_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S5_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S6_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S6_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S6_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S6_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>SM_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>SM_RAW_0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>WV_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>WV_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Sentinel-2</h2>
		<p>The most used product types of the mission.</p>
		<Tbl caption="Sentinel-2">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>MSIL1C</TblCell><TblCell>Sentinel-2</TblCell><TblCell>Orthorectified Top-Of-Atmosphere (TOA) reflectance</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/technical-guides/sentinel-2-msi/level-1c/product-formatting" /></TblCell></TblRow>
				<TblRow><TblCell>MSIL2A</TblCell><TblCell>Sentinel-2</TblCell><TblCell>Orthorectified Bottom-Of-Atmosphere (BOA) reflectance</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/technical-guides/sentinel-2-msi/level-2a/product-formatting" /></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Sentinel-3</h2>
		<p>The most used product types of the mission.</p>
		<Tbl caption="Sentinel-3">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>OL_1_EFR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Full Resolution Top Of Atmosphere radiance</TblCell><TblCell><InfoPointer href="https://vnavigator.eumetsat.int/product/EO:EUM:DAT:SENTINEL-3:OL_1_EFR___NRT" /></TblCell></TblRow>
				<TblRow><TblCell>OL_1_ERR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Reduced Resolution Top Of Atmosphere radiance</TblCell><TblCell><InfoPointer href="https://vnavigator.eumetsat.int/product/EO:EUM:DAT:SENTINEL-3:OL_1_ERR___NTC?query=OL_1_ERR___&s=advanced" /></TblCell></TblRow>
				<TblRow><TblCell>OL_2_LFR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Full Resolution Land and Atmosphere parameters</TblCell><TblCell><InfoPointer href="https://navigator.eumetsat.int/product/EO:EUM:DAT:SENTINEL-3:OL_2_LFR___NTC/print" /></TblCell></TblRow>
				<TblRow><TblCell>OL_2_LRR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Reduced Resolution Land & Atmosphere geophysical products</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-olci/processing-levels" /></TblCell></TblRow>
				<TblRow><TblCell>OL_2_WFR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Full Resolution Water & Atmosphere geophysical products</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-olci/processing-levels" /></TblCell></TblRow>
				<TblRow><TblCell>OL_2_WRR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Reduced Resolution Water & Atmosphere geophysical products</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-olci/processing-levels" /></TblCell></TblRow>
				<TblRow><TblCell>SL_1_RBT___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>SL_2_FRP___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>SL_2_LST___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>SL_2_WST___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>SY_1_MISR__</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-synergy/product-types" /></TblCell></TblRow>
				<TblRow><TblCell>SY_2_AOD___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-synergy/product-types" /></TblCell></TblRow>
				<TblRow><TblCell>SY_2_SYN___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-synergy/product-types" /></TblCell></TblRow>
				<TblRow><TblCell>SY_2_V10___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-synergy/product-types" /></TblCell></TblRow>
				<TblRow><TblCell>SY_2_VG1___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-synergy/product-types" /></TblCell></TblRow>
				<TblRow><TblCell>SY_2_VGP___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-synergy/product-types" /></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Sentinel-3 more</h2>
		<p>Less frequently requested product types of the mission.</p>
		<Tbl caption="Sentinel-3 more">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>SR_1_SRA_A_</TblCell><TblCell>Sentinel-3</TblCell><TblCell>L1A Echos parameters for SAR mode</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/documents/247904/2753172/Sentinel-3-Product-Data-Format-Specification-Level-1-products" /></TblCell></TblRow>
				<TblRow><TblCell>SR_1_SRA_BS</TblCell><TblCell>Sentinel-3</TblCell><TblCell>L1b-S Echos parameters for SAR mod</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/documents/247904/2753172/Sentinel-3-Product-Data-Format-Specification-Level-1-products" /></TblCell></TblRow>
				<TblRow><TblCell>SR_1_SRA___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/documents/247904/2753172/Sentinel-3-Product-Data-Format-Specification-Level-1-products" /></TblCell></TblRow>
				<TblRow><TblCell>SR_2_LAN___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>1-Hz and 20-Hz Ku and C bands parameters (LRM/SAR/PLRM), waveforms. Over Land</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/documents/247904/2753172/Sentinel-3-Product-Data-Format-Specification-Level-1-products" /></TblCell></TblRow>
				<TblRow><TblCell>SR_2_WAT___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>1-Hz and 20-Hz Ku and C bands parameters (LRM/SAR/PLRM), waveforms. Over Water</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/documents/247904/2753172/Sentinel-3-Product-Data-Format-Specification-Level-1-products" /></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Sentinel-5P</h2>
		<p>The most used product types of the mission.</p>
		<Tbl caption="Sentinel-5P">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>AER_AI</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Ultraviolet Aerosol Index</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>AER_LH</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Aerosol Layer Height</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>CH4___</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Methane</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>CLOUD_</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Cloud</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>CO____</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Carbon Monoxide</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/2474726/Sentinel-5P-Level-2-Product-User-Manual-Carbon-Monoxide.pdf/dc93c3ae-6c77-4673-8fe7-459635aba4a3?version=1.2" /></TblCell></TblRow>
				<TblRow><TblCell>HCHO__</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Formaldehyde</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>NO2___</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Nitrogen Dioxide</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>O3_TCL</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Tropospheric Ozone</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>O3__PR</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Ozone Profile</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>O3____</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Ozone</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>RA_BDM</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>SO2___</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Sulphur Dioxide Sentinel-5 Precursor Level 2 Sulphur Dioxide</TblCell><TblCell></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Sentinel-5P more</h2>
		<p>Less frequently requested product types of the mission.</p>
		<Tbl caption="Sentinel-5P more">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>IR_SIR</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Irradiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>IR_UVN</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Irradiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>NP_BD3</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 NPP Cloud</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>NP_BD6</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 NPP Cloud Sentinel-5 Precursor Level 2 NPP Cloud</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>NP_BD7</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 NPP Cloud Sentinel-5 Precursor Level 2 NPP Cloud</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>RA_BD1</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD2</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD3</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD4</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD5</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD6</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD7</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD8</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Spectral</h2>
		<p>Products from Spectral and Hyperspectral Instruments</p>
		<Tbl caption="Spectral">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>IR_SIR</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Irradiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>IR_UVN</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Irradiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>MSIL1C</TblCell><TblCell>Sentinel-2</TblCell><TblCell>Orthorectified Top-Of-Atmosphere (TOA) reflectance</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/technical-guides/sentinel-2-msi/level-1c/product-formatting" /></TblCell></TblRow>
				<TblRow><TblCell>MSIL2A</TblCell><TblCell>Sentinel-2</TblCell><TblCell>Orthorectified Bottom-Of-Atmosphere (BOA) reflectance</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/technical-guides/sentinel-2-msi/level-2a/product-formatting" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD1</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD2</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD3</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD4</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD5</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD6</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD7</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD8</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
			</TblBody>
		</Tbl>
		<h2>Product types</h2>
		<p>All available product types</p>
		<Tbl caption="Product types">
			<TblHead labels={["Code/Name", "Origin", "Description", "Info Pointer"]} />
			<TblBody>
				<TblRow><TblCell>AER_AI</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Ultraviolet Aerosol Index</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>AER_LH</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Aerosol Layer Height</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>CH4___</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Methane</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>CLOUD_</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Cloud</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>CO____</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Carbon Monoxide</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/2474726/Sentinel-5P-Level-2-Product-User-Manual-Carbon-Monoxide.pdf/dc93c3ae-6c77-4673-8fe7-459635aba4a3?version=1.2" /></TblCell></TblRow>
				<TblRow><TblCell>EW_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>EW_GRDM_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>EW_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>EW_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>EW_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>HCHO__</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Formaldehyde</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>IR_SIR</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Irradiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>IR_UVN</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Irradiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>IW_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>IW_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>IW_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>IW_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>MSIL1C</TblCell><TblCell>Sentinel-2</TblCell><TblCell>Orthorectified Top-Of-Atmosphere (TOA) reflectance</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/technical-guides/sentinel-2-msi/level-1c/product-formatting" /></TblCell></TblRow>
				<TblRow><TblCell>MSIL2A</TblCell><TblCell>Sentinel-2</TblCell><TblCell>Orthorectified Bottom-Of-Atmosphere (BOA) reflectance</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/technical-guides/sentinel-2-msi/level-2a/product-formatting" /></TblCell></TblRow>
				<TblRow><TblCell>NO2___</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Nitrogen Dioxide</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>NP_BD3</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 NPP Cloud</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>NP_BD6</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 NPP Cloud Sentinel-5 Precursor Level 2 NPP Cloud</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>NP_BD7</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 NPP Cloud Sentinel-5 Precursor Level 2 NPP Cloud</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>O3_TCL</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Tropospheric Ozone</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>O3__PR</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Ozone Profile</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>O3____</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Ozone</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>OL_1_EFR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Full Resolution Top Of Atmosphere radiance</TblCell><TblCell><InfoPointer href="https://vnavigator.eumetsat.int/product/EO:EUM:DAT:SENTINEL-3:OL_1_EFR___NRT" /></TblCell></TblRow>
				<TblRow><TblCell>OL_1_ERR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Reduced Resolution Top Of Atmosphere radiance</TblCell><TblCell><InfoPointer href="https://vnavigator.eumetsat.int/product/EO:EUM:DAT:SENTINEL-3:OL_1_ERR___NTC?query=OL_1_ERR___&s=advanced" /></TblCell></TblRow>
				<TblRow><TblCell>OL_2_LFR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Full Resolution Land and Atmosphere parameters</TblCell><TblCell><InfoPointer href="https://navigator.eumetsat.int/product/EO:EUM:DAT:SENTINEL-3:OL_2_LFR___NTC/print" /></TblCell></TblRow>
				<TblRow><TblCell>OL_2_LRR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Reduced Resolution Land & Atmosphere geophysical products</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-olci/processing-levels" /></TblCell></TblRow>
				<TblRow><TblCell>OL_2_WFR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Full Resolution Water & Atmosphere geophysical products</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-olci/processing-levels" /></TblCell></TblRow>
				<TblRow><TblCell>OL_2_WRR___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>Reduced Resolution Water & Atmosphere geophysical products</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-olci/processing-levels" /></TblCell></TblRow>
				<TblRow><TblCell>PB_RLM_SHIP</TblCell><TblCell>Petabite</TblCell><TblCell>Ship sightings based on AIS data</TblCell><TblCell><InfoPointer href="https://gitlab.com/petabite.eu/documentation/petabite-datatypes" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD1</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD2</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD3</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD4</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD5</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD6</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD7</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BD8</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/3119978/Sentinel-5P-Level-2-Input-Output-Data-Definition" /></TblCell></TblRow>
				<TblRow><TblCell>RA_BDM</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 1B Radiances</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>S1_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S1_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S1_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S1_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S2_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S2_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S2_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S2_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S3_GRDF_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S3_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S3_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S3_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S3_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S4_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S4_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S4_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S4_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S5_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S5_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S5_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S5_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S6_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S6_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S6_RAW__0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>S6_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>SL_1_RBT___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>SL_2_FRP___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>SL_2_LST___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>SL_2_WST___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>SM_GRDH_1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>SM_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>SM_RAW_0S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>SM_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>SO2___</TblCell><TblCell>Sentinel-5P</TblCell><TblCell>Sentinel-5 Precursor Level 2 Sulphur Dioxide Sentinel-5 Precursor Level 2 Sulphur Dioxide</TblCell><TblCell></TblCell></TblRow>
				<TblRow><TblCell>SR_1_SRA_A_</TblCell><TblCell>Sentinel-3</TblCell><TblCell>L1A Echos parameters for SAR mode</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/documents/247904/2753172/Sentinel-3-Product-Data-Format-Specification-Level-1-products" /></TblCell></TblRow>
				<TblRow><TblCell>SR_1_SRA_BS</TblCell><TblCell>Sentinel-3</TblCell><TblCell>L1b-S Echos parameters for SAR mod</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/documents/247904/2753172/Sentinel-3-Product-Data-Format-Specification-Level-1-products" /></TblCell></TblRow>
				<TblRow><TblCell>SR_1_SRA___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/documents/247904/2753172/Sentinel-3-Product-Data-Format-Specification-Level-1-products" /></TblCell></TblRow>
				<TblRow><TblCell>SR_2_LAN___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>1-Hz and 20-Hz Ku and C bands parameters (LRM/SAR/PLRM), waveforms. Over Land</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/documents/247904/2753172/Sentinel-3-Product-Data-Format-Specification-Level-1-products" /></TblCell></TblRow>
				<TblRow><TblCell>SR_2_WAT___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>1-Hz and 20-Hz Ku and C bands parameters (LRM/SAR/PLRM), waveforms. Over Water</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/documents/247904/2753172/Sentinel-3-Product-Data-Format-Specification-Level-1-products" /></TblCell></TblRow>
				<TblRow><TblCell>SY_1_MISR__</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-synergy/product-types" /></TblCell></TblRow>
				<TblRow><TblCell>SY_2_AOD___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-synergy/product-types" /></TblCell></TblRow>
				<TblRow><TblCell>SY_2_SYN___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-synergy/product-types" /></TblCell></TblRow>
				<TblRow><TblCell>SY_2_V10___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-synergy/product-types" /></TblCell></TblRow>
				<TblRow><TblCell>SY_2_VG1___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-synergy/product-types" /></TblCell></TblRow>
				<TblRow><TblCell>SY_2_VGP___</TblCell><TblCell>Sentinel-3</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-synergy/product-types" /></TblCell></TblRow>
				<TblRow><TblCell>WV_OCN__2S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
				<TblRow><TblCell>WV_SLC__1S</TblCell><TblCell>Sentinel-1</TblCell><TblCell>-</TblCell><TblCell><InfoPointer href="https://sentinel.esa.int/documents/247904/1877131/Sentinel-1-Product-Specification" /></TblCell></TblRow>
			</TblBody>
		</Tbl>

		{/* End insert type data */}

	</>)
}