/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { Helmet } from "react-helmet";



export default function Ack() {
	return (<>
		<Helmet>
			<title>home / doc / acknowledgements</title>
		</Helmet>
		<h1>Attributions and acknowledgements</h1>

		<p>There is not always enough room to proper attribute the source of the map data.
			Depending on the selected projection and map layers, the following attributions apply.</p>

		<h2>North polar projection </h2>

		<p>Map tiles attribution: © OpenStreetMap contributors'</p>

		<p>Tile server: A tile server for public use is kindly provided by  tile.gbif.org</p>

		<h2>South polar projection</h2>

		<p>Map tiles attribution: © OpenStreetMap contributors'</p>

		<p>Tile server: A tile server for public use is kindly provided by  tile.gbif.org</p>

		<h2>Mercator projection</h2>

		<h3>TopPlus tiles</h3>

		<p>Map tiles attribution:  '© Bundesamt für Kartografie und Geodäsie (2021), Datenquellen: http://sg.geodatenzentrum.de/web_public/Datenquellen_TopPlus_Open.pdf '</p>

		<p>Tile server: A tile server for public use is kindly provided by sgx.geodatenzentrum.de</p>

		<h3>OpenStreetMap</h3>

		<p>Map tiles attribution:  © OpenStreetMap contributors' Rendering &copy; EOX</p>

		<p>Tile Server: A tile server is kindly provided by EOX.</p>

		<h3>Sensor.Community</h3>

		<p>We thank the community of citizens behind the Sensor.Community (<a href="https://sensor.community/">https://sensor.community/</a>) project for collecting and providing
			their environmental data to all of us.
		</p>
	</>
	)
}


