/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React, { useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { CSVLink } from "react-csv"
import { DataProductFilter } from '../components/data/DataProductFilter/DataProductFilter'
import { ConnectedOnlyLoggedIn } from '../components/security/OnlyLoggedIn'
import {
	selectDataProductsFromStoreState,
	selectDataProductsFilterFromStoreState,
	setProductFilterAndDoSearch
} from '../state/store.dataProducts'
import { selectUserBookmarkedItemsFromStoreState, isFullBookmarkedStore } from '../state/store.userItemsBookmarked';
import {selectOidcTokensFromStoreState} from '../state/store.oidc'
import { LoadingSpinner } from '../components/common/LoadingSpinner/LoadingSpinner'
import { DataProductList } from '../components/data/DataProduct/DataProductList'
import { MapView } from '../components/common/MapView/MapView'
import SectionTopRow from '../components/common/SectionTopRow'
import { Helmet } from "react-helmet";
import Form from 'react-bootstrap/Form'
import { SupportEmail } from '../components/doc/components/ServiceName';


function CsvExportButton({ loading, products, selectedProductIds }) {

	if (!loading && products.length > 0) {

		var productList = products

		if (selectedProductIds.length > 0) {
			productList = productList.filter(p => selectedProductIds.includes(p.id))
		}

		const csvData = productList.map((p) => {
			const r = { ...p }
			delete r.thumbnailURL
			delete r.quicklookURL
			delete r.id
			delete r.spatialCoverageBoundingBox
			delete r.spatialCoverageGeoJson
			delete r.displayChannel
			delete r.channels
			r['coverage'] =  JSON.stringify(p.spatialCoverage)
			delete r.spatialCoverage
			return r
		})

		return <CSVLink
			separator=";"
			data={csvData}
			filename={"ieoto-products.csv"}
			className="btn btn-primary"
			target="_blank"
			enclosingCharacter="'"
		>Export</CSVLink>
	} else {
		return <Button variant='primary' disabled>Export</Button>
	}
}

function ResultSetInfo({ loading, products = [] }) {
	var atLeast = ""
	var results = "results"

	if (products.length === 1) {
		results = "result"
	}
	if (products.length === 50) {
		atLeast = <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
			overlay={<Tooltip>Some results are not shown, narrow your search to get complete result sets.</Tooltip>}>
			<span><strong>at least</strong></span>
		</OverlayTrigger>
	}
	return (<>
		{loading && <Button>Searching ...</Button>}
		{!loading && <Button className="bg-transparent" >Search returned {atLeast} {products.length} {results}.</Button>}
	</>)
}

function DataProductListView({ loading, products, dispatch, selectedProductIds, productFilter, setProductFilter, access_token,bookmarkedProductIs }) {
	const [showOnlySelected, setShowOnlySelected] = useState(false);

	const onShowOnlySelectionSwitchChange = (event) => {
		setShowOnlySelected(event.target.checked)
	}

	//	onChange={onSelectionSwitchChange}


	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>IEOTO / search</title>
				<meta name="description" content="Allows search the content of the Petabite data service IEOTO that provides access to a large number of earth observation remote sensing data and in-situ measurements." />
			</Helmet>
			<SectionTopRow >
				<div className="col m-1"><ResultSetInfo loading={loading} products={products} /></div>
				<div className="col-auto m-1 p-1 border	bg-primary rounded d-flex align-items-center">
					<Form.Check type="switch" id="view-switch" checked={showOnlySelected} label="Show only selected" onChange={onShowOnlySelectionSwitchChange} />
				</div>
				<div className="col-auto m-1"><CsvExportButton loading={loading} products={products} selectedProductIds={selectedProductIds} /></div>
			</SectionTopRow>
			<LoadingSpinner loading={loading}>
				<div className="data-product-list" >
					<DataProductList
						dispatch={dispatch}
						products={products}
						access_token={access_token}
						selectedProductIds={selectedProductIds}
						productFilter={productFilter}
						setProductFilter={setProductFilter}
						showOnlySelected={showOnlySelected}
						bookmarkedProductIs={bookmarkedProductIs} 
						isBookmarklistFull={isFullBookmarkedStore(bookmarkedProductIs)}/>
				</div>
			</LoadingSpinner>
		</>
	)
}


function ConnectedDataProductListWithLoading({ productFilter, setProductFilter }) {
	const dispatch = useDispatch()
	const { loading, error, errorMessage, products, selectedProductIds } = useSelector(selectDataProductsFromStoreState, shallowEqual)
	const { bookmarkedItems } = useSelector(selectUserBookmarkedItemsFromStoreState, shallowEqual)
	const { access_token } = useSelector(selectOidcTokensFromStoreState, shallowEqual)
	
	if (error) {
		return <>
			<SectionTopRow >
				<div className="col m-1">
					{!loading && <Button className="bg-transparent" >Sorry, an internal error occured. Please search again. Consider adding filter constraints to limit the result set.</Button>}
					{loading && <Button>Searching ...</Button>}
				</div>
			</SectionTopRow>

			<div>{errorMessage}</div>
		</>
	} else {
		return <DataProductListView
			loading={loading}
			dispatch={dispatch}
			products={products}
			access_token={access_token}
			selectedProductIds={selectedProductIds}
			productFilter={productFilter}
			setProductFilter={setProductFilter}
			bookmarkedProductIs={bookmarkedItems.map((item) => item.data.id)}
			/>

	}
}


function ConnectedProductsMapView() {
	const { products, appliedProductFilter, loading, selectedProductIds } = useSelector(selectDataProductsFromStoreState, shallowEqual)

	try {
		return <MapView aoiBoundingBox={appliedProductFilter.boundingBox}
		products={products && products.length > 0 ? products.filter(product => product.spatialCoverage) : []}
		selectedProductIds={selectedProductIds} loading={loading} />
	} catch (e) {
		return <Container><div>Sorry, something went wrong during map rendering. Please reload page and report to <SupportEmail body='mapview' /> if the problem persists.</div></Container>
	}
}

export function ConnectedDataPage() {
	const selectedDataProductsFilterFromStoreState = useSelector(selectDataProductsFilterFromStoreState, shallowEqual);
	const [productFilter, setProductFilter] = useState(selectedDataProductsFilterFromStoreState.productFilter);
	const dispatch = useDispatch()
	const productTypesInfo = selectedDataProductsFilterFromStoreState.productTypesInfo;
	const networkTypesInfo = selectedDataProductsFilterFromStoreState.networkTypesInfo;

	/**
	 * Applies the locally edit filter to the global filter object.
	 * This causes also the execution of a search with the new filter
	 */
	const applyProductFilter = (newProductFilter) => {
		// causes search!
		dispatch(setProductFilterAndDoSearch(newProductFilter))
	}

	return <ConnectedOnlyLoggedIn loginHint>
		<Container fluid className='m-0 p-0 h-100'>
			<Row className='gx-0'>
				<Col className='h-100' xs={12} lg={4} xl={3} xxl={2}>
					<DataProductFilter dispatch={dispatch} productTypesInfo={productTypesInfo} networkTypesInfo={networkTypesInfo} productFilter={productFilter} setProductFilter={setProductFilter} applyProductFilter={applyProductFilter} />
				</Col>
				<Col xs={12} lg={8} xl={{ span: 5, order: 'last' }} xxl={{ span: 5, order: 'last' }} style={{ maxHeight: 'calc(100vmin - 10rem)', minHeight: '400px' }}
					className="border" >
					<ConnectedProductsMapView />
				</Col>
				<Col xs={12} lg={12} xl={4} xxl={5}   >
					<ConnectedDataProductListWithLoading productFilter={productFilter} setProductFilter={setProductFilter} />
				</Col>
			</Row>
		</Container>
	</ConnectedOnlyLoggedIn>
}