import { useEffect, useState } from 'react';
import type { InputDescriptorType, OutputDescriptorType, Process, WorkflowDetailsType, ieotoApiLink } from '../../state/ieotoProcessingApi';
import { Button, Col, Row } from 'react-bootstrap';
import { getAvailableWorkflows, getWorkflowDetails } from '../../state/ieotoProcessingApi';
import Container from 'react-bootstrap/Container';
import { reloadIcon } from '../common/Icons/Icons';
import { LoadingSpinner } from '../common/LoadingSpinner/LoadingSpinner';
import { ApiCallErrorDescriptor } from './ApiCallErrorDescriptor';

type WorkflowInputProps = {
	inputName: string
	inputDescriptor: InputDescriptorType
}

export function WorkflowInput({ inputName, inputDescriptor }: WorkflowInputProps) {
	return <Row className="mb-3">
		<Col xs={12} sm={4} lg={2} >{inputName} {inputDescriptor.maxOccurs > 0 && " [ ]"}</Col><Col>{inputDescriptor.description}</Col>
	</Row>

}

type WorkflowOutputProps = {
	outputName: string
	outputDescriptor: OutputDescriptorType
}

export function WorkflowOutput({ outputName, outputDescriptor }: WorkflowOutputProps) {
	return <Row className="mb-3">
		<Col xs={12} sm={4} lg={2} >{outputName}</Col><Col>{outputDescriptor.title}</Col>
	</Row>

}

type WorkflowDetailsProps = {
	detailsLink: ieotoApiLink
}

/**
 * 
 * @param param0 
 * 
 * 
 * @returns 
 */
export function WorkflowDetails({ detailsLink }: WorkflowDetailsProps) {
	const [workflowDetails, setDetails] = useState({} as WorkflowDetailsType)

	useEffect(() => {
		async function fetchData() {
			const details = await getWorkflowDetails(detailsLink)
			if (!ignore) {
				setDetails(details)
			}
			return details
		}
		let ignore = false;
		fetchData()
		return () => {
			ignore = true
		}
	}, [detailsLink])

	return (<Col>
		<Row>
			<Col>
				<h3>Inputs</h3>
			</Col>
		</Row>
		<Row>
			{workflowDetails.inputs && Object.entries(workflowDetails.inputs).map(([key, wfd]) => <WorkflowInput key={key} inputName={key} inputDescriptor={wfd} />)}
		</Row>
		<Row>
			<Col>
				<h3>Outputs</h3>
			</Col>
		</Row>
		<Row>
			{workflowDetails.outputs && Object.entries(workflowDetails.outputs).map(([key, wfd]) => <WorkflowOutput key={key} outputName={key} outputDescriptor={wfd} />)}
		</Row>

		{/* JSON.stringify(workflowDetails) */}

	</Col>)

}

type WorkflowDescriptionProps = {
	workflow: Process
}

export function WorkflowDescription({ workflow }: WorkflowDescriptionProps) {
	return <>
		<Row className="py-2">
			<Col className="col-3">
				<h2>{workflow.id}</h2>
			</Col>
			<Col>
				<Row>
					<h2>{workflow.title}</h2>
				</Row>
				<Row className="py-2">
					<Col>
						{workflow.description}
					</Col>
				</Row>
				<Row>
					<WorkflowDetails detailsLink={workflow.links[0]} />
				</Row>
			</Col>
		</Row>
	</>

}

export type ProcessingPageProps = {
	userId: string
}

export function WorkflowsSubPage({ userId }: ProcessingPageProps) {
	const [workflows, setWorkflows] = useState([] as Array<Process>)
	const [reloadCount, setReloadCount] = useState(0)
	const [loading, setLoading] = useState(false)
	const [errorcode, setErrorcode] = useState(-1)

	useEffect(() => {

		async function fetchData() {
			setLoading(true)
			setErrorcode(-1)
			try {
				const wfs = await getAvailableWorkflows(userId)
				setLoading(false)
				if (!ignore) {
					setWorkflows(wfs)
				}
				return wfs
			} catch (e: any) {
				setErrorcode(e.response.status)
				setLoading(false)
			}
		}
		let ignore = false;
		fetchData()
		return () => {
			ignore = true
		}
	}, [userId])

	useEffect(() => {
		async function fetchData() {
			setLoading(true)
			try {
				const wfs = await getAvailableWorkflows(userId)
				setLoading(false)
				setWorkflows(wfs)
			} catch (e: any) {
				setErrorcode(e.response.status)
				setLoading(false)
			}

		}
		if (reloadCount > 0) {
			fetchData()
		}
	}, [reloadCount, userId])

	return (
		<Container fluid>
			<Row className="flex-row-reverse">
				<Col xs="auto" className="ml-auto p-0" >
					<Container fluid >
						<Row className="gx-0 my-2">
							<Col></Col>
							<Col xs="auto" className="ml-auto p-0">
								<Button variant="secondary" onClick={() => { setReloadCount(reloadCount + 1) }} style={{ minWidth: '7rem' }}>Reload {reloadIcon()}</Button>
							</Col>
						</Row>
					</Container>
				</Col>
				<Col className="p-0">
					<Container >
						{errorcode < 0 && <LoadingSpinner loading={loading} >
							{!loading && workflows.length === 0 && <div>There are no defined workflows.</div>}
							{!loading && workflows.length > 0 &&
								workflows.map((wf) => <WorkflowDescription key={wf.id} workflow={wf} />)
							}
						</LoadingSpinner>}
						<ApiCallErrorDescriptor errorcode={errorcode} />

					</Container>
				</Col>
			</Row>

		</Container>
	)
}