/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

const { PetabiteLogoImageHeader } = require('../doc/components/PetabiteLogoImageHeader')

export function ImprintDialog({ onClose, show = true }) {
	return (
		<Modal show={show} size="lg" onHide={onClose} >
			<Modal.Header className="container-fluid" closeButton >
				<div className="row justify-content-center pl-4">
					<h3>Imprint</h3>
				</div>
			</Modal.Header>
			<Modal.Body>
				<PetabiteLogoImageHeader />
				<div className="m-4">
					<div>Petabite GmbH</div>
					<div>Munstermannskamp 1</div>
					<div>21335 Lüneburg</div>
					<div>Germany</div>
					<div><span className="font-weight-bold">Email:</span> info (at) petabite.eu</div>
					<div><span className="font-weight-bold">Phone:</span> +49 4131 7898-137</div>
					<div><span className="font-weight-bold">Registergericht/Register Court:</span> Amtsgericht Lüneburg, HRB 208770</div>
					<div><span className="font-weight-bold">VAT ID:</span> DE329864235</div>
					<div>Geschäftsführer/Managing Director: Nils Junike, Bernard Pruin</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={onClose}>Close</Button>
			</Modal.Footer>
		</Modal>
	);
}