/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { UserFilterDialog } from '../UserFilters/UserFilterDialog'
import { emptyProductFilter } from '../../../state/emptyProductFilter';

import SectionTopRow from '../../common/SectionTopRow'

import { SearchIcon, deleteIcon } from '../../common/Icons/Icons'
import { IconSubmitButton, IconButton } from '../../common/Icons/IconButton'
import {
	AreaOfInterestFilter,
	ProductTypesFilter,
	ProductNameFilter,
	MissionProgramFilter,
	MissionNameFilter,
	SatelliteFilter,
	PolarizationFilter,
	SensingOrbitDirectionFilter,
	SensingDatatakeIdFilter,
	SensingCycleFilter,
	SensingRelativeOrbitNumberFilter,
	SensingAbsoluteOrbitNumberFilter,
	CoveragePercentCloudFilter,
	AssociationProductNameFilter,
	InsituTypesFilter,
	NewCondition,
	AnyConditionFilter,
	ChannelConditionFilter,
	NewChannelCondition,
} from './AttributeFilter'
import {
	replaceEoProductAttributeCondition,
	getAttributeCondition,
	replaceInsituProductAttributeCondition,
	replaceInArray,
	CHANNEL_PREFIX,

} from '../../../state/dataProducts-utils'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import PopoverBody from 'react-bootstrap/PopoverBody'
import { BDefault, BActionDefault } from '../../common/StyleSettings';
import { SensingTimeInputFilter } from './SensingTimeInputFilter';
import { AttributesEnum } from '../../../utils/ApiTypes';

const formGroupClass = "py-2"

function AddMoreConditions({ setNewFilters, children }) {
	return <Form.Group as={Col} xs={12} md={6} lg={12} className={formGroupClass} >
		<OverlayTrigger trigger="click" placement="bottom" rootClose={true} className="px-0 w-100" onExited={setNewFilters}
			overlay={<Popover id="popover-basic" className="px-0" style={{ "minWidth": "24rem" }}>
				<PopoverBody className="bg-secondary px-2 w-100"  style={{ "maxWidth": "30rem" }}>
					<Form.Group as={Row} xs={12} className="px-0 w-100">
						{children}
					</Form.Group>
					<Row className="mt-3 justify-content-center">
						<Col className='col-auto'><Button variant={BDefault} onClick={() => document.body.click()}>Close</Button></Col>
					</Row>
				</PopoverBody>
			</Popover>}  >
			<Button variant={BDefault} className="w-100">Add more conditions</Button>

		</OverlayTrigger>
	</Form.Group>

}

export function OptionalEoProductFilter({ productTypesInfo, displayConditionFilter, editConditionFilter, onChange, preparationMode }) {
	const eoEditItemFilter = editConditionFilter
	const eoDisplayItemFilter = displayConditionFilter.eoFilters
	return <>
		<ProductNameFilter eoDisplayItemFilter={eoDisplayItemFilter} eoEditItemFilter={eoEditItemFilter} onChange={onChange} preparationMode={preparationMode} />
		<MissionProgramFilter eoDisplayItemFilter={eoDisplayItemFilter} eoEditItemFilter={eoEditItemFilter} onChange={onChange} preparationMode={preparationMode} />
		<MissionNameFilter eoDisplayItemFilter={eoDisplayItemFilter} eoEditItemFilter={eoEditItemFilter} onChange={onChange} preparationMode={preparationMode} />
		<SatelliteFilter eoDisplayItemFilter={eoDisplayItemFilter} eoEditItemFilter={eoEditItemFilter} onChange={onChange} preparationMode={preparationMode} />
		<PolarizationFilter eoDisplayItemFilter={eoDisplayItemFilter} eoEditItemFilter={eoEditItemFilter} onChange={onChange} preparationMode={preparationMode} />
		<SensingOrbitDirectionFilter eoDisplayItemFilter={eoDisplayItemFilter} eoEditItemFilter={eoEditItemFilter} onChange={onChange} preparationMode={preparationMode} />
		<SensingDatatakeIdFilter eoDisplayItemFilter={eoDisplayItemFilter} eoEditItemFilter={eoEditItemFilter} onChange={onChange} preparationMode={preparationMode} />
		<SensingCycleFilter eoDisplayItemFilter={eoDisplayItemFilter} eoEditItemFilter={eoEditItemFilter} onChange={onChange} preparationMode={preparationMode} />
		<SensingRelativeOrbitNumberFilter eoDisplayItemFilter={eoDisplayItemFilter} eoEditItemFilter={eoEditItemFilter} onChange={onChange} preparationMode={preparationMode} />
		<SensingAbsoluteOrbitNumberFilter eoDisplayItemFilter={eoDisplayItemFilter} eoEditItemFilter={eoEditItemFilter} onChange={onChange} preparationMode={preparationMode} />
		<CoveragePercentCloudFilter eoDisplayItemFilter={eoDisplayItemFilter} eoEditItemFilter={eoEditItemFilter} onChange={onChange} preparationMode={preparationMode} />
		<AssociationProductNameFilter eoDisplayItemFilter={eoDisplayItemFilter} eoEditItemFilter={eoEditItemFilter} onChange={onChange} preparationMode={preparationMode} />
	</>
}

export function OptionalInsituProductFilter({ editConditionFilter, onChange, preparationMode }) {

	if (preparationMode) {
		return <>
			<NewCondition onChange={onChange} />
			<NewChannelCondition onChange={onChange} />
		</>
	}
	else {
		return <>
			{editConditionFilter.filter((condition) => condition.key !== AttributesEnum.networkName).map((condition) => {
				if (condition.key.startsWith(CHANNEL_PREFIX)) {
					return <ChannelConditionFilter key={condition.key} attributeName={condition.key} condition={condition} onChange={onChange} />
				} else {
					return <AnyConditionFilter key={condition.key} attributeName={condition.key} condition={condition} onChange={onChange} />
				}
			}
			)}
		</>
	}
}

export function DataProductFilter({ dispatch, productTypesInfo, networkTypesInfo, productFilter, setProductFilter, applyProductFilter }) {
	const [showAddUserFilterDlg, setShowAddUserFilterDlg] = useState(false); // Controls visibility of the store search dialogue
	// the filter fragment set within add new conditions popup
	const [eoPopupFilters, setEoPopupFilters] = useState(emptyProductFilter.eoFilters)
	const [insituPopupFilters, setInsituPopupFilters] = useState(emptyProductFilter.insituFilters)
	const [activeTimeFilterTab, setActiveTimeFilterTab] = useState(null)



	// update the filter upon closure of the add conditions popup
	const onEoPopupClose = () => {
		var newProductFilter = productFilter;
		for (const newCondition of eoPopupFilters) {
			newProductFilter = replaceEoProductAttributeCondition(newProductFilter, newCondition.key, newCondition)
		}
		setProductFilter(newProductFilter)
		setEoPopupFilters([])
	}

	const onInsituPopupClose = () => {
		var newProductFilter = productFilter;
		for (const newCondition of insituPopupFilters) {
			newProductFilter = replaceInsituProductAttributeCondition(newProductFilter, newCondition.key, newCondition)
		}
		setProductFilter(newProductFilter)
		setInsituPopupFilters([])
	}

	const clearAllFilters = () => {
		setProductFilter(emptyProductFilter)
		setEoPopupFilters(emptyProductFilter.eoFilters)
		setInsituPopupFilters(emptyProductFilter.insituFilters)
		setActiveTimeFilterTab(null)
	}

	const onEoProductAttributeConditionChangeProductFilter = (attributeName, newCondition) => {
		const newProductFilter = replaceEoProductAttributeCondition(productFilter, attributeName, newCondition)
		setProductFilter(newProductFilter)
	}

	const onInsituAttributeConditionChangeProductFilter = (attributeName, newCondition) => {
		const newProductFilter = replaceInsituProductAttributeCondition(productFilter, attributeName, newCondition)
		setProductFilter(newProductFilter)
	}

	const onEoProductAttributeConditionChangePopupFilter = (attributeName, newCondition) => {
		const newPopupFilter = replaceInArray(eoPopupFilters, attributeName, newCondition)
		setEoPopupFilters(newPopupFilter)
	}

	const onInsituProductAttributeConditionChangePopupFilter = (attributeName, newCondition) => {
		const newPopupFilter = replaceInArray(insituPopupFilters, attributeName, newCondition)
		setInsituPopupFilters(newPopupFilter)
	}

	const onChangeTimeFilter = (newTimeFilter) => {
		const newProductFilter = { ...productFilter, timeFilter: newTimeFilter }
		setProductFilter(newProductFilter)
	}

	const onChangeBoundingBox = (newBoundingBox) => {
		const newProductFilter = { ...productFilter, boundingBox: newBoundingBox }
		setProductFilter(newProductFilter)
	}

	const onChangeEoFilterUse = (event) => {
		const targetValue = event.target.checked
		const replaceProperties = { withEoFilters: targetValue }

		if (!targetValue) {
			replaceProperties.withInsituFilters = true
		}
		const newProductFilter = { ...productFilter, ...replaceProperties }
		setProductFilter(newProductFilter)
	}

	const onChangeInsituFilterUse = (event) => {
		const targetValue = event.target.checked
		const replaceProperties = { withInsituFilters: targetValue }

		if (!targetValue) {
			replaceProperties.withEoFilters = true
		}
		const newProductFilter = { ...productFilter, ...replaceProperties }
		setProductFilter(newProductFilter)
	}

	return (
		<Container fluid>
			<Form onSubmit={(event) => {
				event.preventDefault();
				applyProductFilter(productFilter)
			}}>
				<SectionTopRow><div className="mr-auto"><IconSubmitButton icon={<SearchIcon />} /></div></SectionTopRow>
				<Row><Col><h3>General conditions</h3></Col></Row>
				<Row>
					<Col>
						<SensingTimeInputFilter
							activeTab={activeTimeFilterTab}
							setActiveTab={setActiveTimeFilterTab}
							timeFilter={productFilter.timeFilter}
							onChange={onChangeTimeFilter} />
					</Col>
					{/* Editor comes with Col */}
					<AreaOfInterestFilter boundingBox={productFilter.boundingBox} onChange={onChangeBoundingBox} />
				</Row>
				<Row className="mt-3 bg-eo"><Col><h3>EO data</h3></Col>
					<Col className='ml-auto col-auto fs-4' title="When deactivated, eo data products are not included in the search.">
						<Form.Check type="switch" id="eo-switch" checked={productFilter?.withEoFilters ?? false}
							label="" onChange={onChangeEoFilterUse} />
					</Col>
				</Row>
				<Row className={"bg-eo " + (productFilter?.withEoFilters ? "" : "notused")} >
					{/* optional filter conditions are added here when they are set*/}
					<ProductTypesFilter productTypesInfo={productTypesInfo}
						condition={getAttributeCondition(productFilter.eoFilters, "productType")}
						onChange={onEoProductAttributeConditionChangeProductFilter} preparationMode={false}
					/>
					<OptionalEoProductFilter preparationMode={false} productTypesInfo={productTypesInfo} displayConditionFilter={productFilter}
						editConditionFilter={productFilter.eoFilters}
						onChange={onEoProductAttributeConditionChangeProductFilter} />
					<AddMoreConditions setNewFilters={onEoPopupClose} >
						<OptionalEoProductFilter preparationMode={true} productTypesInfo={productTypesInfo} displayConditionFilter={productFilter}
							editConditionFilter={eoPopupFilters}
							onChange={onEoProductAttributeConditionChangePopupFilter} />
					</AddMoreConditions>
				</Row>
				<Row className="mt-3 bg-insitu">
					<Col><h3>In-situ data</h3></Col>
					<Col className='ml-auto col-auto fs-4' title="When deactivated, in-situ data is not included in the search.">
						<Form.Check type="switch" id="insitu-switch" checked={productFilter?.withInsituFilters ?? false}
							label="" onChange={onChangeInsituFilterUse} />
					</Col>
				</Row>
				<Row className={"bg-insitu " + (productFilter?.withInsituFilters ? "" : "notused")} >
					<InsituTypesFilter productTypesInfo={networkTypesInfo}
						condition={getAttributeCondition(productFilter.insituFilters ?? [], AttributesEnum.networkName)}
						onChange={onInsituAttributeConditionChangeProductFilter} preparationMode={false} />
					<OptionalInsituProductFilter preparationMode={false} productTypesInfo={networkTypesInfo} displayConditionFilter={productFilter} editConditionFilter={productFilter.insituFilters ?? []}
						onChange={onInsituAttributeConditionChangeProductFilter} />
					<AddMoreConditions setNewFilters={onInsituPopupClose} >
						<OptionalInsituProductFilter preparationMode={true} productTypesInfo={networkTypesInfo} displayConditionFilter={productFilter} editConditionFilter={insituPopupFilters}
							onChange={onInsituProductAttributeConditionChangePopupFilter} />
					</AddMoreConditions>

				</Row>


				<Col xs={12} md={6} lg={12} className="ml-auto mt-md-4 mt-lg-0">
					<Container fluid>
						<Row>
							<Col>
								<Button variant={BDefault} type="submit" className="my-2 w-100">Search</Button>
							</Col>
							<Col>
								<Button variant={BActionDefault} className="my-2 w-100" onClick={() => setShowAddUserFilterDlg(true)} style={{ minWidth: "8rem" }}>Store Search</Button>
							</Col>
							<Col>
								<IconButton onClick={clearAllFilters} icon={deleteIcon("Clear all filter criteria")} />
							</Col>
						</Row>
					</Container>
				</Col>

			</Form>
			{showAddUserFilterDlg ? <UserFilterDialog dispatch={dispatch} onClose={() => setShowAddUserFilterDlg(false)} productFilter={productFilter} /> : null}
		</Container >
	);
}
