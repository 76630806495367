/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
export const environmentConfig = window._environmentConfig_ ? window._environmentConfig_ : {
	oidc: {
		webClientId: 'NA',
		apiClientId: 'NA',
		authority: 'NA',
	},
	api: {
		url: 'NA',
		minPublishedAgeInSeconds: 7200
	},
	enableDebuggingUIForAnonymousUsers: false,
	allAccountsAreBetatester: false,
}