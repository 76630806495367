/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React, { useState } from 'react';

import "react-datetime/css/react-datetime.css";
import './DateTimeInput.css'
import moment from 'moment';
import Datetime from "react-datetime";

function isEmpty(str) {
	return (!str || str.length === 0);
}

const inputProps = { className: "", placeholder: "Click to pick date time" }


/**
 * 
 * @param {*} initialValue "The initially set date as an ISO time string"
 * @param {*} "minDateValue The smallest date value that can be selected. No constraints if not set.""
 * 
 * 
 * @returns 
 */

export function DateTimeInput({ initialValue, minDateValue='', maxDateValue='', onValueChange = (val) => { } }) {
	const [valid, setValid] = useState(true);

	const forwardOnChangeWhenValid = (newValue) => {

		if (typeof newValue === 'object') {
			if (newValue.isValid()) {
				setValid(true)
				//format() results in something like "2016-05-03T20:15:01Z"
				onValueChange(newValue.utc(true).format())
			} else {
				setValid(false)
			}
		} else if (newValue === '') {
			setValid(true)
			onValueChange(null)
		} else {
			console.log("not an object")
			//setValue(null)
			setValid(false)
		}
	}

	const isAcceptableInputDate = (current) => {
		if (isEmpty(minDateValue) && isEmpty(maxDateValue)) {
			return true
		}
		if (!isEmpty(minDateValue)) {
			try {
				const md = moment.utc(minDateValue)
				if (md.isAfter(current)) {
					return false
				}
				else
				{
					return true
				}
			}
			catch {
				return true
			}
		}
		if (!isEmpty(maxDateValue)) {
			try {
				const md = moment.utc(maxDateValue)
				if (md.isBefore(current)) {
					return false
				}
				else
				{
					return true
				}
			}
			catch {
				return true
			}
		}
		return true
	}

	var initialTimeValue = ""
	try {
		if (!isEmpty(initialValue)) {
			initialTimeValue = moment.utc(initialValue)
		}
	}
	catch (e) {
		console.log(e)
	}
	// The following is a trick to get new values into Datetime when the values are good or from external
	// and still allow to allow uncontrolled editing with temporary invalid strings
	if (valid) {
		return (
			<div className="col pe-0 validDateTime" >
				{/* seems impossible to reset the input of a datetime field to empty, hence it is completely redrawn when set to empty */}
				{initialTimeValue === "" && <Datetime inputProps={inputProps} utc={true} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
					isValidDate={isAcceptableInputDate}
					onChange={forwardOnChangeWhenValid} />}
				{initialTimeValue > 0 && <Datetime inputProps={inputProps} utc={true} value={initialTimeValue} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
					isValidDate={isAcceptableInputDate}
					onChange={forwardOnChangeWhenValid} />}
			</div>
		);
	}
	else {
		return (
			<div className="col invalidDateTime">
				{/* seems impossible to reset the input of a datetime field to empty, hence it is completely redrawn when set to empty */}
				{initialTimeValue === "" && <Datetime inputProps={inputProps} utc={true} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
					isValidDate={isAcceptableInputDate}
					onChange={forwardOnChangeWhenValid} />}
				{initialTimeValue > 0 && <Datetime inputProps={inputProps} utc={true} initialValue={initialTimeValue} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"
					isValidDate={isAcceptableInputDate}
					onChange={forwardOnChangeWhenValid} />}
			</div>
		);
	}

}
