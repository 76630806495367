import { ObservationChannel, ObservationChannelType } from '../../utils/types';
import { NetworkConfigurationProps } from './NetworkConfiguration';


function sampleEntry(observations: any, channel: ObservationChannel) {
	if (channel.channelType === ObservationChannelType.INTEGER || channel.channelType === ObservationChannelType.LONG) {
		observations[channel.name] = "123"
	} else if (channel.channelType === ObservationChannelType.FLOAT || channel.channelType === ObservationChannelType.DOUBLE) {
		observations[channel.name] = "456.78"
	} else if (channel.channelType === ObservationChannelType.BOOLEAN) {
		observations[channel.name] = "true"
	} else if (channel.channelType === ObservationChannelType.ENUMERATION) {
		observations[channel.name] = "VALUE_A"
	} else if (channel.channelType === ObservationChannelType.STRING) {
		observations[channel.name] = "Lorem ipsum dolor sit amet"
	} else if (channel.channelType === ObservationChannelType.TAGS) {
		observations[channel.name] = "FOO, BAR"
	} else if (channel.channelType === ObservationChannelType.TIME) {
		observations[channel.name] = "1970-01-01T00:00:00.000000Z"
	} else if (channel.channelType === ObservationChannelType.KEYWORD) {
		observations[channel.name] = "FOO_BAR"
	} else if (channel.channelType === ObservationChannelType.POSITION) {
		observations[channel.latitudeName ?? "latitude"] = 43.12
		observations[channel.longitudeName ?? "longitude"] = 143.12
	} else {
		observations[channel.name] = "value"
	}
}

export const ID_CHANNEL_DEV = { name: "id", label: "ID", description: "Sensor ID", channelType: ObservationChannelType.KEYWORD } as ObservationChannel

export function SampleRecord({ networkDefinition,editable }: NetworkConfigurationProps) {
	const exampleRecord = { } as any
	networkDefinition.observationType.channels.forEach((c) => {
		sampleEntry(exampleRecord, c)
	})

	return <pre className='bg-white my-3 p-2'>
		{JSON.stringify(exampleRecord, null, 2)}
	</pre>
}
