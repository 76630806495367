/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react'
import { UserProfileStatus } from '../components/UserProfile/UserProfileStatus'
import { connect } from 'react-redux'
import { selectUserProfileFromStoreState } from "../state/store.oidc";
import { OnlyLoggedIn } from '../components/security/OnlyLoggedIn'
import { Helmet } from "react-helmet";
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import { SubTocEntry } from '../components/common/SubTocEntry';
import { Route, Routes } from 'react-router';

import { ConnectedApiClientAccessToken } from '../components/ApiClient/ApiClientAccessToken';
import { NetworkConfigurations } from '../components/networks/NetworkConfigurations';


const fullPath = (leaf: string) => "/page/profile/" + leaf;



const USER_PROFILE_SLUG = "userProfile";

const API_TOKEN_SLUG = "apiClientAccessToken";
const NETWORKS_SLUG = "networks";


function TableOfContent() {

	const toc = [
		{ path: USER_PROFILE_SLUG, title: "Profile" },
		{ path: API_TOKEN_SLUG, title: "API token" },
		{ path: NETWORKS_SLUG, title: "Networks" }
	]

	return (
		<Navbar expand="lg">
			<Navbar.Toggle />
			<Navbar.Collapse >
				<Nav className="flex-column">
					{
						toc.map((e, index) => <SubTocEntry path={fullPath(e.path)} title={e.title} key={index} />)
					}
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	)
}

interface UserProfilePageProps {
	userProfile: any
}

export function UserProfilePage({ userProfile }: UserProfilePageProps) {
	return (
		<OnlyLoggedIn loginHint userProfile={userProfile}>
			<Helmet>
				<title>IEOTO / profile</title>
			</Helmet>
			<Container fluid className='doc-page m-0 h-100' >
				<Row className='h-100 gx-0'>
					<Col sm={12} lg={2} xl={2} className="p-0" >
						<TableOfContent />
					</Col>
					<Col sm={12} lg={10} xl={10} className="p-0" >
						<Routes>
							<Route path="/" element={<UserProfileStatus userProfile={userProfile} />} />
							<Route path={USER_PROFILE_SLUG} element={<UserProfileStatus userProfile={userProfile} />} />
							<Route path={API_TOKEN_SLUG} element={<ConnectedApiClientAccessToken />} />
							<Route path={NETWORKS_SLUG} element={<NetworkConfigurations userProfile={userProfile} />} />
						</Routes>
					</Col>
				</Row>
			</Container>

		</OnlyLoggedIn>
	)
}
export const ConnectedUserProfilePage = connect(selectUserProfileFromStoreState)(UserProfilePage) 