/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react'

import { ConnectedApiClientAccessToken } from '../components/ApiClient/ApiClientAccessToken';
import { ConnectedOnlyLoggedIn } from '../components/security/OnlyLoggedIn'


export function ConnectedApiClientAccessTokenPage() {
	return (
		<ConnectedOnlyLoggedIn loginHint>
			<ConnectedApiClientAccessToken />
		</ConnectedOnlyLoggedIn>
	)
}
