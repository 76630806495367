/*
* This is replaced by sed during build time, see .gitlab-ci.yml
*/
const _buildVersion = "production.9ffeadd9904a64aefe74d8524705f526dc3ffec1";
const _buildTime = "2024-10-11T13:07:00Z";

const version = _buildVersion.includes("@@@") ? 'workstation.0000000000000000000000000000000000000000' : _buildVersion;
const regex = /(.+)\.([0-9a-f]{40})/;
const versionParts = regex.exec(version);
const loadTime = new Date();

export const buildInfo = {
	version: version,
	commitRefName: versionParts[1],
	commitHash: versionParts[2],
	buildTime: _buildTime.includes("@@@") ? loadTime.toISOString() : _buildTime
};