import { Button } from 'react-bootstrap';

type ErrorDescriptionProps = {
	errorcode: Number;
};

export function ApiCallErrorDescriptor({ errorcode }: ErrorDescriptionProps) {
	switch (errorcode) {
		case -1: 
			return null
		case 0:
			return null
		case 403:
			return <div>
				You have insufficent rights to perform this operation. Please check out the
				<Button variant="link" as="a" href="/page/doc/introduction">IEOTO account options</Button>
				if you are interested in the underlying functionality.
			</div>;
		default:
			return <div>
				An error {errorcode.toString()} occurred.
			</div>;

	}
}
