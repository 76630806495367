/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { Helmet } from "react-helmet";
import { serviceName } from "../../../pbconstants"
import EULA  from './eula'

const OUR_TERMS = () => <>
	<h1>{serviceName} Terms of use of service and data</h1>
	<h2>Introduction</h2>
	<p>
		{serviceName} is a data search index, catalogue and product service.
		The service provides free access as well as paid access schemes that enable additional
		functionality and data.
	</p>
	<h2>Data supply</h2>
	<p>
		A significant portion of its content is indexed and provided from a third party source to the user.
		The usage conditions of the original provider apply to the data and the original attribution rules apply.
	</p>
	<h2>Copyrights</h2>
	<p>
		Any Sentinel data available via this service is governed by the Legal Notice on the use of
		Copernicus Sentinel Data and Service Information.
	</p>
	<p>
		Any other data available via {serviceName} is governed by individual terms and
		conditions that are referenced in the product metadata.
	</p>
	<p>
		Any non-data contents of the {serviceName} websites are solely
		intended for direct information of the user and should not be used for other
		purposes without consent from Petabite GmbH.
	</p>
	<h2>User registration</h2>
	<p>
		The service provides minimal functions to non-registered users. To use the service in a meaningful way,
		the user can register to access the service with a personalized account.
	</p>
	<h2>Personal data privacy</h2>
	<p>
		When the user accesses the {serviceName} service, registers as a user or downloads content,
		certain technical information about users, such as internet protocol (IP) addresses, queries asked,
		query duration, data downloaded, pages visited, software used, as well as other technical information,
		will be stored briefly on Petabite servers.
	</p>
	<p>
		The collected information will be used by Petabite only for the general analysis of the service use
		and the observance of services load, performance and functionality relative to the overall usage patterns.
	</p>
	<p>
		For paying users additional personal information is gathered and handled to provide the
		requested service and accounting. A separate privacy policy statement governs the use of such data.
		Paid services are currently only available to business customers
		The respective document will be made available in due time.
	</p>
	<h2>Use of cookies</h2>
	<p>
		The {serviceName} service uses cookies strictly for technical purposes only,
		they are not used to trace user activity across sessions or to share user activity information
		with third parties.
	</p>
	<h2>Availability of the Service</h2>
	<p>
		Petabite will make best efforts to provide the services and provide its data access capabilities.
		However we do not guarantee the availability of the service at all times and reserve the right to
		change the service functionality and data portfolio of the service at any time, with or without prior notice.
	</p>
	<p>
		In case of service unavailability for Basic and Premium account functionality for more than 5 days in a month, half of the monthly 
		account price is reimbursed. Sub-day outages are not counted.
	</p>
	<p>
		In case of unavailability of processing account functionality for more than 48 hours in a month, re-imbursement is pro-rata. 
		For each commenced 24 h, 1/30th of the monthly processing option base fee is re-imbursed. 
	</p>
	<h2>Waiver of Liability</h2>
	<p>
		With regard to the service offered by Petabite through the {serviceName} service,
		the user shall have no claim against Petabite, its employees, related entities or employees
		of such entities, with respect to any injury to, or death of its own employees or employees
		of related entities or for damage to or loss of its own property or that of its related entities,
		whether such injury, death, damage or loss arise through negligence or otherwise, except in the
		case of gross negligence or wilful misconduct.
	</p>
	<h2>Use of the {serviceName} service</h2>
	<p>
		By registering at the service, accessing and\or downloading available
		data, the user shall not misuse or interfere the service of the portals.
	</p>
	<p>
		When submitting data to the service data storage interfaces, the user shall never 
		use the service to store any personal data 
		(as per General Data Protection Regulation of the European Union) of themselves or third parties.
	</p>
	<p>
		Petabite reserves the right to terminate any account without stating any reason.
	</p>
	<h2>Miscellaneous</h2>
	<p>
		The {serviceName} service uses third-party map layers as reference information
		for assisting data selection, Petabite and its suppliers offer no guarantees of any
		kind in connection to their accuracy or completeness.
	</p>
	<p>
		Furthermore with respect to national borders, naming of places or other geographical
		feature no statement with respect to their correctness or legitimacy is implied.
	</p>
</>

export default function Terms() {
	return (<>
		<Helmet>
			<title>home / doc / terms</title>
		</Helmet>

		<p className="lead">Please note: If you have signed in to the service. These are the terms and conditions that
			you have agreed upon, when you registered, or when you have been asked to update your agreement with the
			conditions during service use.</p>

		<OUR_TERMS />

		<hr/>

		<EULA />

	</>
	)
}


