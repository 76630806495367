/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { serviceName } from "../../../pbconstants"

const SENSOR_COMMUNITY_LICENSE = () => <>
	<h3>Sensor.Community Data License</h3>

	<p>Data from the Sensor.Community (<a href="https://sensor.community/">https://sensor.community/</a>) is governed by the 
		<a href="https://opendatacommons.org/licenses/dbcl/1-0/">Database Contents License (DbCL) v1.0</a>
	</p>

</>

const COPERNICUS_LICENCSE = () => <>
	<h3>Copernicus End User License Agreement</h3>

	<p>The access and use of Copernicus Sentinel Data and Service Information are regulated under EU law. In particular, the law provides that users shall have free, full, and open access to Copernicus Sentinel Data
		and Service Information without any express or implied warranty, including as regards quality and suitability for any purpose.</p>

	<p>EU law grants free access to Copernicus Sentinel Data and Service Information for lawful use, including:</p>

	<ul>
		<li>(a) reproduction</li>
		<li>(b) distribution</li>
		<li>(c) communication</li>
		<li>(d) adaptation, modification, and combination with other data and information</li>
	</ul>
	<p>EU law allows for specific limitations of access and uses in the rare cases of security concerns,
		protection of third-party rights, or risk of service disruption.</p>
	<p>By using Sentinel Data or Service Information the user acknowledges that these conditions are
		applicable to him/her and that the user renounces any claims for damages against the European Union and the providers of the said Data and Information. The scope of this waiver encompasses any dispute, including contracts and torts claims, that might be filed in court, in arbitration, or in any other form of dispute settlement.</p>
	<p>Where the user communicates to the public or distributes Copernicus Sentinel Data and Service Information,
		the source of that Data and Information shall be shown as follows:</p>
	<ul>
		<li>(1) ‘Copernicus Sentinel data [Year]’ for Sentinel data; and/or</li>
		<li>(2) ‘Copernicus Service information [Year]’ for Copernicus Service Information.</li>
	</ul>

	<p>Where the Copernicus Sentinel Data and Service Information have been adapted or modified,
		the user shall provide the following notice:</p>
	<ul>
		<li>(1) ‘Contains modified Copernicus Sentinel data [Year]’ for Sentinel data; and/or</li>
		<li>(2) ‘Contains modified Copernicus Service information [Year]’ for Copernicus Service Information.</li>
	</ul>

	<p>
		See also: Regulation (EU) No 377/2014 and Commission Delegated Regulation (EU) No 1159/2013 and
		the Copernicus Sentinel Data Policy, ESA/PB-EO(2013)30, rev. 1.
	</p>
</>

const PETABITE_LICENSE = () => <>
	<h3>Petabite Product License</h3>

	<p>This license is applicable to the product types PB_S1GRD_SHS, PB_S22A_SHS, PB_RLM_SHIP.</p>

	<p>Petabite grants the End User use of the data of the listed products subject to the acceptance of and agreement with the
		following End User License Agreement.</p>

	<h4>Section 1 Definitions</h4>

	<dl>
		<dt>End User</dt>
		<dd>refers to the person, business entity, or any other legal entity who obtains the
			Product. The end user is considered to have accepted this End User License Agreement. </dd>
		<dt>Product</dt>
		<dd>refers to a downloadable data entity that may consist of original data a third party, Petabite own data or derived data from third parties. </dd>
	</dl>

	<h4>Section 2 Permitted Uses</h4>

	<p>There are no immediate restrictions on their use. However when third party data is included in the data product,
		the respective third party license conditions apply.</p>

	<h4>Section 3 Prohibited Uses</h4>

	<p>The End User shall not remove or alter any copyright notice of the original Product.</p>

	<h4>Section 4 Intellectual Property Right and Copyright Notice</h4>

	<p>The End user confirms that Petabite or the third party from which the data originates retains the copyright to the products.</p>

	<p>The End User owns all intellectual property rights, including copyrights, of any derivative work generated by themselves within the limites of any third party license
		condition that applies to the source data.
	</p>

	<h4>Section 5 Limited Warranty – Disclaimer</h4>

	<p>Neither Petabite nor the any third Party involved in the provision of the data product and associated services, warrants that the Product is free of
		errors or omissions. Products are provided as is with no warranty of fitness for any particular purpose.</p>

	<p>Neither Petabite nor the any third Party involved in the provision of the data product and associated services  shall have any
		liability to the End User for any damage suffered by the End User or any third party, as a result of using their products.
		This damage is including but is not limited to consequential loss and lost earnings.
	</p>

	<h4>Section 6 Modification of this Agreement</h4>

	<p>Petabite reserves the right to modify this agreement. In the case of modification, the new agreement will be published on the service internet site.</p>

	<h4>Section 7 Governing Law and Jurisdiction</h4>

	<p>This End User License Agreement shall be governed by German law, unless applicable third party licenses stiuplate otherwise.</p>

</>

const OUR_EULA = () => <>

	<h2>End User License Agreement (EULA) for data</h2>


	<p> When using Petabite data products and products downloaded from the {serviceName} service. The following license condition apply.
		The individual license conditions are determined by the product type and content of the downoaded product.
	</p>

	<PETABITE_LICENSE />

	<h1>Applicable third party licenses</h1>

	<COPERNICUS_LICENCSE />

	<SENSOR_COMMUNITY_LICENSE />
</>

export default function EULA() {
	return (<OUR_EULA />)
}
