/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { getDefaultMiddleware } from '@reduxjs/toolkit'
//import { logger } from 'redux-logger';
import { USER_FOUND } from 'redux-oidc';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, KEY_PREFIX } from 'reduxjs-toolkit-persist';

export const defaultMiddlewares = []
	//	.concat(logger)
	.concat(getDefaultMiddleware({
		serializableCheck: {
			ignoredActions: [
				// ignore oidc-client.User, this is handeled by oidc-slice:
				USER_FOUND,
				// ignore persistance actions:
				FLUSH,
				REHYDRATE,
				PAUSE,
				PERSIST,
				PURGE,
				REGISTER
			],
		},
	}))



export function subscribeOnStoreBootstrapped(persistor, onStoreBootstrapped) {

	const _unsubscribe = persistor.subscribe(handlePersistorState)
	function handlePersistorState() {
		let { bootstrapped } = persistor.getState()
		if (bootstrapped) {
			_unsubscribe && _unsubscribe()
			onStoreBootstrapped()
		} else {
			console.debug("not yet bootstrapped")
		}
	}
	handlePersistorState()
}


export function initCrossTabSync(store, persistConfig) {
	const blocklist = persistConfig.blacklist || null
	const allowlist = persistConfig.whitelist || null
	const keyPrefix = persistConfig.keyPrefix || KEY_PREFIX

	const key = persistConfig.key
	const keyWithPrefix = keyPrefix + persistConfig.key

	window.addEventListener('storage', (storageEvent) => {
		if (storageEvent.key && storageEvent.key === keyWithPrefix) {
			if (storageEvent.oldValue === storageEvent.newValue) {
				return
			}
			//console.debug("handleStorageEvent", storageEvent)

			const stateFromEvent = JSON.parse(storageEvent.newValue)

			const stateToRehydrate = Object.keys(stateFromEvent).reduce((s, reducerKey) => {
				//console.debug(reducerKey, allowlist, blocklist)
				if (allowlist && allowlist.indexOf(reducerKey) === -1) {
					//console.debug("reducerKey does not matched allowlist", reducerKey)
					return s
				}
				if (blocklist && blocklist.indexOf(reducerKey) !== -1) {
					//console.debug("reducerKey matched blocklist", reducerKey)
					return s
				}

				s[reducerKey] = JSON.parse(stateFromEvent[reducerKey])

				return s
			}, {})

			//console.debug("crossTabSync got event from other tab, trigger rehydrate for ", key, " (", stateToRehydrate.storeName, ")")
			store.dispatch({
				key,
				payload: stateToRehydrate,
				type: REHYDRATE,
			})
		}
	}, false)
}
