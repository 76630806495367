/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { createSlice } from '@reduxjs/toolkit'
import { pscoutApi } from '../state/pscoutApi'
import { USER_FOUND } from 'redux-oidc';
import { handleApiError } from './store.msg'

export const userItemsSlice = createSlice({
	name: 'userItems',
	initialState: {
		loading: false,
		filterItemsLoaded: false,
		filterItems: []
	},
	reducers: {
		filterItemsLoading: (state, action) => {
			state.loading = true;
			state.filterItems = [];
		},
		filterItemsLoaded: (state, action) => {
			state.loading = false;
			state.filterItemsLoaded = true;
			state.filterItems = action.payload;
			state.error = false;
			state.errorMessage = undefined;
		},
		filterItemsLoadingFailed: (state, action) => {
			state.loading = false;
			state.filterItems = [];
			state.error = true;
			state.errorMessage = action.payload;
		},
		filterChanging: (state, action) => {
			state.filterItemsLoaded = false;
		},
	}
})

export const { filterItemsLoading, filterItemsLoaded, filterItemsLoadingFailed, filterChanging } = userItemsSlice.actions


export const selectUserFilterItemsFromStoreState = (state) => {
	return {
		filterItems: state.userItems.filterItems,
		filterItemsLoaded: state.userItems.filterItemsLoaded
	}
}


const reloadUserFilter = (dispatch) => {
	pscoutApi.getUserItems({ type: 'USER_FILTER' })
		.then((filterItems) => {
			if (filterItems) {
				dispatch(filterItemsLoaded(filterItems));
			}
		}).catch(handleApiError(dispatch))
}

userItemsSlice.middleware = (store) => (next) => (action) => {
	let result = next(action);
	if (action.type === USER_FOUND && !selectUserFilterItemsFromStoreState(store.getState()).filterItemsLoaded) {
		reloadUserFilter(store.dispatch);
	}
	return result;
}


export const refreshUserFilters = () => async (dispatch) => {
	reloadUserFilter(dispatch);
}


export const addUserFilter = (filterName, productFilter) => async (dispatch) => {
	dispatch(filterChanging());
	pscoutApi.addUserItem({
		type: "USER_FILTER",
		label: filterName,
		data: productFilter
	})
		.catch(handleApiError(dispatch))
		.then(() => { reloadUserFilter(dispatch) })
}

export const changeUserFilterName = (item, filterName) => async (dispatch) => {
	try {
		dispatch(filterChanging());
		pscoutApi.patchUserItem({
			uuid: item.uuid,
			lockVersion: item.lockVersion,
			label: filterName
		})
			.catch(handleApiError(dispatch))
			.then(() => { reloadUserFilter(dispatch) })
	} catch (err) {
		console.error(err);
	}
}

export const deleteUserFilter = (item) => async (dispatch) => {
	dispatch(filterChanging());
	pscoutApi.deleteUserItem(item.uuid)
		.catch(handleApiError(dispatch))
		.then(() => { reloadUserFilter(dispatch) })
}
