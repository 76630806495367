/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react';
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import dateformat from 'dateformat';
import MiddleEllipsis from 'react-middle-ellipsis';
import {
	ingestIcon, missionProgramIcon, missionNameIcon, satelliteIcon, productTypeIcon,
	productNameIcon, thingIdIcon, polarisationIcon, temporalCoverageStartIcon, temporalCoverageEndIcon, sizeIcon,
	passDirectionIcon, datatakeIdIcon, cycleIcon, relOrbitIcon, absOrbitIcon, coveragePercentCloudIcon,
	spatialCoverageIcon, temperatureIcon, measurementIcon, networkIcon, itemCountIcon, infoIcon, levelsIcon, associationsIcon, thingNameIcon, thingLabelIcon
} from './DataProductAttributeIcons'
import { copyToClipboardIcon, permaLinkIcon } from '../../common/Icons/Icons'
import { AttributesEnum } from '../../../utils/ApiTypes';
import { SupportEmail } from '../../doc/components/ServiceName';

const isChannelDescription = (obj) => {
	return obj.hasOwnProperty('channelName')
};

/**
 * DataProductAttribute*-components allow to display Product attributes in different contexts
 */
//this component is only for styleguidist to have a nice title
export function DataProductAttribute() {
	return <></>;
}

/**
  * Prevent ellipsis for some attributes
 */
function MEllipsis({ withEllipsis, children }) {
	if (withEllipsis) {
		return <MiddleEllipsis>{children}</MiddleEllipsis>
	}
	return <>{children}</>
}

function isEmptyAttributeValue(attributeValue) {
	if (!attributeValue) {
		return true
	}
	if (Array.isArray(attributeValue) && !attributeValue.length) {
		return true
	}
	return false
}


/**
 * Displays a single attribute of a Product with label and value as a search result
 * @param inSituContext set to true, when this is an in-situ product
 */
export function DataProductAttributeListEntry({ attributeName, attributeValue, unit, brief, inSituContext = false }) {
	try {

		if (isHiddenAttributeListEntry(attributeName)) {
			// never show hidden attributes
			return null
		}
		if ((brief && !isBriefAttributeListEntry(attributeName)) || isEmptyAttributeValue(attributeValue) ||
			(!inSituContext && !attributeIcons.get(attributeName))) {
			return null;
		} else {
			return <span className={'rounded m-1 mr-2 text-nowrap ' + ("satellite" === attributeName ? 'bg-light' : '')} style={{ maxWidth: "100%" }}>
				<MEllipsis withEllipsis={!isEllipsisForbidden(attributeName)}>
					<span>
						<DataProductAttributeLabelListEntry attributeName={attributeName} defaultIcon={inSituContext ? genericMeasurementIcon : null} />
						<DataProductAttributeValue attributeName={attributeName} attributeValue={attributeValue} />
					</span>
				</MEllipsis>
			</span>
		}
	}
	catch (e) {
		return <span title={"Could not render attribute " + attributeName}>Error</span>
	}
}

/**
 * Displays a single attribute of a Product with label and value as a table row
 */
export function DataProductAttributeTableRow({ attributeName, attributeValue }) {
	if (!attributeValue || isHiddenAttributeTableRow(attributeName) || isEmptyAttributeValue(attributeValue) || !attributeIcons.get(attributeName)) {
		return null;
	} else {
		return <tr >
			<td>
				<DataProductAttributeLabelTableRow attributeName={attributeName} />
			</td>
			<td>

				<DataProductAttributeValue attributeName={attributeName} attributeValue={attributeValue} />

			</td>
		</tr>
	}
}

export function DataProductAttributeLabelListEntry({ attributeName, defaultIcon }) {

	if (attributeIcons.has(attributeName)) {
		return <>{attributeIcons.get(attributeName)} </>
	}
	else if (defaultIcon) {
		if (attributeNamesEn.has(attributeName)) {
			return <>{defaultIcon} {attributeNamesEn.get(attributeName)}: </>
		} else {
			return <>{defaultIcon} {attributeName}: </>
		}
	}
	else {
		return <>{attributeName}: </>
	}
}
export function DataProductAttributeLabelTableRow({ attributeName, defaultLabel }) {
	if (attributeIcons.has(attributeName)) {
		if (attributeNamesEn.has(attributeName)) {
			//icon and translated name
			return <>{attributeIcons.get(attributeName)} {attributeNamesEn.get(attributeName)}</>
		} else {
			//only icon
			return <>{attributeIcons.get(attributeName)} {attributeName}</>
		}
	} else {
		if (attributeNamesEn.has(attributeName)) {
			// only translated name
			return <>{attributeNamesEn.get(attributeName)}</>
		} else {
			//nothing
			return null;
		}
	}
}


function ChannelDescription({ attributeValue }) {

	// still to handle:	 STRING, TAGS, TIME, POSITION

	if (["KEYWORD", "ENUMERATION", "BOOLEAN"].includes(attributeValue.channelType)) {
		return <>
			{attributeValue.values.slice(0, 3).map((v, index) => <span key={v}>{index === 0 ? "" : " ,"} {v}</span>)}
		</>
	} else if (attributeValue.constantValue === true) {
		// a constant value does not need to show min and max
		return <span>
			{attributeValue.avg && <><span title="Constant in aggregation interval" style={{ color: "green" }}>{attributeValue.avg?.toFixed(2)}</span></>}
			{attributeValue.channelUnit && <span> {attributeValue.channelUnit}</span>}
		</span>
	} else if (['INTEGER', 'LONG', 'FLOAT', 'DOUBLE'].includes(attributeValue.channelType)) {
		return <span>(
			{attributeValue.min && <span title="Minimum in aggregation interval" style={{ color: "blue" }}>{attributeValue.min?.toFixed(2)}</span>}
			{attributeValue.avg && <>/<span title="Mean in aggregation interval" style={{ color: "green" }}>{attributeValue.avg?.toFixed(2)}</span></>}
			{attributeValue.max && <>/<span title="Maximum in aggregation interval" style={{ color: "red" }}>{attributeValue.max?.toFixed(2)}</span></>}
			) &nbsp;
			{attributeValue.channelUnit && <span>{attributeValue.channelUnit}</span>}
		</span>

	} else {
		return null
	}

}

export function DataProductAttributeValue({ attributeName, attributeValue }) {
	try {
		if (attributeValue) {
			if (attributeValueRenders.has(attributeName)) {
				const renderedAttribute = attributeValueRenders.get(attributeName)(attributeValue)
				return <span className="ellipseMe" data-toggle="tooltip"
					title={attributeTooltipRenders.has(attributeName) ? attributeTooltipRenders.get(attributeName)(attributeValue) : null}>
					{renderedAttribute}
				</span>
			} else {
				if (isChannelDescription(attributeValue)) {
					return <ChannelDescription attributeValue={attributeValue} />
				}
				else {
					var renderedAttribute = attributeValue
					if (typeof renderedAttribute === 'string') {
						if (renderedAttribute.startsWith("http")) {
							renderedAttribute = <a href={renderedAttribute} target="_blank" rel="noreferrer">{renderedAttribute}</a>
						}
					}
					return <span className="ellipseMe" data-toggle="tooltip" onClick={() => navigator.clipboard.writeText(renderedAttribute)}
						title={attributeTooltipRenders.has(attributeName) ? attributeTooltipRenders.get(attributeName)(attributeValue) : null}>
						{renderedAttribute}
					</span>
				}
			}
		} else {
			return null;
		}
	}
	catch (e) {
		return <span title="Sorry could not render attribute value."><SupportEmail label='Error' body={"Could not render " + JSON.stringify(attributeName) + " with value " + JSON.stringify(attributeValue)} /></span>
	}

}

const isBriefAttributeListEntry = (attributeName) => {
	return briefAttributesListEntry.includes(attributeName);
}
/**
 * List of brief product attributes to be shown in summary views.
 */
const briefAttributesListEntry = [
	AttributesEnum.satellite,
	AttributesEnum.networkName,
	AttributesEnum.productType,
	AttributesEnum.sensingCycle,
	AttributesEnum.sensingRelativeOrbitNumber,
	AttributesEnum.temporalCoverageStart,
	AttributesEnum.productSizeInBytes,
	AttributesEnum.coveragePercentCloud,
	AttributesEnum.productName,
	AttributesEnum.thingId,
	AttributesEnum.thingName
];

const isEllipsisForbidden = (attributeName) => {
	return ellipsisForbiddenAttributesListEntry.includes(attributeName);
}

/**
 * List of product attributes that should never be shortened with middle ellipsis.
 */
const ellipsisForbiddenAttributesListEntry = [

	'coveragePercentCloud',
	'temporalCoverageStart',
	'temporalCoverageEnd'
];

const isHiddenAttributeListEntry = (attributeName) => {
	return hiddenAttributesListEntry.includes(attributeName);
}
/**
 * List of hidden product attributes never to be shown in overviews.
 */
const hiddenAttributesListEntry = [
	'id',
	'lastModified',
	'spatialCoverage',
	'thumbnailURL',
	'quicklookURL',
	'shortLinkURL',
	//'associations', //FIXME  implement rendering
	'tags', //FIXME  implement rendering
	'units',
	'channels',
	'displayChannel',
	'spatialCoverageBoundingBox',
	'spatialCoverageGeoJson',
	'productClass',
	'ownerName',
	'networkConfigVersion'
];

const isHiddenAttributeTableRow = (attributeName) => {
	return hiddenAttributesTableRow.includes(attributeName);
}
/**
 * List of hidden product attributes never to be shown in tables.
 */
const hiddenAttributesTableRow = [
	'id',
	'lastModified',
	'thumbnailURL',
	'quicklookURL',
	'shortLinkURL',

	'channels', //FIXME implement rendering
	'displayChannel', //FIXME  implement rendering
	'tags',
	'units'
];

/**
 * Product attributes icon lookup table.
 */
const attributeIcons = new Map()
	.set(AttributesEnum.published, ingestIcon)
	.set(AttributesEnum.missionProgram, missionProgramIcon)
	.set(AttributesEnum.missionName, missionNameIcon)
	.set(AttributesEnum.satellite, satelliteIcon)
	.set(AttributesEnum.productType, productTypeIcon)
	.set(AttributesEnum.productName, productNameIcon)
	.set(AttributesEnum.polarization, polarisationIcon)
	.set(AttributesEnum.temporalCoverageStart, temporalCoverageStartIcon)
	.set(AttributesEnum.temporalCoverageEnd, temporalCoverageEndIcon)
	.set(AttributesEnum.productSizeInBytes, sizeIcon)
	.set(AttributesEnum.sensingOrbitDirection, passDirectionIcon)
	.set(AttributesEnum.sensingDatatakeId, datatakeIdIcon)
	.set(AttributesEnum.sensingCycle, cycleIcon)
	.set(AttributesEnum.sensingRelativeOrbitNumber, relOrbitIcon)
	.set(AttributesEnum.sensingAbsoluteOrbitNumber, absOrbitIcon)
	.set(AttributesEnum.coveragePercentCloud, coveragePercentCloudIcon)
	.set(AttributesEnum.spatialCoverage, spatialCoverageIcon)
	.set("temperature", temperatureIcon)
	.set(AttributesEnum.networkLabel, networkIcon)
	.set(AttributesEnum.networkName, networkIcon)
	.set(AttributesEnum.thingId, thingIdIcon)
	.set(AttributesEnum.thingLabel, thingLabelIcon)
	.set(AttributesEnum.thingName, thingNameIcon)
	.set(AttributesEnum.thingInfo, infoIcon)
	.set(AttributesEnum.aggregationLevels, levelsIcon)
	.set(AttributesEnum.numObservations, itemCountIcon())
	.set(AttributesEnum.associations, associationsIcon)
	;

const genericMeasurementIcon = measurementIcon

const renderTimestamp = (timestamp) => {
	return dateformat(timestamp, "UTC:yyyy-mm-dd HH:MM");
}
const renderPercent = (percentValue) => {
	if (percentValue) {
		return Math.round(percentValue) + ' %'
	} else {
		return percentValue;
	}
}
const renderSizeInBytes = (sizeInBytes) => {
	if (sizeInBytes) {
		return formatBytes(sizeInBytes, sizeInBytes < 1073741824 ? 0 : 1);
	} else {
		return sizeInBytes;
	}
}
/**
 * format bytes with unit
 * Source: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
 * License: CC0
 */
const formatBytes = (bytes, decimals = 2) => {
	if (bytes === 0) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
const renderOrbitDirection = (orbitDirection) => {
	switch (orbitDirection) {
		case 'ASCENDING':
			return 'ASC';
		case 'DESCENDING':
			return 'DESC';
		default:
			return orbitDirection;
	}
}


const onSpatialCoverageToClipboardButtonClick = (spatialCoverage) => () => {
	navigator.clipboard.writeText(JSON.stringify(spatialCoverage)).then(function () {
	}, function (error) {
		console.log("Failed to copy to clipboard.", error);
	})
}
const onShowOnGeojsonIoButtonClick = (spatialCoverage) => () => {
	// Workaround, as geojson.io rejects deprecated crs attribute
	// see https://www.rfc-editor.org/rfc/rfc7946#appendix-B.1
	const spatialCoverageWithoutCrs = { ...spatialCoverage }
	delete spatialCoverageWithoutCrs.crs;
	const geojsonUrl = 'http://geojson.io/#data=data:application/json,' + encodeURIComponent(JSON.stringify(spatialCoverageWithoutCrs))
	window.open(geojsonUrl, "_blank");
}
const renderSpatialCoverage = (spatialCoverage) => {
	return <Row>
		<Button variant='light' className="mx-2" onClick={onSpatialCoverageToClipboardButtonClick(spatialCoverage)} >{copyToClipboardIcon} Copy as GeoJson</Button>
		<Button variant='light' onClick={onShowOnGeojsonIoButtonClick(spatialCoverage)} >{permaLinkIcon} Show on geojson.io</Button>
	</Row>
}

const renderAggregationLevels = (aggregationLevels) => {
	return aggregationLevels.slice(-1)[0]; //last element of array
}

function Role({ roleCode }) {
	if (roleCode === "is_in_realm") {
		return <span title="Target product is covered by this realm product">In realm</span>
	} else {
		return <span>{roleCode}</span>
	}
}

function AssociationInEntry({ association }) {
	return <span title={association.productName}>(<Role roleCode={association.role} />:&nbsp;{association.productType})</span>
}

// const renderArrayAttribute = (arrayAttribute) => {
// 	return <span>{JSON.stringify(arrayAttribute)}</span>
// }


const renderAssociationsAttribute = (arrayAttribute) => {
	return <span>{arrayAttribute.map(a => <AssociationInEntry key={a.productName} association={a} />)}</span>
}

const valueAsTooltip = (value) => {
	return value
}

const valueAsTooltipClickInfo = (value) => {
	return value + "\nClick on value to copy it into the clipboard!"
}

const productSizeInBytesAsTooltip = (productSizeInBytes) => {
	return new Intl.NumberFormat().format(productSizeInBytes) + ' bytes'
}

/**
 * Product attribute names lookup table.
 */
const attributeNamesEn = new Map()
	.set('published', 'Ingestion Time')
	.set('missionProgram', 'Mission Program')
	.set('missionName', 'Mission Name')
	.set('satellite', 'Satellite')
	.set('productType', 'Product Type')
	.set('productName', 'Product Name')
	.set('polarization', 'Polarisation')
	.set('temporalCoverageStart', 'Start Time')
	.set('temporalCoverageEnd', 'Stop Time')
	.set('productSizeInBytes', 'Total Size')
	.set('sensingOrbitDirection', 'Pass Direction')
	.set('sensingDatatakeId', 'Datatake ID')
	.set('sensingCycle', 'Orbit Cycle')
	.set('sensingRelativeOrbitNumber', 'Relative Orbit')
	.set('sensingAbsoluteOrbitNumber', 'Absolute Orbit')
	.set('coveragePercentCloud', 'Cloud Coverage')
	.set('thingId', 'ID')
	.set('aggregationLevels', 'Level')
	;

/**
 * Product attributes value rendering function lookup table.
 */
const attributeValueRenders = new Map()
	.set('published', renderTimestamp)
	.set('temporalCoverageStart', renderTimestamp)
	.set('temporalCoverageEnd', renderTimestamp)
	.set('productSizeInBytes', renderSizeInBytes)
	.set('sensingOrbitDirection', renderOrbitDirection)
	.set('coveragePercentCloud', renderPercent)
	.set('spatialCoverage', renderSpatialCoverage)
	.set('tags'.renderArrayAttribute)
	.set('associations', renderAssociationsAttribute)
	.set('aggregationLevels', renderAggregationLevels)
	;

const attributeTooltipRenders = new Map()
	.set('published', valueAsTooltip)
	.set('temporalCoverageStart', valueAsTooltip)
	.set('temporalCoverageEnd', valueAsTooltip)
	.set('productSizeInBytes', productSizeInBytesAsTooltip)
	.set('productName', valueAsTooltipClickInfo)
	;