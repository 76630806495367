/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import Button from 'react-bootstrap/Button'
import {
	expandArrowIcon,
	collapseArrowIcon,
	expandIcon,
	collapseIcon
} from '../Icons/Icons'


export function ExpandCollapseButton({ collapsed, onClick, arrow = true }) {
	if (arrow) {
		return (<Button variant="link" onClick={onClick}>{collapsed ? expandArrowIcon : collapseArrowIcon}</Button>);
	} else {
		return (<Button variant="link" onClick={onClick}>{collapsed ? expandIcon : collapseIcon}</Button>);
	}
}


