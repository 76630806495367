/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */


export const pbSquaresUrl = "/img/pb_squares.png";

export function PetabiteSquaresImage() {
	return (<img src={pbSquaresUrl} width="200px" height="69px" alt="Petabite GmbH Icon"/>)
}

