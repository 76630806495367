/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { createUserManager } from 'redux-oidc';
import { WebStorageStateStore } from 'oidc-client'
import { environmentConfig } from './environmentConfigLoader';

const apiClientStorageStateStore = new WebStorageStateStore({ prefix: "apiClientOidc.", store: sessionStorage })
const apiClientStorageUserStore = new WebStorageStateStore({ prefix: "apiClientOidc.", store: sessionStorage })

const apiClientUserManagerConfig = {
	client_id: environmentConfig.oidc.apiClientId,
	redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/page/oidcApiClientCallback`,
	post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/page/oidcApiClientCallback`,
	response_type: 'code',
	scope: 'openid offline_access',
	authority: environmentConfig.oidc.authority,
	automaticSilentRenew: false,
	filterProtocolClaims: true,
	loadUserInfo: false,
	monitorSession: false,
	revokeAccessTokenOnSignout: false,
	accessTokenExpiringNotificationTime: Math.floor((Math.random() * 30)) + 45, //refresh access token between 45 and 75 seconds before it expires
	stateStore: apiClientStorageStateStore,
	userStore: apiClientStorageUserStore,
};

export const apiClientUserManager = createUserManager(apiClientUserManagerConfig);
