import { Button, Col, OverlayTrigger, Popover, PopoverBody, Row } from "react-bootstrap";
import JSONPretty from "react-json-pretty";
import { BDefault } from "./StyleSettings";
import { copyToClipboardIcon } from "./Icons/Icons";

interface ShowProductJsonProps {
	product: any
}


export function ShowItemJson({ product }: ShowProductJsonProps) {

	const copyToClipboardButtonClick = () => {
		navigator.clipboard.writeText(JSON.stringify(product)).then(function () {
		}, function (error) {
			console.log("Failed to copy to clipboard.", error);
		})
	}

	return <OverlayTrigger trigger="click" placement="bottom" rootClose={true}
		overlay={<Popover id="popover" className="px-0" >
			<PopoverBody className="bg-secondary p-2 border">
				<Row className='bg-white p-1 m-2'>
					<JSONPretty id="json-pretty" data={product}></JSONPretty>

				</Row>
				<Row className="mt-3 justify-content-center">
					<Col className='col-auto'><Button variant={BDefault} onClick={copyToClipboardButtonClick}>{copyToClipboardIcon}</Button></Col>
					<Col className='col-auto'><Button variant={BDefault} onClick={() => document.body.click()}>Close</Button></Col>
				</Row>
			</PopoverBody>
		</Popover>}  >
		<Button variant={BDefault} className="">J</Button>

	</OverlayTrigger>
}
