/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { PetabiteContact } from '../components/PetabiteContact'
import { Helmet } from "react-helmet";
import Container from 'react-bootstrap/Container';

const { PetabiteLogoImageHeader } = require('../components/PetabiteLogoImageHeader')
export default function Imprint() {
	return (<>
		<Helmet>
			<title>home / doc / imprint</title>
		</Helmet>
		<PetabiteLogoImageHeader />
		<Container className="m-4">
			<PetabiteContact />
			<div><span className="fw-bold">Registergericht/Register Court:</span> Amtsgericht Lüneburg, HRB 208770</div>
			<div><span className="fw-bold">VAT ID:</span> DE329864235</div>
			<div><span className="fw-bold">Geschäftsführer/Managing Director:</span> Nils Junike, Bernard Pruin</div>
		</Container></>
	)
}