import React from 'react';

export const IconPetabite = () => { return <svg
   xmlns="http://www.w3.org/2000/svg"
   width="40"
   height="40"
   viewBox="0 0 171.28792 187.94125"
   aria-label="Petabite logo" >
   <g
   id="g10"
   transform="matrix(1.3333333,0,0,-1.3333333,-630.92869,429.79128)"><g
     id="g12"
     transform="scale(0.1)"><path
   d="m 4778.75,2417.27 c 0,-59.76 0,-119.51 0,-179.27 54.7,0 109.41,0 164.11,0 0,59.76 0,119.51 0,179.27 -54.7,0 -109.41,0 -164.11,0 z"
   style={{ fill:"none",stroke:"#67942d",strokeWidth:93.56939697,strokeLinecap:"square",strokeLinejoin:"miter",strokeMiterlimit:4,strokeDasharray:"none",strokeOpacity:1 }}
   id="path16" /><path
   d="m 5121.07,2417.27 c 0,-59.76 0,-119.51 0,-179.27 54.71,0 109.42,0 164.12,0 0,59.76 0,119.51 0,179.27 -54.7,0 -109.41,0 -164.12,0 z"
   style={{ fill:"none",stroke:"#d051a3",strokeWidth:93.56939697,strokeLinecap:"square",strokeLinejoin:"miter",strokeMiterlimit:4,strokeDasharray:"none",strokeOpacity:1 }}
   id="path18"
    /><path
   d="m 5463.4,2039.94 c 0,-59.77 0,-119.51 0,-179.28 54.7,0 109.42,0 164.12,0 0,59.77 0,119.51 0,179.28 -54.7,0 -109.42,0 -164.12,0 z"
   style={{ fill:"none",stroke:"#f47216",strokeWidth:93.56939697,strokeLinecap:"square",strokeLinejoin:"miter",strokeMiterlimit:4,strokeDasharray:"none",strokeOpacity:1 }}
   id="path20"
   /><path
   d="m 4778.75,2796.97 c 0,-59.76 0,-119.5 0,-179.26 54.7,0 109.41,0 164.11,0 0,59.76 0,119.5 0,179.26 -54.7,0 -109.41,0 -164.11,0 z"
   style={{ fill:"none",stroke:"#6e87cd",strokeWidth:93.56939697,strokeLinecap:"square",strokeLinejoin:"miter",strokeMiterlimit:4,strokeDasharray:"none",strokeOpacity:1 }}
   id="path22"
   /><path
   d="m 5121.07,2796.97 c 0,-59.76 0,-119.5 0,-179.26 54.71,0 109.42,0 164.12,0 0,59.76 0,119.5 0,179.26 -54.7,0 -109.41,0 -164.12,0 z"
   style={{ fill:"none",stroke:"#fab24a",strokeWidth:93.56939697,strokeLinecap:"square",strokeLinejoin:"miter",strokeMiterlimit:4,strokeDasharray:"none",strokeOpacity:1 }}
   id="path24"
   /><path
   d="m 5463.4,2796.97 c 0,-59.76 0,-119.5 0,-179.26 54.7,0 109.42,0 164.12,0 0,59.76 0,119.5 0,179.26 -54.7,0 -109.42,0 -164.12,0 z"
   style={{ fill:"none",stroke:"#474757",strokeWidth:93.56939697,strokeLinecap:"square",strokeLinejoin:"miter",strokeMiterlimit:4,strokeDasharray:"none",strokeOpacity:1 }}
   id="path26"
   /><path
   d="m 5805.73,2417.22 c 0,-59.74 0,-119.49 0,-179.23 54.7,0 109.41,0 164.11,0 0,59.74 0,119.49 0,179.23 -54.7,0 -109.41,0 -164.11,0 z"
   style={{ fill:"none",stroke:"#519383",strokeWidth:93.56939697,strokeLinecap:"square",strokeLinejoin:"miter",strokeMiterlimit:4,strokeDasharray:"none",strokeOpacity:1 }}
   id="path28"
   /><path
   d="m 5463.4,2417.22 c 0,-59.74 0,-119.49 0,-179.23 54.7,0 109.42,0 164.12,0 0,59.74 0,119.49 0,179.23 -54.7,0 -109.42,0 -164.12,0 z"
   style={{ fill:"none",stroke:"#444885",strokeWidth:93.56939697,strokeLinecap:"square",strokeLinejoin:"miter",strokeMiterlimit:4,strokeDasharray:"none",strokeOpacity:1 }}
   id="path30"
   /><path
   d="m 5463.4,3176.65 c 0,-59.75 0,-119.49 0,-179.24 54.7,0 109.42,0 164.12,0 0,59.75 0,119.49 0,179.24 -54.7,0 -109.42,0 -164.12,0 z"
   style={{ fill:"none",stroke:"#b53b45",strokeWidth:93.56939697,strokeLinecap:"square",strokeLinejoin:"miter",strokeMiterlimit:4,strokeDasharray:"none",strokeOpacity:1 }}
   id="path32"
   /><path
   d="m 5805.73,2796.97 c 0,-59.76 0,-119.5 0,-179.26 54.7,0 109.41,0 164.11,0 0,59.76 0,119.5 0,179.26 -54.7,0 -109.41,0 -164.11,0 z"
   style={{ fill:"none",stroke:"#3d90d5",strokeWidth:93.56939697,strokeLinecap:"square",strokeLinejoin:"miter",strokeMiterlimit:4,strokeDasharray:"none",strokeOpacity:1 }}
   id="path34"
   /><path
   d="m 5121.07,3176.65 c 0,-59.77 0,-119.51 0,-179.28 54.71,0 109.42,0 164.12,0 0,59.77 0,119.51 0,179.28 -54.7,0 -109.41,0 -164.12,0 z"
   style={{ fill:"none",stroke:"#327d6e",strokeWidth:93.56939697,strokeLinecap:"square",strokeLinejoin:"miter",strokeMiterlimit:4,strokeDasharray:"none",strokeOpacity:1 }}
   id="path36"
   />
</g></g></svg>
};