/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react';

import 'jquery'

import './index.scss'
import { Provider } from 'react-redux'
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react'
import OidcProviderPatched from './components/security/oidc/OidcProviderPatched'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { mainStore, mainStorePersistor } from './state/mainStore';
import { webClientUserManager } from './state/webClientUserManager';
import { ConnectedApp } from './App';
import { LoadingSpinner } from './components/common/LoadingSpinner/LoadingSpinner';
import { ConnectedOidcCallbackPage } from './components/security/oidc/OidcCallbackPage';
import { apiClientUserManager } from './state/apiClientUserManager';
import { apiClientStore, ApiClientStoreContext } from './state/apiClientStore';
import { } from './third-party-code/window.polyfill'
import { Helmet } from "react-helmet";
import { createRoot } from "react-dom/client"

mainStore.initStore();
apiClientStore.initStore();

const domNode = document.getElementById('root');
const root = createRoot(domNode)

root.render(
	<React.StrictMode>
		<Provider store={mainStore}>
			<Helmet>
				<title>IEOTO</title>
			</Helmet>
			<PersistGate loading={<LoadingSpinner loading />} persistor={mainStorePersistor}>
				<Provider store={apiClientStore} context={ApiClientStoreContext} >
					<OidcProviderPatched store={mainStore} userManager={webClientUserManager}>
						<OidcProviderPatched store={apiClientStore} userManager={apiClientUserManager}>
							<BrowserRouter>
								<Routes>
									<Route path="/page/oidcWebClientCallback" exact element={
										<ConnectedOidcCallbackPage userManager={webClientUserManager} targetURL="/" />
									} />
									<Route path="/page/oidcApiClientCallback" exact element={
										<ConnectedOidcCallbackPage userManager={apiClientUserManager} targetURL="/page/apiClientAccessToken" />
									} />
									<Route path="*" element={<ConnectedApp />}></Route>
								</Routes>
							</BrowserRouter>
						</OidcProviderPatched>
					</OidcProviderPatched>
				</Provider>
			</PersistGate>
		</Provider>
	</React.StrictMode>
);

