/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { Route, Routes } from 'react-router-dom';

import { SN } from '../components/doc/components/ServiceName'
import { IconPetabite } from '../components/doc/components/IconPetabite'

/* eslint-disable import/no-webpack-loader-syntax */
import Intro from '../components/doc/00-introduction/introduction'
import Support from '../components/doc/00-introduction/support'
import Web from '../components/doc/20-web/web'
import WebSearch from '../components/doc/22-web-search/web-search'
import StoredSearches from '../components/doc/22-web-search/stored-searches';
import Bookmarks from '../components/doc/22-web-search/bookmarks';
import WebMap from '../components/doc/24-web-map/web-map'
import WebList from '../components/doc/26-web-list/web-list'
import Api from '../components/doc/40-api/api'
import Ack from '../components/doc/80-ack/ack'
import Terms from '../components/doc/85-terms/terms'
import Legal from '../components/doc/90-legal/legal'
import Imprint from '../components/doc/90-legal/imprint'
import { Helmet } from "react-helmet";
import { SubTocEntry } from '../components/common/SubTocEntry';

const fullPath = (leaf) => "/page/doc/" + leaf;

const introSlug = "introduction"
const supportSlug = "support"
const webSlug = "web"
const webSearchSlug = "websearch"
const storedSearchesSlug = "storedsearches"
const bookmarksSlug = "bookmarks"
const webMapSlug = "webmap"
const webListSlug = "weblist"
const apiSlug = "api"
const ackSlug = "ack"
const termsSlug = "terms"
export const legalSlug = "legal"
export const imprintSlug = "imprint"



function TableOfContent() {

	const toc = [
		{ path: "introduction", title: "Introduction" },
		{ path: supportSlug, title: "Support" },
		{ path: webSlug, title: "Web service" },
		{ path: webSearchSlug, title: "Search" },
		{ path: storedSearchesSlug, title: "Stored searches" },
		{ path: bookmarksSlug, title: "Bookmarks" },
		{ path: webMapSlug, title: "Map view" },
		{ path: webListSlug, title: "List view" },
		{ path: apiSlug, title: "API functions" },
		{ path: ackSlug, title: "Attributions" },
		{ path: termsSlug, title: "Terms and Conditions" },
		{ path: legalSlug, title: "Privacy policy" },
		{ path: imprintSlug, title: "Imprint" },
	]

	return (
		<Navbar expand="lg">
			<Navbar.Toggle />
			<Navbar.Collapse >
				<Nav className="flex-column">
					{
						toc.map((e, index) => <SubTocEntry path={fullPath(e.path)} title={e.title} key={index} />)
					}
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	)
}

export function DocPage() {
	return (
		<>
			<Helmet>
				<title>IEOTO / documentation</title>
			</Helmet>
			<Container fluid className='doc-page m-0 h-100' >
				<Row className='h-100 gx-0'>
					<Col sm={12} lg={2} xl={2} className="p-0" >
						<div className="w-100 doc-page-header d-none d-lg-block" aria-label='Petabite icon'> <IconPetabite /> </div>
						<TableOfContent />
					</Col>
					<Col sm={12} lg={10} xl={10} className="p-0" >

						<h1 className="w-100 doc-page-header d-none d-lg-block"> <SN /> service documentation</h1>

						<Container className="doc-page-content" aria-label="Documentation content">
							<Routes>
								<Route path="/" element={<Intro/>} exact />
								<Route path={introSlug} element={<Intro />} exact />
								<Route path={supportSlug} element={<Support />} exact />
								<Route path={webSlug} element={<Web/>} exact />
								<Route path={webSearchSlug} element={<WebSearch/>} exact />
								<Route path={storedSearchesSlug} element={<StoredSearches/>} exact />
								<Route path={bookmarksSlug} element={<Bookmarks/>} exact />
								<Route path={webMapSlug} element={<WebMap/>} exact />
								<Route path={webListSlug} element={<WebList/>} exact />
								<Route path={apiSlug} element={<Api/>} exact />
								<Route path={ackSlug} element={<Ack/>} exact />
								<Route path={termsSlug} element={<Terms/>} exact />
								<Route path={legalSlug} element={<Legal/>} exact />
								<Route path={imprintSlug} element={<Imprint/>} exact />
							</Routes>
						</Container>
					</Col>
				</Row>
			</Container>
		</>
	);
}
