/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistCombineReducers, createMigrate } from 'reduxjs-toolkit-persist'
import storage from 'reduxjs-toolkit-persist/lib/storage'
import autoMergeLevel1 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1'

import { oidcSlice, selectOidcAccessTokenFromStoreState, loadUserFromReduxStoreToUserManager } from './store.oidc'
import { userItemsSlice } from './store.userItems'
import { userBookmarkedSlice } from './store.userItemsBookmarked'
import { dataProductsSlice } from './store.dataProducts'
import { storeNameSlice } from './store.storeName'
import { msgSlice } from './store.msg'
import { defaultMiddlewares, initCrossTabSync, subscribeOnStoreBootstrapped } from './store-utils';
import { webClientUserManager } from "./webClientUserManager";
import { pscoutApi } from './pscoutApi'

const storeName = 'mainStore'

const migrations = {
  1: (state) => {
    console.debug(`migrating store ${state.storeName} from V${state._persist.version} to V1`)
    return {
      storeName: state.storeName,
      oidc: state.oidc
    }
  }
}
const persistConfig = {
	key: storeName,
	storage: storage,
	stateReconciler: autoMergeLevel1,
	throttle: 10, //max one write per x ms
	version: 1, //increment whenever store needs migration
	blacklist: null,
	whitelist: [ // list of slice-names to be peristed between browser-sessions and synced between tabs
		'storeName',
		'oidc'
	],
	migrate: createMigrate(migrations, { debug: false }),
}

const middleware = defaultMiddlewares
	.concat(oidcSlice.middleware)
	.concat(dataProductsSlice.middleware)
	.concat(userItemsSlice.middleware)
	.concat(userBookmarkedSlice.middleware);

const preloadedState = {
	storeName: storeName
}

export const mainStore = {
	...configureStore({
		reducer: persistCombineReducers(persistConfig, {
			storeName: storeNameSlice.reducer,
			oidc: oidcSlice.reducer,
			dataProducts: dataProductsSlice.reducer,
			userItems: userItemsSlice.reducer,
			bookmarkedItems: userBookmarkedSlice.reducer,
			msg: msgSlice.reducer
		}),
		middleware,
		preloadedState
	}),
}

export const mainStorePersistor = persistStore(mainStore)

initCrossTabSync(mainStore, storeName, persistConfig)

mainStore.initStore = () => {
	subscribeOnStoreBootstrapped(mainStorePersistor, () => loadUserFromReduxStoreToUserManager(mainStore, webClientUserManager) )
	pscoutApi.setAccessTokenProvider(() => selectOidcAccessTokenFromStoreState(mainStore.getState()))
}