/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { SN } from '../components/ServiceName'
import { Tbl, TblHead, TblBody, TblRow } from '../components/DocTable'
import { Helmet } from "react-helmet";

export default function Introduction() {
	return (<>
		<Helmet>
			<title>home / doc / introduction</title>
		</Helmet>
		<h1>Introduction</h1>

		<p><SN /> supports you in finding, accessing and managing scientific data for remote sensing and in-situ sensors
			in a convenient way across a number of source platforms.
			<SN /> is indexing remote-sensing data from open platforms and provides convenient access. In-situ data is stored
			within the platform. Some datasets are harvested automatically by the service. Additional in-situ datasets can be
			store by registered users by themselves. Self stored data is private and only available for search and retrieval by
			the owner but still can be searched for and viewed together with publicly available data sets.  
		</p>

		<p>The amount of data that can be found through this service is constantly growing by harvesting
			various data sources and new data sources are added regularly.</p>

		<p><SN /> is collecting information on free and open data sources and manages the provision of
			the data via the web service and its API largely without the need to provide any additional 3rd
			party credentials.</p>

		<p>Key functions of the <SN /> service are</p>
		<ul>
			<li>Search via Web and via an API</li>
			<li>Storage of self provided in-situ data</li>
			<li>Store searches for later and repeated use</li>
			<li>Download via the Web interface or via an API</li>
		</ul>

		<h2><SN /> Features</h2>

		<p><SN /> has an ever growing set of features. Some features are restricted to specific user classes as
			listed in the following table:</p>

		<Tbl caption="Main features">
			<TblHead labels={["Function", "Anonymous user", "Registered", "Basic", "Premium"]} />
			<TblBody>
				<TblRow cols={["Access to product ressource pages", "x", "x", "x", "x"]} />
				<TblRow cols={["Searching in EO missions data sets", " ", "x", "x", "x"]} />
				<TblRow cols={["Access to Copernicus data (S1,S2,S3,S5P)", " ", "x", "x", "x"]} />
				<TblRow cols={["Searching in IoT data sets", " ", "x", "x", "x"]} />
				<TblRow cols={["Access and download available IoT data (AIS, ADS-B, ...)", " ", "", "", "x"]} />
				<TblRow cols={["Search using API                  ", " ", "x", "x", "x"]} />
				<TblRow cols={["Store and load queries            ", " ", "x", "x", "x"]} />
				<TblRow cols={["Download free and open data       ", " ", "x", "x", "x"]} />
				<TblRow cols={["Download Petabite basic data products", " ", " ", "", "x"]} />
				<TblRow cols={["Store own in situ data", " ", " ", "1 GB", "100 GB"]} />
				<TblRow cols={["Hosted processing with own algorithm ", " ", "", "option", "option"]} />
				<TblRow cols={["Use of preset algorithms for processing ", " ", "", "option", "option"]} />
				<TblRow cols={["Processing results store (0.1 TB)", " ", "", "option", "option"]} />
			</TblBody>
		</Tbl>

		<h2><SN /> pricing</h2>

		<p>Please note: Paid options are only available to commercial customers.</p>

		<Tbl caption="Price information" >
			<TblHead labels={["Feature", "Conditions", "Price"]} />
			<TblBody>
				<TblRow cols={["Basic account", "As per feature table and limited IoT data storage (1GB)", "€ 5.00 / month"]} />
				<TblRow cols={["Premium account", "As per feature table and 100 GB IoT data storage", "€ 15.00 / month"]} />
				<TblRow cols={["Processing 'advantage'", "Requirement: Basic account or higher. Processing with up to 4 vCores and 12 GB RAM, 50 GBytes of processing disk space. Best effort production on shared resources", "€ 40.00 / month"]} />
				<TblRow cols={["Processing 'exclusive'", "Requirement: Basic account or higher. Dedicated own processing resources 8 vCore 30 GB RAM, 200 GBytes of processing disk space..", "€ 200.00 / month"]} />
				<TblRow cols={["Own data storage", "The price is computed based on the maximum storage space used within a month. The first 100 GByte of storage space are included.", "€ 0.02 /GB/month"]} />
				<TblRow cols={["Service usage tutorial package", "A one-to-one introductory tutorial to get you started with the use of our APIs and hosted processing service.", "€ 1200.00"]} />
				<TblRow cols={["Algorithm integration support package", "Support for the integration of an algorithm with a single step production.", "€ 2400.00"]} />
			</TblBody>

		</Tbl>


	</>)
}

