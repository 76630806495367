/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React, { useState, useEffect } from 'react';
import { pscoutApi } from '../../state/pscoutApi';
import { LoadingSpinner } from '../common/LoadingSpinner/LoadingSpinner'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import { accountManagement } from "../../state/session"
import { OnlyDebuggingUI } from '../security/OnlyDebuggingUI'


function UserProfileInfoEntry({ category, value }) {
	return <tr><td>{category}</td><td>{value}</td></tr>
}

function UserProfileInfo({ userProfile }) {
	return (<Container>
	
		<h2 className="mt-2">User Profile Information</h2>
		<h3>for {userProfile.username}</h3>
		<p></p>
		<Table bordered={true} caption="User Profile Information" className="table-striped" hover>
			<thead>
				<tr><th scope="col">Information</th><th scope="col">Value</th></tr>
			</thead>
			<tbody>
				<UserProfileInfoEntry category="Username" value={userProfile.username} />
				<UserProfileInfoEntry category="Full name" value={userProfile.fullName} />
				<UserProfileInfoEntry category="Email" value={userProfile.email} />
				<UserProfileInfoEntry category="Email has been verified" value={userProfile.emailIsVerified ? "yes" : "no"} />
			</tbody>
		</Table>

		<p>
			<Button onClick={accountManagement} >Edit Profile ...</Button>
		</p>

	</Container>
	)

}

export function UserProfileStatus({ userProfile }) {
	const [profileFromApi, setProfileFromApi] = useState(null);
	const [principalFromApi, setPrincipalFromApi] = useState(null);
	const [jwtFromApi, setJwtFromApi] = useState(null);

	useEffect(() => {
		if (!profileFromApi && userProfile.isLoggedIn) {
			pscoutApi.getUserProfile().then(profile => {
				//console.log(profile);
				setProfileFromApi(profile);
			});
			pscoutApi.getUserPrincipal().then(principal => {
				//console.log(principal);
				setPrincipalFromApi(principal);
			});
			pscoutApi.getUserJwt().then(jwt => {
				//console.log(jwt);
				setJwtFromApi(jwt);
			});
		}
	});

	return (<>
		<LoadingSpinner loading={userProfile.isLoadingInProgress}>
			<UserProfileInfo userProfile={userProfile} />
			<OnlyDebuggingUI userProfile={userProfile}>
				<Container className="bg-white mt-4 text-break">
					<ul>
						<li>Id: {userProfile.id}</li>
						<li>Username: {userProfile.username}</li>
						<li>Full name: {userProfile.fullName}</li>
						<li>Family name: {userProfile.familyName}</li>
						<li>Given name: {userProfile.givenName}</li>
						<li>Email: {userProfile.email}</li>
						<li>email is verified: {userProfile.emailIsVerified ? "yes" : "no"}</li>
						<li>Email: {userProfile.email}</li>
						<li>Roles: <ul>
							{userProfile.roles.map(role => (
								<li key={role}>{role}</li>
							))}
						</ul></li>
						<li>Access scopes: <ul>
							{userProfile.accesScopes.map(scope => (
								<li key={scope}>{scope}</li>
							))}
						</ul></li>
						<li>profileFromApi: <pre>{JSON.stringify(profileFromApi, null, 2)}</pre></li>
						<li>principalFromApi: <pre>{JSON.stringify(principalFromApi, null, 2)}</pre></li>
						<li>jwtFromApi: <pre>{JSON.stringify(jwtFromApi, null, 2)}</pre></li>
					</ul>
				</Container>
			</OnlyDebuggingUI>
			
		</LoadingSpinner>
	</>
	)
}