/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { createSlice } from '@reduxjs/toolkit'

export const storeNameSlice = createSlice({
	name: 'storeName',
	initialState: "unnamed store",
	reducers: {
		setStoreName: (state, action) => {
			state = action.payload
		}
	}
})

export const { setStoreName } = storeNameSlice.actions
export const selectStoreName = state => state.storeName