/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */


export const iconSize = { width: '1.2em', height: '1.2em' }

/**
 * The name of the service.
 */
export const serviceName = "IEOTO";

/**
 * The support e-mail address
 */

export const SUPPORT_EMAIL = "support@petabite.space"

export const DOCUMENTATION_URL = "https://gitlab.com/petabite.eu/documentation"

export const TRACEABILITY_SERVICE_QUERY_URL_PREFIX = "https://trace.dataspace.copernicus.eu/traces?q="