/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

//import { Fig } from '../components/Figure'
import { SN } from '../components/ServiceName'

//import WebMainFigure from './web-mainscreen.png'
import { Helmet } from "react-helmet";

export default function Web() {
	return (<>
		<Helmet>
			<title>home / doc / functionality</title>
			 <meta name="description" content="The home service functionalities (search and download) as well as profile management can be accessed through the web interface." />
		</Helmet>
		<h1>Web service functionality</h1>

		<p>The <SN /> web service can be reached at <a href="https://ieo.to">https://ieo.to</a>. 
		While some functionality of the service is available to an unregistered user, there is more useful functionality for a registered user
		(See also <a href="/page/doc/introduction">Introduction</a>).
		This user manual is describing the functionality the way it is available to a registered user and may contain sections that are only available 
		to users with paid accounts.</p>

		<h2>Registration process</h2>

		<p>To register select "Register" in the top right corner of the screen. The registration process requires you to
		supply a valid e-mail address. The e-mail address is needed to ensure that you can control your account and recover it,
		e.g. in case of password loss. Please have a look at our <a href="/page/doc/legal">privacy policy</a> before you register.</p>

		<h2>Overview</h2>

		<p>The main application window consists of a navigation bar and </p>
		<ul>
			<li>a search component to filter for data products</li>
			<li>a data list area, showing the results of the search</li>
			<li>a map area to show the location of the data products</li>
		</ul>

		{/* <Fig src={WebMainFigure} label="Application main screen" />*/}

		<p>The location of the application components on the screen depends on the size of the browser window and the  device you are working on.
		The application layout tries to maximize the use of the available screen space in a responsive way,
        without compromising the readability of the content. </p>

		<p>When all components are next to each other, the service behaves similar to an application with a fixed screen.
		When there is not enough room for adjacent placement, the service behaves more like a page, that can be scrolled.
		The components are either positioned next or on top of each other and it should be possible to use
        the service in a convenient way on a computer and on a tablet or mobile phone. </p>

		<p>A few screen components also behave differently depending on whether they are viewed on a computer
		or a mobile device. </p>
	</>)
}
