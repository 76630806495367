/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from "react"

import { LayersControl, TileLayer } from "react-leaflet";
import { OSM_ATTRIBUTION, OSM_URL, OSM_LABEL} from './Projections'

export const LAYER_OSM = 'osmeox'
export const LAYER_BKG = 'bkg'

/**
 * Map base layers and ui control.
 * For EOX maps, check: https://tiles.maps.eox.at/wmts/1.0.0/WMTSCapabilities.xml
 */
export function MapBaseLayers({ selected = LAYER_BKG, children }) {

	return <LayersControl position="topright">
		<LayersControl.BaseLayer checked={selected === LAYER_OSM ? true : false} name={OSM_LABEL}>
			<TileLayer
				url={OSM_URL}
				attribution={OSM_ATTRIBUTION}
			/>
		</LayersControl.BaseLayer>
		<LayersControl.BaseLayer checked={selected === LAYER_BKG ? true : false} name="TopPlus">
			<TileLayer
				url="https://sgx.geodatenzentrum.de/wmts_topplus_open/tile/1.0.0/web/default/WEBMERCATOR/{z}/{y}/{x}.png"
				attribution='&copy; Bundesamt für Kartographie und Geodäsie (2021), Data sources: http://sg.geodatenzentrum.de/web_public/Datenquellen_TopPlus_Open.pdf '
			/>
		</LayersControl.BaseLayer>
		{children}
	</LayersControl>

}

