/**
 * Handles Bookmarking logic
 */
export class  BookmarkCtrl {

    readonly isBookmarked:boolean
    readonly isBookmarklistFull:boolean

    constructor(isBookmarked:boolean, isBookmarklistFull:boolean) {
        this.isBookmarked = isBookmarked
        this.isBookmarklistFull = isBookmarklistFull
    }

    /**
     * An item can be bookmarked, when the item list is not full and it is not yet bookmarked.
     * @returns whether an item can be bookmarked
     */
    canBeBookmarked() {

        return !this.isBookmarked && !this.isBookmarklistFull
    }
}