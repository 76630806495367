/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react';
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';

const collectionToOption = (collection) => {
	return { value: collection, label: collection };
}

const groupToOption = (group) => {
	return { label: group.label, options: group.collections.map(collectionToOption) };
}


const optionToProductTypeCode = (option) => {
	return option.value;
}

export function ProductTypesSelection({ productTypesInfo, selectedProductTypes, onChange, placeholder="Any EO product type" }) {
	const options = productTypesInfo.groups.map(groupToOption)
	const selectedOptions = selectedProductTypes.map(collectionToOption)

	return (
		<div className="col">
			<Select isMulti isClearable options={options} placeholder={placeholder} value={selectedOptions} onChange={(value) => onChange(value.map(optionToProductTypeCode))} />
		</div>
	);
}


export function InsituNetworkSelection({ productTypesInfo, selectedProductTypes, onChange, placeholder="Insitu type" }) {
	const options = productTypesInfo.groups.map(groupToOption)
	const selectedOptions = selectedProductTypes.map(collectionToOption)

	return (
		<div className="col">
			<CreatableSelect isMulti isClearable options={options} placeholder={placeholder} value={selectedOptions} onChange={(value) => onChange(value.map(optionToProductTypeCode))} />
		</div>
	);
}

