import React from "react";
import { Col } from "react-bootstrap";
import moment from "moment";

import type { RelTimeDiff } from "./SensingTimeInputFilter";

type RelTimeInputProps = {
    relTime: RelTimeDiff
    relIntervalStartLimit: RelTimeDiff | undefined
    relIntervalEndLimit: RelTimeDiff | undefined
    valueChanged: (val: RelTimeDiff) => void
};

// temporarily implemented twice to have a typescript version, see store.dataProducts.js
export function isTimelimitSet(timeLimit: RelTimeDiff | undefined) {
    if (timeLimit) {
        if (timeLimit.days + timeLimit.hours !== 0) {
            return true
        }
    }
    return false
}

// exported to preven build warning
export function MinMaxShow({ minDay, maxDay, minHour, maxHour, days, hours }: any) {
    const theDate=moment.utc(new Date())
    theDate.add(days,'days')
    theDate.add(hours,'hours')
    return (<>
        
        { 
        /**
         * [<span>{minDay}</span> - <span>{maxDay}</span>] [<span>{minHour}</span> - <span>{maxHour}</span> ] 
         */
        
        days + hours !== 0 && theDate.format("YYYY-MM-DD HH:mm:ss") }</>)
}


export function RelTimeInput({ relTime, relIntervalStartLimit, relIntervalEndLimit, valueChanged }: RelTimeInputProps) {
    var maxHourLimit = 0
    var maxDayLimit = 0
    var minHourLimit = -23
    var minDayLimit = -10000

    if (isTimelimitSet(relIntervalStartLimit)) {
        minDayLimit = relIntervalStartLimit?.days as number
        if (relTime.days === minDayLimit) {
            minHourLimit = relIntervalStartLimit?.hours as number
        }
        if (relTime.hours > (relIntervalStartLimit?.hours as number)) {
            minDayLimit = minDayLimit + 1
        }
    }

    if (isTimelimitSet(relIntervalEndLimit)) {
        maxDayLimit = relIntervalEndLimit?.days as number
        if (relTime.days === maxDayLimit) {
            maxHourLimit = relIntervalEndLimit?.hours as number
        }
        if (relTime.hours < (relIntervalEndLimit?.hours as number)) {
            maxDayLimit = maxDayLimit - 1
        }
    }

    const setFun = (newValue:RelTimeDiff) => {
        var days = newValue.days
        var hours = newValue.hours
        if (days > maxDayLimit) {
            days = maxDayLimit
        }
        if (days < minDayLimit) {
            days = minDayLimit
        }
        if (hours < minHourLimit) {
            hours = minHourLimit
        }
        if (hours > maxHourLimit) {
            hours = maxHourLimit
        }
        valueChanged({ days: days, hours: hours })
    } 

    return (<>
        <Col className="col-auto"><input type='number' size={3} min={-10000} max={0} step={1} value={relTime.days}
            onChange={(e) => setFun({ days: e.target.valueAsNumber, hours: relTime.hours })} /> days </Col>
        <Col><input type='number' size={2} min={-23} max={0} step={1} value={relTime.hours}
            onChange={(e) => setFun({ days: relTime.days, hours: e.target.valueAsNumber })} /> hours</Col>
        {
            
            <Col className="col">
                <MinMaxShow minDay={minDayLimit} maxDay={maxDayLimit} minHour={minHourLimit} maxHour={maxHourLimit} 
                    days={relTime.days}
                    hours={relTime.hours} />
            </Col>
            
        }
    </>)

}