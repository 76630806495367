import { useState } from "react"
import { Form } from "react-bootstrap"
import { FC_CLASSES } from "./NetworkConfiguration"

export interface ChannelNameEditorProps {
	channelName: string|undefined
	onChange: (newName: string) => void
	disabled: boolean
}

// handle name differently, because it is also the key
export function ChannelNameEditor({ channelName, onChange, disabled = true }: ChannelNameEditorProps) {
	const [editName, setEditName] = useState(channelName??"")

	return < Form.Control className={disabled ? "" : FC_CLASSES} type="text" value={editName} onChange={(e) => {
		setEditName(e.target.value)
	}

	} onBlur={(e) => {
		if (channelName !== editName) {
			onChange(editName)
		}
	}} onKeyUp={(event) => {
		if (event.key === "Enter") {
			if (channelName !== editName) {
				onChange(editName)
			}
		}
	}} disabled={disabled} />

}