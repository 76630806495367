/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import Row from 'react-bootstrap/Row'

export default function SectionTopRow({ children }) {
	return (
		<Row className="justify-content-end my-1 mb-4 px-1 section-top">
			{children}
		</Row>
	)
}