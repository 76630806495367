/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React, { useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { useParams } from "react-router-dom";
import { LoadingSpinner } from '../components/common/LoadingSpinner/LoadingSpinner'
import { DataProductDetails } from '../components/data/DataProduct/DataProductDetails'
import { unconnectedLoadProduct } from '../state/store.dataProducts'
import {selectOidcTokensFromStoreState} from '../state/store.oidc'

export function DataProductDetailsPage({ productName, access_token}) {
	const dispatch = useDispatch()
	const [myProduct, setMyProduct] = useState(null)
	useEffect(() => {
		unconnectedLoadProduct(dispatch, productName,setMyProduct)
	}, [productName, dispatch])
	//console.log(myProduct)
	return <LoadingSpinner loading={typeof myProduct == "undefined"} >
		{myProduct && <DataProductDetails product={myProduct} access_token={access_token} />	}
	</LoadingSpinner>
}

export function ConnectedDataProductDetailsPage() {
	const { productName } = useParams();
	const { access_token } = useSelector(selectOidcTokensFromStoreState, shallowEqual)
	return <DataProductDetailsPage productName={productName} access_token={access_token}/>
}