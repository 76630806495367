/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from "react";
import { CallbackComponent } from "redux-oidc";
import { useNavigate } from "react-router-dom";

export function ConnectedOidcCallbackPage({userManager, targetURL}) {
	const navigate = useNavigate();
	// just redirect to 'targetURL' in both cases
	return (
		<CallbackComponent
			userManager={userManager}
			successCallback={() => navigate(targetURL)}
			errorCallback={error => {
				navigate(targetURL);
				console.error(error);
			}}
		>
			<div>Redirecting...</div>
		</CallbackComponent>
	);
}
