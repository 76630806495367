/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react'
import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistCombineReducers, createMigrate } from 'reduxjs-toolkit-persist'
import storage from 'reduxjs-toolkit-persist/lib/storage'
import autoMergeLevel1 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1'

import { oidcSlice, ignoreUserExpiredMiddleware } from './store.oidc'
import { storeNameSlice } from './store.storeName'
import { defaultMiddlewares } from './store-utils';

const storeName = 'apiClientStore'

const migrations = {
  1: (state) => {
    console.debug(`migrating store ${state.storeName} from V${state._persist.version} to V1`)
    return {
      storeName: state.storeName,
      oidc: state.oidc
    }
  }
}

const persistConfig = {
	key: storeName,
	storage: storage,
	stateReconciler: autoMergeLevel1,
	throttle: 10, //max one write per x ms
	version: 1, //increment whenever store needs migration 
	blacklist: null,
	whitelist: [ // list of slice-names to be peristed between browser-sessions and synced between tabs
		'storeName',
		//'oidc'
	],
	migrate: createMigrate(migrations, { debug: false }),
}


export const apiClientStore = {
	...configureStore({
		reducer: persistCombineReducers(persistConfig, {
			storeName: storeNameSlice.reducer,
			oidc: oidcSlice.reducer,
		}),
		middleware: defaultMiddlewares.concat(ignoreUserExpiredMiddleware),
		preloadedState: {
			storeName: storeName
		}
	}),
}


export const apiClientStorePersistor = persistStore(apiClientStore)

apiClientStore.initStore = () => {}

export const ApiClientStoreContext = React.createContext();
