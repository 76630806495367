/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import './AccessTokenPage.css';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux'
import { environmentConfig } from '../../state/environmentConfigLoader';
import { selectOidcTokensFromStoreState, selectOidcUserFromStoreState } from "../../state/store.oidc";
import { ConnectedOnlyDebuggingUI } from '../../components/security/OnlyDebuggingUI'

export function AccessTokenPage({ access_token, refresh_token, apiUrl, oidcUser }) {
	const oidcUserJson = JSON.stringify(oidcUser, null, 2);
	
	const thingsTestData = {
		id:"eui-a84041ce11849a71",
		timestamp: "2022-09-22T13:46:53.272219649Z",
		battery_abs: 3.641,
		humidity_rel: 54.1,
		temperature: 23, 
		door_status: "open",
		rx_rssi: -87,
		rx_snr: 11.5,
		rx_gateway_id: "eui-a84041ffff22adb0",
		frame_counter: 47
	}
	
	console.debug("oidcUser", oidcUser)
	return (<>
				<h2>OidcUser</h2>
				<pre>
					{oidcUserJson}
				</pre>
				
				<h2>Tokens</h2>
				<ul>
					<li><RefreshToken refresh_token={refresh_token} /></li>
					<li><CurlGetStatement access_token={access_token} url={apiUrl("/user/profile")}/></li>
					<li><CurlPostStatement access_token={access_token} url={apiUrl("/things/"+oidcUser.profile.sub+"/{network}/observation/{thingId}")} data={thingsTestData}/></li>
				</ul>
			</>
	)
}

export function RefreshToken({ refresh_token }) {
	return (
		<code className="curlStatement">
			refresh_token: {refresh_token}
		</code>
	)
}

export function CurlGetStatement({ access_token, url }) {
	return (
		<code className="curlStatement">
			curl -H 'Authorization: Bearer {access_token}' '{url}' | jq
		</code>
	)
}

export function CurlPostStatement({ access_token, url, data }) {
	return (
		<code className="curlStatement">
			curl -H 'Authorization: Bearer {access_token}' -H 'Content-Type: application/json' '{url}' -d '{JSON.stringify(data)}'
		</code>
	)
}


export function ConnectedAccessTokenPage() {
	const { access_token, refresh_token } = useSelector(selectOidcTokensFromStoreState, shallowEqual)
	const oidcUser = useSelector(selectOidcUserFromStoreState, shallowEqual)
	
	const apiUrl = (path) => environmentConfig.api.url + path
	return <>
		<ConnectedOnlyDebuggingUI>
			<AccessTokenPage access_token={access_token} refresh_token={refresh_token} apiUrl={apiUrl} oidcUser={oidcUser} />
		</ConnectedOnlyDebuggingUI>
	</>
}
