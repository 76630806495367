/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { selectUserProfileFromStoreState } from "../../state/store.oidc";
import { login } from "../../state/session"
import { UserProfile } from '../../state/storeTypes';

export function OnlyLoggedIn({ userProfile, children, loginHint }:{ userProfile:UserProfile, children:React.ReactElement | React.ReactElement[], loginHint:boolean|undefined }) {
	if (userProfile.isLoggedIn) {
		return <>{children}</>
	} else {
		if (loginHint) {
			return <div className="welcome-page container-fluid h-100">
				<div>&nbsp;</div>
				<div className="p-5 border container-lg welcome-page-content">
					<p>You need to be logged in to perform this function.</p>
					<p>
						Please <Button variant="primary" onClick={login}>Login</Button> to continue.
					</p>
				</div>
			</div>

		} else {
			return null;
		}
	}
}

export const ConnectedOnlyLoggedIn = connect(selectUserProfileFromStoreState)(OnlyLoggedIn)