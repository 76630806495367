/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react';
import Image from 'react-bootstrap/Image'
import thumbnailPlaceholderUrl from './Sentinel-1A_mosaic_of_Europe_thumbnail.jpg'
import quicklookPlaceholderUrl from './Sentinel-1A_mosaic_of_Europe_quicklook.jpg'
import { LoadingSpinner } from '../../common/LoadingSpinner/LoadingSpinner'

function onThumbnailLoadError(e) {
	e.target.src=thumbnailPlaceholderUrl;
}
function onQuicklookLoadError(e) {
	e.target.src=quicklookPlaceholderUrl;
}

export function DataProductQuicklook({ thumbnailImage, quicklookImage, thumbnail = false }) {
	let srcUrl
	if (thumbnail) {
		if (!thumbnailImage.missing) {
			srcUrl = thumbnailImage.imageUrl
		} else if (!quicklookImage.missing) {
			srcUrl = quicklookImage.imageUrl
		} else {
			srcUrl = thumbnailPlaceholderUrl
		}
		var style = {}
		if (thumbnailImage.notFound === undefined) {
			style = { "filter": "grayscale(100%) contrast(20%)" }
		}
		return <div style={{ "height": "60px", "overflow": "hidden" }}>
			<Image src={srcUrl} style={style} alt='Thumbnail' rounded fluid className="border border-secondary" onError={onThumbnailLoadError} />
		</div>
	} else {
		//console.debug("DataProductQuicklook-quicklookImage:", quicklookImage)
		if (!quicklookImage.missing) {
			srcUrl = quicklookImage.imageUrl
		} else if (!thumbnailImage.missing) {
			srcUrl = thumbnailImage.imageUrl
		} else {
			srcUrl = quicklookPlaceholderUrl
		}
		return <LoadingSpinner loading={quicklookImage.loading} >
			<div style={{ "maxHeight": "500px", "overflow": "auto" }}>
				<Image src={srcUrl} alt='Quicklook' rounded fluid  onError={onQuicklookLoadError} />
			</div>
		</LoadingSpinner>
	}
}
