import { Table } from "react-bootstrap";
import { ManagedThing, ManagedThingPage } from "../../utils/types";
import { useEffect, useState } from "react";
import { pscoutApi } from "../../state/pscoutApi";

interface ManagedThingConfigEntryProps {
    thingConfig: ManagedThing
}

function ManagedThingConfigEntry({ thingConfig }: ManagedThingConfigEntryProps) {
    return <tr>
        <td>
            {thingConfig.thingId}
        </td>
        <td>
            {thingConfig.thingName}
        </td>
        <td>
            {thingConfig.thingLabel}
        </td>
        <td>
            {"(" + thingConfig.positionLatitude + " " + thingConfig.positionLongitude + ")"}
        </td>

    </tr>
}

interface ManagedSensorPropsType {
    owner: string | undefined,
    networkName: string | undefined
}

interface ManagedSensorsViewPropsType {
    managedThingPage: ManagedThingPage
}

export function ManagedSensorsView({ managedThingPage }: ManagedSensorsViewPropsType) {
    return <>
        <h1>Managed Sensors</h1>
        {managedThingPage && <><Table bordered className="table-responsive" size="xl" style={{ width: "auto" }}>
            <thead>
                <tr><th style={{ maxWidth: "12rem" }}>ID</th><th>Name</th><th>Label</th><th>Position</th></tr>
            </thead>
            <tbody>
                {managedThingPage.items?.map((thingConfig => <ManagedThingConfigEntry thingConfig={thingConfig} key={thingConfig.thingId} />))}
            </tbody>
        </Table>
            {(managedThingPage.totalPages && managedThingPage.totalPages > 1) && <div>There are more things in this network. Use the API to access/get information.</div>}
        </>}
    </>

}

export function ManagedSensors({ owner, networkName }: ManagedSensorPropsType) {
    const [managedThingPage, setManagedThingPage] = useState(null as unknown as ManagedThingPage)

    useEffect(() => {
        const getInfo = async () => {
            pscoutApi.getManagedThingsInfo(owner, networkName).then(managedThingPage => {
                setManagedThingPage(managedThingPage)
            }
            ).catch(error => {
                console.log(error)
            })

        }
        getInfo()

    }, [owner, networkName])

    if (!owner || !networkName) {
        return <div>Network not well defined!</div>
    } else {
        return <ManagedSensorsView managedThingPage={managedThingPage} />
    }
}