/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import Figure from 'react-bootstrap/Figure'
import Row from 'react-bootstrap/Row'


export function Fig({ src, label, description, width = "600px" }) {
	return <Row className="w-100 doc-figure">
	
	<Figure className="flex-column border col-auto">
	
		<Figure.Image src={src} alt={description} width={width} />
		
		<Figure.Caption>
		{label}
		</Figure.Caption>

	</Figure>
	</Row>
}