/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { createUserManager } from 'redux-oidc';
import { WebStorageStateStore } from 'oidc-client'
import { environmentConfig } from './environmentConfigLoader';

const webStorageStateStore = new WebStorageStateStore({ prefix: "webClientOidc.", store: sessionStorage })
const webStorageUserStore = new WebStorageStateStore({ prefix: "webClientOidc.", store: sessionStorage })

// see https://github.com/IdentityModel/oidc-client-js/wiki#configuration
const webClientUserManagerConfig = {
	client_id: environmentConfig.oidc.webClientId,
	redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/page/oidcWebClientCallback`,
	post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/page/oidcWebClientCallback`,
	response_type: 'code',
	scope: 'openid offline_access',
	authority: environmentConfig.oidc.authority,
	automaticSilentRenew: true,
	filterProtocolClaims: true,
	loadUserInfo: true,
	monitorSession: false,
	revokeAccessTokenOnSignout: true,
	accessTokenExpiringNotificationTime: Math.floor((Math.random() * 30)) + 45, //refresh access token between 45 and 75 seconds before it expires
	stateStore: webStorageStateStore,
	userStore: webStorageUserStore,
};

export const webClientUserManager = createUserManager(webClientUserManagerConfig);

export const getAccountManagementUrl = () => {
	return webClientUserManagerConfig.authority + "/account";
}
export const getRegistrationUrl = () => {
	if(environmentConfig.allAccountsAreBetatester) {
		return 'https://petabite.atlassian.net/servicedesk/customer/portal/1/group/1/create/18'
	} else {
		return `${webClientUserManagerConfig.authority}/protocol/openid-connect/registrations?`
			+ `client_id=${webClientUserManagerConfig.client_id}`
			+ `&response_type=${webClientUserManagerConfig.response_type}`
			+ `&scope=${webClientUserManagerConfig.scope}`
			+ `&redirect_uri=${webClientUserManagerConfig.redirect_uri}`;
	}
}
