/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react';

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	PointElement,
	LineElement,
	LineController,
	BarController,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	PointElement,
	LineElement,
	LineController,
	BarController
);



export function DataProductThumbnailInSitu({ product }) {

	try {
		const data = {
			labels: product.displayChannel.samples.map((s) => s.time),
			datasets: [
				{
					type: 'line',
					label: product.displayChannel.channelName + "[" + product.displayChannel.channelUnit + "]",
					data: product.displayChannel.samples.map((s) => s.avg),
					borderColor: 'black',
					borderWidth: 1,
					pointStyle: false
				},
			],
		}
		return <div title={product.displayChannel.channelName} className='bg-secondary' style={{ "height": "50px", "maxHeight": "50px" }}>

			<Line data={data} options={{
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						enabled:false
					}
				},
				scales: {
					x: {
						display: false
					},
					y: {
						display: false
					}
				},
				maintainAspectRatio: false
			}} />

		</div>

	} catch (e) {
		console.log(e)
		return <div>Error</div>
	}
}

