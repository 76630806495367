/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useState, useEffect } from "react"
import { LayersControl, MapContainer, GeoJSON, Popup, LayerGroup, TileLayer, Pane, Rectangle, useMap } from "react-leaflet";
import toBBox from 'geojson-bounding-box'
import { productNameIcon } from '../../data/DataProduct/DataProductAttributeIcons';
import { MercatorProjection, ArcticProjection, AntarcticProjection, allZoomIcon, selectionZoomIcon } from '../Icons/Icons';
import { IconButton } from '../Icons/IconButton'
import MiddleEllipsis from 'react-middle-ellipsis';
import { MERCATOR_PROJ, ARCTIC_PROJ, ANTARCTIC_PROJ, projections } from './Projections'
import { GestureHandling } from "leaflet-gesture-handling";
import SectionTopRow from '../../common/SectionTopRow'
import { pscoutApi } from '../../../state/pscoutApi'
import { toLeafletLatLngBounds } from '../../../utils/leafletUtils'
import Form from 'react-bootstrap/Form'
import { dataProductsSelected } from '../../../state/store.dataProducts'
import { useDispatch } from 'react-redux'
import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import L from 'leaflet';

L.Icon.Default.prototype.options.iconUrl = '/marker-icon.svg'
L.Icon.Default.prototype.options.iconRetinaUrl = '/marker-icon.svg'
L.Icon.Default.prototype.options.shadowUrl = '/shadow-icon.svg'

function lessThan180(array) {
	return (array.filter((x) => Math.abs(x) >= 180)).length === 0
}

function maxExtent(array) {
	var min0 = array[0][0]
	var max0 = array[0][0]
	var min1 = array[0][1]
	var max1 = array[0][1]

	for (var n = 1; n < array.length; n++) {
		min0 = Math.min(min0, array[n][0])
		max0 = Math.max(max0, array[n][0])
		min1 = Math.min(min1, array[n][1])
		max1 = Math.max(max1, array[n][1])
	}
	return (Math.max(max0 - min0, max1 - min1))
}

export function toPresentationGeometry(polygon) {
	var rval = polygon
	if (polygon.coordinates[0]?.length > 0 && polygon.coordinates[0]?.length < 6 &&
		polygon.coordinates[0][0].length === 2 && lessThan180(polygon.coordinates[0][0]) && (maxExtent(polygon.coordinates[0]) < 0.001)) {
		rval = { type: "Point", coordinates: polygon.coordinates[0][0], properties: {} }
	}
	return rval
}

/**
 * Visualizes a single product in a map.
 */
function ProductInMap({ product, pathClass, selected, selectable }) {
	const dispatch = useDispatch()

	const featureData = {
		"type": "Feature",
		"properties": {},
		"geometry": toPresentationGeometry(product.spatialCoverage)
	}

	const onSelectionSwitchChange = (event) => {
		dispatch(dataProductsSelected({ affectedProductIds: [product.id], isSelected: event.target.checked }))
	}

	return <GeoJSON className={pathClass} data={featureData} key={product.id}
		pointToLayer={(feature, latlng) => {
			return L.marker(latlng, { 
				opacity:selected?1.0:0.5});
		}} >

		<Popup >
			<h4>Product</h4>

			<a href={pscoutApi.toDataProductViewUrl(product)} target="_blank" rel="noreferrer">
				{productNameIcon}  <div style={{ width: "300px" }}><MiddleEllipsis><span className="ellipseMe">{product.productName}</span></MiddleEllipsis></div>

			</a>
			{selectable && <Form.Check
				type="switch"
				id={`pop-switch-${product.id}`}
				checked={selected}
				onChange={onSelectionSwitchChange}

			/>
			}

		</Popup>

	</GeoJSON>
}


/**
 * A layer of footprints.
 */

function Footprints({ products, selectedProductIds, selectable }) {
	return <LayersControl.Overlay checked name="Footprints">
		<LayerGroup>
			{
				products.map((product) => {
					return <ProductInMap pathClass="product-footprint" product={product} key={product.id} selected={selectedProductIds.includes(product.id)} selectable={selectable} />
				})
			}
		</LayerGroup>
	</LayersControl.Overlay>
}

function AoiLayer({ aoiBoundingBox }) {
	return <LayerGroup>
		<Rectangle pathOptions={{ "className": "aoibox" }} bounds={toLeafletLatLngBounds(aoiBoundingBox)} />
	</LayerGroup >
}

/**
 * A layer of footprints from selected products
 */

function Selected({ products, selectedProductIds, selectable }) {

	return <Pane name="overlay-selected" style={{ zIndex: 401 }}>
		<LayersControl.Overlay checked name="Selected">
			<LayerGroup>
				{
					products.filter((element) => selectedProductIds.includes(element.id)).map((value) => {
						return <ProductInMap pathClass="selected-footprint" product={value} key={value.id} selected={true} selectable={selectable} />
					})
				}
			</LayerGroup>
		</LayersControl.Overlay>
	</Pane>
}

/*function productsEqual(productsA, productsB) {
	if (productsA === productsB)
		return true
	if (productsA == null || productsB == null)
		return false
	if (productsA.length !== productsB.length)
		return false

	for (var i = 0; i < productsA.length; ++i) {
		if (productsA[i].id !== productsB[i].id)
			return false
	}
	return true;
}*/

function fitMapBoundsToProducts(map, products, aoiBoundingBox = null) {

	if (aoiBoundingBox && products?.length === 0) {
		map.fitBounds(toLeafletLatLngBounds(aoiBoundingBox, 0))
	} else if (products?.length > 0) {
		let boxes = products.map((p) => toBBox(p.spatialCoverage))
		let minBox = boxes[0]
		for (var bo of boxes) {
			minBox = [Math.min(minBox[0], bo[0]), Math.min(minBox[1], bo[1]), Math.max(minBox[2], bo[2]), Math.max(minBox[3], bo[3])]
		}
		map.fitBounds([
			[minBox[1], minBox[0]],
			[minBox[3], minBox[2]]
		]);
	}
}

function ProjectionButton({ label, setProjFun }) {
	return <IconButton onClick={setProjFun} icon={label} />
}

function RepositionButton({ reposFun }) {
	return <IconButton onClick={reposFun} icon={allZoomIcon("Refocus on product footprints.")} />
}

function ZoomSelectionButton({ reposFun }) {
	return <IconButton onClick={reposFun} icon={selectionZoomIcon("Focus on selected products footprints.")} />
}


function MapBaseLayers({ baseConfig, children }) {

	return <LayersControl position="topright">
		{
			baseConfig.layers.map((layerConf, index) => {
				return <LayersControl.BaseLayer checked={index === 0} name={layerConf.name} key={layerConf.name}>
					<TileLayer
						url={layerConf.url}
						attribution={layerConf.attribution}
						minZoom={1}
						maxZoom={layerConf.maxZoom}
						{... (baseConfig.tileSize && { tileSize: baseConfig.tileSize })}
					/>
				</LayersControl.BaseLayer>
			})
		}
		{children}
	</LayersControl>

}

function MapSetterComponent({ setMapFun }) {
	const map = useMap()
	setMapFun(map)
	return null
}

/**
 * Displays a map of product footprints.
 * Accepts zero or more products as input.
 */
export function MapView({ aoiBoundingBox, products, selectedProductIds = [], loading, selectable = true }) {

	const [map, setMap] = useState(null)
	const [projection, setProjection] = useState(projections.get(MERCATOR_PROJ))
	// to manage complete repainting of the mapcontainer, when the projection changes
	const [remountMap, setRemountMap] = useState(false)

	// causes asynchronous map redraw after redraw has been requested
	useEffect(() => {
		remountMap && setTimeout(() => setRemountMap(false), 500);
	}, [remountMap]);

	// Note: if products and aoiBoundingBox are part of the dependency
	// there are too many callst to fitMapBounds, not fully understood why
	useEffect(() => {
		if (!loading && map) {
			fitMapBoundsToProducts(map, products, aoiBoundingBox)
		}
	}, [loading, map]) // eslint-disable-line react-hooks/exhaustive-deps

	const explicitFitToProducts = () => {
		fitMapBoundsToProducts(map, products, aoiBoundingBox)
	}

	const explicitFitToSelectedProducts = () => {
		const selectedProducts = products.filter(p => selectedProductIds.includes(p.id))
		if (selectedProducts.length > 0) {
			fitMapBoundsToProducts(map, selectedProducts)
		}
	}

	function changeProjection(projectionName) {
		let newProjection = projections.get(projectionName)
		if (projection !== newProjection) {
			setProjection(newProjection)
			setRemountMap(true) // initiates complete redraw
		}
	}

	function setTheMap(map) {
		//console.log("setting the map")
		//console.log(map.getZoom())
		//setMap(map)
	}
	return <Container className="h-100">
		<SectionTopRow>
			<Col xs={12} className="ml-0 container">
				<Row className="pl-2">
					<Col className="col-auto px-1"><ProjectionButton label={<ArcticProjection />} setProjFun={() => changeProjection(ARCTIC_PROJ)} /></Col>
					<Col className="col-auto  px-1"><ProjectionButton label={<MercatorProjection />} setProjFun={() => changeProjection(MERCATOR_PROJ)} /></Col>
					<Col className="me-auto col-auto px-1"><ProjectionButton label={<AntarcticProjection />} setProjFun={() => changeProjection(ANTARCTIC_PROJ)} /></Col>
					{selectedProductIds.length > 0 && <Col className="col-auto  px-1"><ZoomSelectionButton reposFun={explicitFitToSelectedProducts} /></Col>}
					{products.length > 0 && <Col className="col-auto  px-1"><RepositionButton reposFun={explicitFitToProducts} /></Col>}
				</Row>
			</Col>
		</SectionTopRow>
		<Row className="p-0 h-100">
			<Col className="h-100">
				{!remountMap &&
					<MapContainer className='w-100 mapview'
						bounds={toLeafletLatLngBounds({ south: 53.12, west: 9.98, north: 53.36, east: 10.98 })}
						zoom={2} scrollWheelZoom={false}
						maxZoom={projection.maxZoom}
						minZoom={projection.minZoom}
						ref={setMap}
						whenReady={(arg) => {
							fitMapBoundsToProducts(arg.target, products, aoiBoundingBox);
							arg.target.gestureHandling.enable();
							arg.target.addHandler("gestureHandling", GestureHandling);
						}
						}
						{... (projection.crs && { crs: projection.crs })}  >
						<MapBaseLayers baseConfig={projection} >
							{aoiBoundingBox && <AoiLayer aoiBoundingBox={aoiBoundingBox} />}
							{selectedProductIds.length > 0 && <Selected products={products} selectedProductIds={selectedProductIds} selectable={selectable} />}
							<Footprints selectable={selectable} products={products} selectedProductIds={selectedProductIds} />
						</MapBaseLayers>
						<MapSetterComponent setMapFun={setTheMap} />
					</MapContainer>
				}
			</Col>
		</Row>
	</Container>

}


