/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */


import React from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

/* eslint-disable import/no-webpack-loader-syntax */
import Legal from '../doc/90-legal/legal'
import { PetabiteLogoImageHeader } from '../doc/components/PetabiteLogoImageHeader';


export function PrivacyPolicyDialog({ onClose, show = true }) {
	return (
		<Modal show={show} size="xl" onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>Privacy Policy</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			<PetabiteLogoImageHeader />
				<Legal />
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={onClose}>Close</Button>
			</Modal.Footer>
		</Modal>
	);
}
