import React from "react";
import { Helmet } from "react-helmet";
import { Fig } from "../components/Figure";
import { Tbl, TblHead, TblBody, TblCell, TblRow } from '../components/DocTable'

const StoredSearchesImg = require('./stored-searches.png')

export default function StoredSearches() {
	return (<>
		<Helmet>
			<title>home / doc / stored searches</title>
		</Helmet>
		<h1>Stored searches</h1>

		<p>The search function allows to store the filter conditions of a search for future reuse. This can be
			used just to remember a particular query for future reference or it can be used for repeated queries
			with up-to-date results, when the time condition is provided as relative time from now.
		</p>
		<Fig src={StoredSearchesImg} label="Stored searches entries"
			description="List of stored searches." width="600px" />

		<Tbl caption="UI Elements">
			<TblHead labels={["Element", "Description"]} />
			<TblBody>
				<TblRow>
					<TblCell>Search label</TblCell>
					<TblCell>Each stored search entry has a label that is set by the user when storeing a search. 
						Clicking on the label sets the stored search parameters and navigates to the search page. 
						Pre-existing search filter settings are overwritten.  
					</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>Search conditions</TblCell>
					<TblCell>Important search conditions are depicted as potentially abbreviated statements.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>Delete</TblCell>
					<TblCell>Deletes the stored search. This cannot be undone.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>Reload</TblCell>
					<TblCell>Reload the stored search list. May be needed when stored searches are manipulated in a different
						browser window.
					</TblCell>
				</TblRow>
			</TblBody>
		</Tbl>
	</>)
}