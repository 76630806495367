/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */


import { PetabiteContact } from '../components/PetabiteContact'
import { Helmet } from "react-helmet";
import {serviceName} from "../../../pbconstants"

export default function Legal() {
	return (<>
		<Helmet>
			<title>home / doc / privacy</title>
		</Helmet>
		<h1> Privacy policy</h1>

		<p>This privacy policy statement explains how our
		organization uses the personal data we collect from you when
			you use our service {serviceName}. </p>

		<h2> Contact</h2>

		<p>Responsible contact</p>


		<PetabiteContact />

		<h2> Data Officer </h2>

		<p>In case of questions, complaints or information requests, please contact our data officer Bernard Pruin.</p>

		<PetabiteContact contact="bernard.pruin" />

		<h2>Scope of personal data processing</h2>

		<p>We process personal data of our website visitors only
		to the extend necessary to ensure proper, secure operation of
		this website, thus only technical information is logged, as a
		side effect of the generic software used to serve the site
and in order to identify potential threat scenarios.</p>

		<p>In cases where further personal data processing is
		necessary we will ask for explicit agreement, if that is
		technical possible. If you send us an e-mail we assume that
		you agree for us to handle your personal data to respond and
enter into a conversation with you.</p>

		<h2> Website access data logging</h2>

		<h3> Legal basis for the handling of personal data</h3>

		<p>Processing of personal data in the course of website
		operations is performed on the basis of Art.6 Par.1 of the
			DSGVO</p>

		<h3> Scope of the personal data</h3>

		<p>The following information is logged when accessing our website:</p>
		<ul>
			<li>Information on the browser type and version used to access the site</li>
			<li>The reported IP address of the user</li>
			<li>Time and date of the access</li>
			<li>The URL of the page that is accessed</li>
			<li>Any additional URL parameter passed with the request</li>
		</ul>
		<p>We do not try to identify an individual visitor through
		its IP address. The geographic location of the IP address is
		however resolved as part of the standard website management toolset
		employed by us.</p>

		<h3>Purpose of website data processing</h3>

		<p>Log data is stored and kept to allow to ensure and
		check proper technical functioning of the website and the
		supporting infrastructure.</p>

		<h3>Data storage duration and deletion</h3>

		<p>The logged access data is stored for a limited period
		of time to allow for checking of potential access problems
		and the identification of threats and is usually deleted
		within a month after website access.</p>

		<h3> Right to information and deletion</h3>

		<p>In so far as real time data is concerned it is
		technically impossible to avoid the initial storage of access
		data. A subsequent deletion can be requested via our data
		officer. It should be noted however that such
		process entails the association of an IP address with a
		specific identifiable user. An association that we do not
		normally perform ourselves.</p>
	</>)
}