/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import {SUPPORT_EMAIL, serviceName} from "../../../pbconstants"


export function SN() {
	return <span>{serviceName}</span>
}

interface SupportEmailProps {
	label?:string
	body:string
}

export function SupportEmail({label=SUPPORT_EMAIL, body}:SupportEmailProps) {
	return <a href={"mailto:" + SUPPORT_EMAIL + "?subject=IEOTO-Problem&body=" + encodeURIComponent(body)  } >{label}</a>
}