/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import { addUserFilter } from '../../../state/store.userItems'

export function UserFilterDialog({ dispatch, onClose, productFilter }) {

	const [filterName, setFilterName] = useState("");

	const handleSave = () => {
		dispatch(addUserFilter(filterName, productFilter))
		onClose()
	}

	return (
		<Modal show size="lg" onHide={onClose} animation={false} >
			<Modal.Header closeButton className="bg-primary">
				<Modal.Title>Save Search</Modal.Title>
			</Modal.Header>
			<Modal.Body className="dialogue">
				<Form onSubmit={(event) => {
					event.preventDefault();
					handleSave()
				}}>
					<Row >
						<Form.Group as={Col} controlId="filterName">
							<Form.Label>Name</Form.Label>
							<Form.Control 
								value={filterName}
								onChange={(event) => setFilterName(event.target.value)}
								placeholder="Enter a meaningful name"
							/>
						</Form.Group>
					</Row>
				</Form>
			</Modal.Body>
			<Modal.Footer className="dialogue">
				<Button variant="primary" className="m-2" onClick={handleSave}>Save</Button>
				<Button variant="secondary" className="m-2" onClick={onClose}>Cancel</Button>
			</Modal.Footer>
		</Modal>
	);
}