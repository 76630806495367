import React from "react";
import { Helmet } from "react-helmet";
import { Fig } from "../components/Figure";
import { Tbl, TblHead, TblBody, TblCell, TblRow } from '../components/DocTable'
const BookmarkEntries = require('./bookmarkentries.png')
export default function Bookmarks() {
	return (<>
		<Helmet>
			<title>home / doc / bookmarks</title>
		</Helmet>
		<h1>Bookmarks</h1>

		<p>Individual search results can be bookmarked. The list of bookmarked products is shown on this page.
			The individual items in the list are similar to the entries in the original search result list.
		</p>
		<Fig src={BookmarkEntries} label="Bookmarked entries"
			description="List of bookmarked entries." width="600px" />

		<Tbl caption="UI Elements">
			<TblHead labels={["Element", "Description"]} />
			<TblBody>
				<TblRow>
					<TblCell>Product Entry</TblCell>
					<TblCell>The elements and icons of the product entry are described in the List view section.
						Only elements specific to this view are described.
					</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>Drop bookmark</TblCell>
					<TblCell>When the bookmark of an entry is dropped, the item is removed from the list.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>Reload</TblCell>
					<TblCell>Reload the bookmark list. May be needed when bookmarks are manipulated in a different
						browser window.
					</TblCell>
				</TblRow>
			</TblBody>
		</Tbl>

	</>)
}