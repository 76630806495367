/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */



export function PetabiteLogoImage() {
	return (<img src="/img/petabite.png" width="200px" height="69px" alt="Petabite GmbH logo"/>)
}

