/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import { aoiBoxIcon } from '../Icons/Icons'

function PosField({ lat, lon }) {
	return <span ><span className="font-monospace">{lat.toFixed(2)}</span>, <span className="font-monospace">{lon.toFixed(2)}</span></span>
}


export function AoiInfoField({ boundingBox, noBoxContent, w100 = true }) {

	if (boundingBox == null) {
		return (<div className="text-muted">
			<span>{aoiBoxIcon}</span> {noBoxContent}
		</div>);
	}
	else {
		return (<div className={w100 ? "w-100" : ""} > <span>{aoiBoxIcon}</span> <PosField lat={boundingBox.south} lon={boundingBox.west} />&nbsp;/&nbsp;
			<PosField lat={boundingBox.north} lon={boundingBox.east} />
		</div>);
	}
}

