/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { webClientUserManager, getAccountManagementUrl, getRegistrationUrl } from "../state/webClientUserManager";

export const login = () => { webClientUserManager.signinRedirect(); };
export const logout = () => { webClientUserManager.signoutRedirect() };
export const register = () => {
	window.location.href = getRegistrationUrl()
};

export const accountManagement = () => {
	window.open(getAccountManagementUrl(), '_blank');
}
