/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Popover from 'react-bootstrap/Popover'
import { PopoverHeader } from 'react-bootstrap';
import { PopoverBody } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { PrivacyPolicyDialog } from './PrivacyPolicyDialog'
import { ImprintDialog } from './ImprintDialog'

import { unconnectedGetVersionInfo } from '../../state/store.dataProducts'

import { buildInfo } from '../../buildVersion'


export function ServiceFooter({ pathname, search, hash, versionInfo }) {
	const navigate = useNavigate()

	const onPrivacyDialogClose = () => {
		if (hash === "#PrivacyPolicy")
			navigate(pathname + search)
	}

	const onImprintDialogClose = () => {
		if (hash === "#Imprint")
			navigate(pathname + search)
	}

	//	const onVersionDialogClose = () => {
	//		if (hash === "#Version")
	//			history.push(pathname + search)
	//	}

	const copy = async () => {
		await navigator.clipboard.writeText("The issue that I am concerned with occurred in this service version:" +
			"\nClient Version: " + versionInfo.implVersion + "/" + buildInfo.commitRefName + "/" + buildInfo.commitHash
			+ "\nAPI-Version: " + versionInfo.apiVersion + "/" + versionInfo.commitRefName + "/" + versionInfo.commitHash);
	}


	return (
		<Container fluid>
			<PrivacyPolicyDialog onClose={onPrivacyDialogClose} show={hash === "#PrivacyPolicy"} />
			<ImprintDialog onClose={onImprintDialogClose} show={hash === "#Imprint"} />
			{/* <VersionDialog versionInfo={versionInfo} onClose={onVersionDialogClose} show={hash === "#Version"} /> */}
			<Row>
				<Col md="auto" className="me-auto">
					Copyright © <Button variant="link" className="p-0" as="a" href="https://www.petabite.eu" target="_blank">Petabite GmbH</Button> 2023
				</Col>
				<Col md="auto" className="ms-auto">
					<Button variant="link" as="a" className="p-0 mx-2" href="/page/doc/terms">Terms and Conditions</Button>
					<Button variant="link" as="a" className="p-0 mx-2" href="#PrivacyPolicy">Privacy Policy</Button>
					<Button variant="link" as="a" className="p-0" href="#Imprint">Imprint</Button>
				</Col>
				<Col md="auto">
					<OverlayTrigger trigger="click" placement="top"
						overlay={
							<Popover id="popover-basic">
								<PopoverHeader as="h3">Version information</PopoverHeader>
								<PopoverBody>
									<span className="fw-bold">Client Version :</span> {versionInfo.implVersion}/{buildInfo.commitRefName}<br />
									<span className="fw-bold">API-Version :</span> {versionInfo.apiVersion}/{versionInfo.commitRefName}<br />
									<Button onClick={copy}>Copy to clipboard</Button>
								</PopoverBody>
							</Popover>
						}
						rootClose={true} >
						<Button variant="link" as="a" className="p-0" tabIndex={0}>V{versionInfo.implVersion}</Button>
					</OverlayTrigger>
				</Col>
			</Row>
		</Container>
	)
}

export function ConnectedServiceFooter() {
	const location = useLocation()

	const dispatch = useDispatch()
	const [versionInfo, setVersionInfo] = useState({})
	useEffect(() => {
		unconnectedGetVersionInfo(dispatch, setVersionInfo)
	}, [dispatch])
	return <ServiceFooter pathname={location.pathname} search={location.search} hash={location.hash} versionInfo={versionInfo} />
}
