/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { reloadIcon } from '../components/common/Icons/Icons';
import { UserFilterList } from '../components/data/UserFilters/UserFilterList';
import { ConnectedOnlyLoggedIn } from '../components/security/OnlyLoggedIn';
import { refreshUserFilters, selectUserFilterItemsFromStoreState } from '../state/store.userItems';

import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet";

export function UserFilterPage({ filterItems, dispatch }) {

	const reloadButtonClick = () => dispatch(refreshUserFilters())

	return (
		<Container fluid>
			<Row className="flex-row-reverse">
				<Col xs="auto" className="ml-auto p-0" >
					<Container fluid >
						<Row className="gx-0 my-2">
							<Col></Col>
							<Col xs="auto" className="ml-auto p-0">
								<Button variant="secondary" onClick={reloadButtonClick} style={{ minWidth: '7rem' }}>Reload {reloadIcon()}</Button>
							</Col>
						</Row>
					</Container>
				</Col>
				<Col className="p-0">
					{filterItems.length > 0 && <UserFilterList dispatch={dispatch} filterItems={filterItems} /> }
					{filterItems.length === 0 && <div className="p-4">No searches have been stored yet. 
						Store a search to see it listed here.</div>}
				</Col>
			</Row>
		</Container>
	)
}

export function ConnectedUserFilterPage() {
	const { filterItems } = useSelector(selectUserFilterItemsFromStoreState, shallowEqual)
	const dispatch = useDispatch()
	
	return <>
		<ConnectedOnlyLoggedIn loginHint>
			<Helmet>
				<title>IEOTO / stored searches</title>
			</Helmet>
			<UserFilterPage dispatch={dispatch} filterItems={filterItems} />
		</ConnectedOnlyLoggedIn>
	</>
}

