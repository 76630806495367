/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React, { useState } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form'
import { DataProductOptionsMenu } from './DataProductOptionsMenu'
import { DataProductAttributeListEntry } from './DataProductAttribute';
import { DataProductQuicklook } from '../DataProductQuicklook/DataProductQuicklook';
import { ExpandCollapseButton } from '../../common/ExpandCollapseButton/ExpandCollapseButton'
import { MapViewSimple } from '../../common/MapView/MapViewSimple'
import {
	measurementIcon,
	networkIcon,
	productTypeIcon,
	infoIcon
} from './DataProductAttributeIcons'

import { BookmarkIcon } from '../../common/Icons/Icons';
import { dataProductsSelected, createInSituProductFilter } from '../../../state/store.dataProducts'
import { DataProductQuicklookInSitu } from '../DataProductQuicklook/DataProductQuicklookInSitu';
import { addBookmarked, deleteBookmarked } from '../../../state/store.userItemsBookmarked';
import {
	OP_IN, replaceEoProductAttributeCondition, replaceInsituProductAttributeCondition
} from '../../../state/dataProducts-utils'

import { AttributesEnum } from '../../../utils/ApiTypes';
import { SupportEmail } from '../../doc/components/ServiceName';
import { ErrorBoundary } from 'react-error-boundary';
import { DataProductThumbnailInSitu } from './DataProductThumbnailInSitu';
import { ShowItemJson } from '../../common/ShowItemJson';
import { TRACEABILITY_SERVICE_QUERY_URL_PREFIX } from '../../../pbconstants';


interface DataProductListEntryProps {
	dispatch: any
	product: any
	initialCollapsed: boolean
	selected: boolean
	thumbnailImage: any
	quicklookImage: any
	productFilter: any
	setProductFilter: any
	access_token: string
	bookmarkCtrl: any
}

/**
 * Displays all attributes of a Product Search entry with label and value. Supports collapsed and expanded mode, in collapsed mode only mayor attributes are visible 
 */
export function DataProductListEntry({ dispatch, product,
	initialCollapsed = true,
	selected = false,
	thumbnailImage,
	quicklookImage,
	productFilter,
	setProductFilter,
	access_token,
	bookmarkCtrl }: DataProductListEntryProps) {

	const [collapsed, setCollapsedState] = useState(initialCollapsed);

	const onExpandCollapseButtonClick = () => {
		setCollapsedState(!collapsed)
	}

	const onSelectionSwitchChange = (event: any) => {
		dispatch(dataProductsSelected({ affectedProductIds: [product.id], isSelected: event.target.checked }))
	}

	/*
	const onProductSelectionToggle = () => {
		dispatch(dataProductsSelected({ affectedProductIds: [product.id], isSelected: !selected }))
	}
	*/

	const onThisTypeOnlyClick = () => {
		const keyVal = AttributesEnum.productType
		const newCond = { key: keyVal, op: OP_IN, values: [product.productType] }
		const newProductFilter = replaceEoProductAttributeCondition(productFilter, keyVal, newCond)
		setProductFilter(newProductFilter)
	}

	const onThisNetworkOnlyClick = () => {
		const keyVal = AttributesEnum.networkName
		const newCond = { key: keyVal, op: OP_IN, values: [product.networkName] }
		const newProductFilter = replaceInsituProductAttributeCondition(productFilter, keyVal, newCond)
		setProductFilter(newProductFilter)
	}


	const onBookmarkItem = () => {
		dispatch(addBookmarked(product.productName, product))
	}

	const onDropBookmarkItem = () => {
		dispatch(deleteBookmarked(product))
	}

	const onInSituClick = () => {
		setProductFilter(createInSituProductFilter(product));
	}

	const inSituContext = product.productClass === "InSitu"
	const isCopernicus = product?.missionProgram === "Copernicus"
	
	try {

		return (
			<ErrorBoundary fallback={<div>
				Sorry, an <SupportEmail label='Error' body={"Could not render " + JSON.stringify(product)} /> occurred in rendering this entry for item {product?.productName}. 
				</div>} onError={(error,info) => {
					// console.log(error,info)
				}}>
				<Row className={'mb-2 py-2 border-top border-secondary'} >
					<Col xs={'auto'} className="m-0 pt-3 pr-0">
						<Form.Check
							type="switch"
							id={`select-switch-${product.id}`}
							checked={selected}
							onChange={onSelectionSwitchChange}
						/>
						{bookmarkCtrl?.isBookmarked && <BookmarkIcon />}
					</Col>

					<Col xs={'auto'} className={'ml-auto p-0 m-0 order-sm-last ' + (selected ? ' bg-secondary' : '')}>
						<ButtonGroup>
							<ShowItemJson product={product} />
							<DataProductOptionsMenu product={product} access_token={access_token} withDownload withCopyLink withDetailsLink>
								{setProductFilter && !inSituContext && <Dropdown.Item onClick={onThisTypeOnlyClick}>{productTypeIcon} Search this type only</Dropdown.Item>}
								{setProductFilter && inSituContext && <Dropdown.Item onClick={onThisNetworkOnlyClick}>{networkIcon} Search this network only</Dropdown.Item>}
								{(!inSituContext && product.spatialCoverage && setProductFilter) && <Dropdown.Item onClick={onInSituClick}>{measurementIcon} Search in-situ data</Dropdown.Item>}
								{!bookmarkCtrl?.isBookmarked && <Dropdown.Item onClick={onBookmarkItem} disabled={!bookmarkCtrl?.canBeBookmarked()}><BookmarkIcon /> Set bookmark</Dropdown.Item>}
								{bookmarkCtrl?.isBookmarked && <Dropdown.Item onClick={onDropBookmarkItem} ><BookmarkIcon /> Drop bookmark</Dropdown.Item>}
								{isCopernicus && <Dropdown.Item href={TRACEABILITY_SERVICE_QUERY_URL_PREFIX + product.productName} target="_blank">{infoIcon} Product integrity check</Dropdown.Item> }

							</DataProductOptionsMenu>
							<ExpandCollapseButton collapsed={collapsed} onClick={onExpandCollapseButtonClick} />
						</ButtonGroup>
					</Col>

					<Col style={{ minWidth: "1rem" }} className={'col-sm col-12' + (selected ? ' bg-secondary ' : '')} >
						<Container fluid>
							<Row >
								<Col md={{ order: 'first', span: 3 }} lg={{ order: 'first', span: 2 }} xl={{ order: 'first', span: 1 }} className="ml-auto px-0 rounded">
								{!inSituContext && <DataProductQuicklook thumbnailImage={thumbnailImage} quicklookImage={quicklookImage} thumbnail /> }
								{inSituContext && <DataProductThumbnailInSitu product={product} />}
									{/* product, access_token = null, withDownload = false, withDetailsLink = true, withCopyLink = true, */}
								</Col>
								<Col md={9} lg={10} xl={11} >
									<div className="d-flex flex-wrap">
										{
											toSortedAttributesArray(product).map(([key, value]) => {
												return (
													<DataProductAttributeListEntry key={key} attributeName={key} attributeValue={value} unit={product.units?.[key]} brief={collapsed}
														inSituContext={inSituContext} />
												)
											})
										}
									</div>
								</Col>
							</Row>
							{collapsed ? null :
								<Row>
									<Col md={6} className="pl-0 ml-0 mt-1">
										{!inSituContext && <DataProductQuicklook thumbnailImage={thumbnailImage} quicklookImage={quicklookImage} />}
										{inSituContext && <DataProductQuicklookInSitu product={product} />}

									</Col>
									<Col md={6} className="pr-0 pl-0 ml-0 mr-0 mt-1">
										<MapViewSimple product={product} />
									</Col>
								</Row>
							}

						</Container>
					</Col>

				</Row>
			</ErrorBoundary>)


	} catch (e) {
		// console.log(e)
		return <Row>
			<Col >Sorry, something went wrong, this entry cannot be visualised. Try reload, if the problem persists, please inform us via <SupportEmail body={"Could not render " + JSON.stringify(product)} /></Col>
		</Row>
	}

}

/**
 * Sort order of product attributes
 */
let i = 0;
const attributeOrder = new Map()
	.set(AttributesEnum.satellite, i++)
	.set(AttributesEnum.productType, i++)
	.set(AttributesEnum.networkName, i++)
	.set(AttributesEnum.sensingCycle, i++)
	.set(AttributesEnum.sensingRelativeOrbitNumber, i++)
	.set(AttributesEnum.productSizeInBytes, i++)
	.set(AttributesEnum.coveragePercentCloud, i++)
	.set(AttributesEnum.thingId, i++)
	.set(AttributesEnum.thingName, i++)
	.set(AttributesEnum.thingLabel, i++)
	.set(AttributesEnum.productName, i++)
	.set(AttributesEnum.polarization, i++)
	.set(AttributesEnum.sensingDatatakeId, i++)
	.set(AttributesEnum.sensingAbsoluteOrbitNumber, i++)
	.set(AttributesEnum.sensingOrbitDirection, i++)
	.set(AttributesEnum.temporalCoverageStart, i++)
	.set(AttributesEnum.temporalCoverageEnd, i++)
	.set(AttributesEnum.published, i++)
	.set(AttributesEnum.missionProgram, i++)
	.set(AttributesEnum.missionName, i++)
	.set(AttributesEnum.associations, i++)
	;

const toSortedAttributesArray = (product: any) => {
	const attributesArray = Object.entries(product).filter((entry) => !(entry[0] in ["channels", "displayChannel"]));
	if (attributesArray.find((e) => e[0] === "thingName")) {
		const idIndex = attributesArray.findIndex((e => e[0] === "thingId"))
		if (idIndex > -1 ) {
			delete attributesArray[idIndex]
		}
	}

	product["channels"]?.forEach((element: any) => {
		attributesArray.push([element.channelName, element])
	});

	return attributesArray.sort(([keyA, valueA], [keyB, valueB]) => {
		if (attributeOrder.has(keyA)) {
			if (attributeOrder.has(keyB)) {
				return attributeOrder.get(keyA) - attributeOrder.get(keyB); //A and B known, use attributeOrder-Map
			} else {
				return -1; //A first, B is unknown
			}
		} else {
			if (attributeOrder.has(keyB)) {
				return 1; //B first, A is unknown
			} else {
				return 0; //dont care, both unknown
			}
		}

	})
}
