/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown'
import { optionsMenuIcon } from '../Icons/Icons'
import './OptionsMenu.scss'

export function OptionsMenu({ children,  drop='down' }) {
	return (
		<Dropdown drop={drop}>
			<Dropdown.Toggle variant="white" className='optionsMenu' id="dropdown-basic">{optionsMenuIcon}</Dropdown.Toggle>
			<Dropdown.Menu>
				{children}
			</Dropdown.Menu>
		</Dropdown>
	)
}
