/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Image from 'react-bootstrap/Image'
import MiddleEllipsis from 'react-middle-ellipsis';
import { DataProductOptionsMenu } from './DataProductOptionsMenu'
import { DataProductAttributeTableRow } from './DataProductAttribute';
import { MapView } from '../../common/MapView/MapView'
import { pscoutApi } from '../../../state/pscoutApi'
import { Helmet } from "react-helmet";
import HomePageContent from '../../../components/HomePageContent/HomePageContent'
import { ConnectedOnlyAnonymous } from '../../../components/security/OnlyAnonymous'
import { serviceName } from "../../../pbconstants"
import './DataProductDetails.css'
import { DataProductQuicklookInSitu } from '../DataProductQuicklook/DataProductQuicklookInSitu';

/**
 * Displays a single Product with all available details for the product 
 */
export function DataProductDetails({ product, thumbnailImage, quicklookImage, access_token }) {
	
	const inSituContext = product.productClass === "InSitu"

	return (<Container fluid>
		<Helmet>
			<title>{serviceName} {product.productName}</title>
			<meta property="og:type" content="dp:dataproduct" />
			<meta name="og:title" content={product.productName} />
			<meta name="og:image" content={product.quicklookURL} />
			<meta name="og:url" content={pscoutApi.toDataProductViewUrl(product)} />
			<meta name="og:site_name" content="{serviceName}" />
			<meta name="dp:time" content={product.temporalCoverageStart} />
			<meta name="article:published_time" content={product.temporalCoverageStart} />
			<meta name="article:tag" content={product.productType} />
			<meta name="article:tag" content={product.satellite} />
		</Helmet>
		<Row className="equal">
			<Col xs={10} >
				{/* Product name */}
				<MiddleEllipsis>
					<h4 className="mt-2 d-flex justify-content-center">
						<span className="ellipseMe" data-toggle="tooltip" title={product.productName}>
							{product.productName}
						</span>
					</h4>
				</MiddleEllipsis>
			</Col>
			<Col xs={'auto'} className={'ml-auto p-0 m-0'}>
				<DataProductOptionsMenu product={product} access_token={access_token} withDownload withCopyLink />
			</Col>
		</Row>
		<Row>
			<Col md={12} lg={12} xl={{ order: 'first', span: 6 }} className="p-1" >
				<Container fluid className="data-product-details-section h-100">
					<h4 className="d-flex justify-content-center" >Attributes</h4>
					<Container fluid className="d-flex justify-content-center" >
						{/* Product attributes table */}

						<Table striped bordered className="table-responsive" size="sm" style={{ width: "auto" }}>
							<thead>
								<tr><th style={{ maxWidth: "12rem" }}>Attribute</th><th>Value</th></tr>
							</thead>
							<tbody>
								{
									toSortedAttributesArray(product)
										.map(([attributeName, attributeValue]) => {
											return <DataProductAttributeTableRow key={attributeName} attributeName={attributeName} attributeValue={attributeValue} />
										})
								}
							</tbody>
						</Table>
					</Container>
				</Container>
			</Col>

			<Col md={12} lg={12} xl={6} className="p-1" >
				<Container fluid className="data-product-details-section h-100 justify-content-center">
					<h4 className="d-flex justify-content-center">Quicklook</h4>
					{/* Product quicklook */}
					<Container fluid className="d-flex justify-content-center" >
						{/*<DataProductQuicklook thumbnailImage={thumbnailImage} quicklookImage={quicklookImage} /> */}
						<div style={{ "maxHeight": "500px", "overflow": "auto" }}>
							{product.quicklookURL && <Image src={product.quicklookURL} alt='Quicklook' rounded fluid />}
							{inSituContext && <DataProductQuicklookInSitu product={product} />}
							{!inSituContext && !product.quicklookURL && <span>Sorry, this product does not come with a quicklook image.</span>}
						</div>
					</Container>
				</Container>
			</Col>

			<Col md={12} lg={12} xl={6} style={{ minHeight: "600px" }} className="p-1">
				<Container fluid className="data-product-details-section" style={{ height: "100%", width: "100%" }} >
					<h4 className="d-flex justify-content-center">Footprint</h4>
					<Container fluid style={{ height: "calc(100% - 2rem)", width: "100%" }} >
						{/* Product footprint-map */}
						<MapView products={[product]} selectable={false} />
					</Container>
				</Container>
			</Col>


			<ConnectedOnlyAnonymous>
				<Col md={12} lg={12} xl={6} style={{ minHeight: "600px" }} className="p-1">
					<div className="p-5 border container-lg welcome-page-content">
						<HomePageContent />
					</div>
				</Col>
			</ConnectedOnlyAnonymous>

		</Row>
	</Container>
	)
}

/**
 * Sort order of product attributes
 */
let i = 0;
const attributeOrder = new Map()
	.set('missionProgram', i++)
	.set('missionName', i++)
	.set('satellite', i++)
	.set('productType', i++)
	.set('productName', i++)
	.set('temporalCoverageStart', i++)
	.set('temporalCoverageEnd', i++)
	.set('productSizeInBytes', i++)
	.set('sensingDatatakeId', i++)
	.set('sensingAbsoluteOrbitNumber', i++)
	.set('sensingOrbitDirection', i++)
	.set('sensingCycle', i++)
	.set('sensingRelativeOrbitNumber', i++)
	.set('coveragePercentCloud', i++)
	.set('polarization', i++)
	.set('published', i++)
	;

const toSortedAttributesArray = (product) => {
	const attributesArray = Object.entries(product);
	return attributesArray.sort(([keyA, valueA], [keyB, valueB]) => {
		if (attributeOrder.has(keyA)) {
			if (attributeOrder.has(keyB)) {
				return attributeOrder.get(keyA) - attributeOrder.get(keyB); //A and B known, use attributeOrder-Map
			} else {
				return -1; //A first, B is unknown
			}
		} else {
			if (attributeOrder.has(keyB)) {
				return 1; //B first, A is unknown
			} else {
				return 0; //dont care, both unknown
			}
		}

	})
}

