/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react'
import Button from 'react-bootstrap/Button'

import { connect } from 'react-redux'

import { selectApiClientOidcConfigFromStoreState } from "../../state/store.oidc";
import { ApiClientStoreContext } from '../../state/apiClientStore';
import { apiClientUserManager } from '../../state/apiClientUserManager';
import { copyToClipboardIcon } from '../common/Icons/Icons'
import { Col } from 'react-bootstrap';

const onToClipboardButtonClick = (apiClientOidcConfig) => () => {
	navigator.clipboard.writeText(JSON.stringify(apiClientOidcConfig, null, 2)).then(function() {
	}, function(error) {
		console.log("Failed to copy to clipboard.", error);
	})
}


export function ApiClientAccessToken({ apiClientOidcConfig }) {
	
	return (
		<Col className="bg-white">
			<div className='p-3 lead bg-white'>You can generate refresh token information that allows to connect to the service API using the OAuth 2.0 standard.</div>
			<Button variant="primary" className="m-3" onClick={() => apiClientUserManager.signinRedirect()}>Generate token</Button>
			{null == apiClientOidcConfig ? null :
				<div className='p-3'>
					<h5>Configuration information for clients (e.g. put at <code>/etc/petabite/pscout-api-client.config.json</code>):</h5>
					<pre class="bg-white border p-2" style={{maxWidth:"1000px"}}><code>{JSON.stringify(apiClientOidcConfig, null, 2)}</code></pre>
					
					<Button variant='light' onClick={onToClipboardButtonClick(apiClientOidcConfig)} >{copyToClipboardIcon} Copy to Clipboard</Button>
				</div>
			}
		</Col>
	)
}

export const ConnectedApiClientAccessToken = connect(selectApiClientOidcConfigFromStoreState, null, null, { context: ApiClientStoreContext })(ApiClientAccessToken)