/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

export function PetabiteContact({ contact="info"}) {
	
	return <>
	    <span className="fw-bold">Petabite GmbH</span><br/>
		Munstermannskamp 1<br/>
		21335 Lüneburg<br/>
		Germany<br/>
		<span className="fw-bold">Email:</span> { contact } (at) petabite.eu<br/>
		<span className="fw-bold">Phone:</span> +49 4131 7898-137<br/>
		<span className="fw-bold"><a href="https://www.petabite.eu">https://www.petabite.eu</a></span><br/>
	</>
	
}