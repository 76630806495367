/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import { SN } from '../components/doc/components/ServiceName'

import HomePageContent from '../components/HomePageContent/HomePageContent'
import { ConnectedOnlyAnonymous } from '../components/security/OnlyAnonymous'
import { ConnectedOnlyLoggedIn } from '../components/security/OnlyLoggedIn'
import { legalSlug } from './DocPage'
import { Helmet } from "react-helmet";

export function HomePage() {
	return (
		<div className="welcome-page container-fluid h-100"><Helmet>
			<title>IEOTO / home</title>
			<meta name="description" content="The petabite data service IEOTO provides access to a large number of earth observation remote sensing data and in-situ measurements." />
		</Helmet>

			<div>&nbsp;</div>
			<ConnectedOnlyAnonymous>
				<div className="p-5 rounded container-lg welcome-page-content text-center">
					<HomePageContent />
				</div>
			</ConnectedOnlyAnonymous>
			<ConnectedOnlyLoggedIn>
				<div className="p-5 container-lg welcome-page-content">
					<h1>Welcome to the <SN/> </h1>

					<p>Please check-out the comprehensive <a href="/page/doc">online documentation</a>. Further information and support can also be found through
					our <a href="https://petabite.atlassian.net/servicedesk/customer/portals" target="_blank" rel="noreferrer">support center</a>. (Note: This requires a separate registration.)</p>

					<p>Information on how we handle personal data can be found on our <a href={legalSlug}>privacy policy</a> page.</p>

				</div>
			</ConnectedOnlyLoggedIn>
		</div>
	);
}
