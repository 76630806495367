/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { Fig } from '../components/Figure'
import { Tbl, TblHead, TblBody, TblCell, TblRow } from '../components/DocTable'
import WebListFigure from './web-list-component.png'
import { dataIconsList } from '../../data/DataProduct/DataProductAttributeIcons'
import { optionsMenuIcon } from '../../common/Icons/Icons'
import { Helmet } from "react-helmet";
import Form from 'react-bootstrap/Form'

export default function WebList() {
	return (<>
		<Helmet>
			<title>home / doc / list</title>
		</Helmet>
		<h1>Result list component</h1>

		<p>The result list component lists the results of a search and provides some details on the products found.
		A list entry can be expanded to show an extended list of metadata and, if available, a product quicklook.
		There is also enlarged view of the product fooptrint with an underlying map. For products near the pole,
		the map projection is adjusted to provide a less distorted view of the footprint.</p>

		<Fig src={WebListFigure} label="Map component" />

		<Tbl caption="Map elements">
			<TblHead labels={["Item", "Description"]} />
			<TblBody>
				<TblRow>
					<TblCell>Search returned ...</TblCell>
					<TblCell>Information on the search result as a whole. When it says "at least", there are more results than shown in the list and
					one should consider narrowing the search criteria.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>Show only selected</TblCell>
					<TblCell>List only the entries that are selected. Result element selection can either be done in the list or in the map.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>Export</TblCell>
					<TblCell>Download a semicolon separated list of the results. The semicolon was chosen as a 
						separator to allow for the easy inclusion of JSON as result fields, e.g. for spatial coverage 
						information.
						When there are selected results, only the selected ones are exported.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>Item entry</TblCell>
					<TblCell>For each result element, there is a self contained card-like element. The elemente can be extended to show </TblCell>
				</TblRow>
				
				<TblRow>
					<TblCell><Form.Check
						type="switch"
						id="select-switch-docu"
						checked={true}
					/></TblCell>
					<TblCell>Download a comma separated list of the results. When there are selected results, only the selected ones are exported.</TblCell>
				</TblRow>

				<TblRow>
					<TblCell> Map area </TblCell>
					<TblCell> The map area shows the product footprints on top of selectable map background.
					The map background selection options depend on the chosen projection and are
					available through the map controls in the upper right corner of the map view.
 	            Selected products are highlighted.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>+/-</TblCell>
					<TblCell>Zoom in and out of the map.</TblCell>
				</TblRow>


			</TblBody>
		</Tbl>

		<p>To save space we rely heavily on icons as metadata labels, their meaning is listed here.</p>

		<Tbl caption="List items">
			<TblHead labels={["Item", "Description"]} />
			<TblBody>
				{
					dataIconsList.map((iconInfo, index) => <TblRow key={index}>
						<TblCell>{iconInfo.icon}</TblCell>
						<TblCell>{iconInfo.description}</TblCell>
					</TblRow>)
				}
				<TblRow key="xxx">
					<TblCell>{optionsMenuIcon}</TblCell>
					<TblCell>Options menue. Access to Download, individual product page, individual product page link and filter by type function.</TblCell>
				</TblRow>
			</TblBody>
		</Tbl>
	</>
	)
}
