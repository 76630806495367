/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react';

import Container from 'react-bootstrap/Container';
import { ConnectedOnlyLoggedIn } from '../components/security/OnlyLoggedIn';
import { Helmet } from "react-helmet";
import { Route, Routes } from 'react-router-dom';
import { selectUserProfileFromStoreState } from '../state/store.oidc';
import { shallowEqual, useSelector } from 'react-redux';
import { WorkflowsSubPage } from '../components/processing/WorkflowsSubpage';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { SubTocEntry } from '../components/common/SubTocEntry';

import { JobsSubPage } from '../components/processing/JobsSubpage';

const workflowSlug = "workflows"
const jobsSlug = "jobs"

const fullPath = (leaf: string) => "/page/processing/" + leaf;

function TableOfContent() {

	const toc = [
		{ path: workflowSlug, title: "Workflows" },
		{ path: jobsSlug, title: "Jobs" },
	]

	return (
		<Navbar expand="lg">
			<Navbar.Toggle />
			<Navbar.Collapse >
				<Nav className="flex-column">
					{
						toc.map((e, index) => <SubTocEntry path={fullPath(e.path)} title={e.title} key={index} />)
					}
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	)
}


export function ConnectedProcessingPage() {
	const { userProfile } = useSelector(selectUserProfileFromStoreState, shallowEqual)

	return <>
		<ConnectedOnlyLoggedIn loginHint>
			<Helmet>
				<title>IEOTO / Processing</title>
			</Helmet>
			<Container fluid className='doc-page m-0 h-100' >
				<Row className='h-100 gx-0'>
					<Col sm={12} lg={2} xl={1} className="p-0" >
						<TableOfContent />
					</Col>
					<Col sm={12} lg={10} xl={11} className="p-0" >
						<Container className="doc-page-content" aria-label="Documentation content">
							<Routes>
								<Route path="/" element={<WorkflowsSubPage userId={userProfile.id as string} />}  />
								<Route path={workflowSlug} element={<WorkflowsSubPage userId={userProfile.id as string} />}  />
								<Route path={jobsSlug} element={<JobsSubPage userId={userProfile.id as string} />}  />
							</Routes>
						</Container>
					</Col>
				</Row>
			</Container>
		</ConnectedOnlyLoggedIn>
	</>
}

