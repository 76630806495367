/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { SN } from '../components/ServiceName'
import { Helmet } from "react-helmet";

export default function Support() {
	return (<>
		<Helmet>
			<title>home / doc / support</title>
		</Helmet>

		<h1>Support</h1>

		<p>If you do not find the information on <SN /> that you are looking for in this documentation, found a problem with the service or
		   would like to suggest an improvement, please visit <a href="https://support.petabite.space">https://support.petabite.space</a> or
           send and e-mail to support&nbsp;(at)&nbsp;petabite.space.</p>

		<p>If you do not want to get in touch right away about
		an issue, you can help us by taking note of the service version that you have been dealing with. You can find the
		complete version specification by clicking on the main version string at the bottom right of every page and copy
           the specification into your notes.</p>

	</>)
}

