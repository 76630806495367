/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'

export function Tbl({ bordered = true, caption="", children }) {
	return <Container className="py-3">
		<Table bordered={bordered} caption="a table" className="table-striped" hover>
		<caption>Table: {caption}</caption>
			{children}
		</Table>
	</Container>
}

export function TblHead({ labels }) {
	return <thead>
		<tr>{labels.map((label, index) => <th key={index} scope="col">{label}</th>)}</tr>
	</thead>
}

export function TblBody({ children }) {
	return <tbody>{children}</tbody>
}

export function TblCell({ children }) {
	return <td>{children}</td>
}

export function TblRow({ cols = [], children }) {
	return <tr>{cols.map((entry, index) => <td key={index}>{entry}</td>)}{children}</tr>
}


