import { ProductQuerySpecType } from "./storeTypes";

export const emptyProductFilter:ProductQuerySpecType = {
	timeFilter: {
		intervalStart: null,
		intervalEnd: null,
		relIntervalStart: null,
		relIntervalEnd: null,
	},

	boundingBox: null,
	pageNumber: 0,
	pageSize: 50,
	sortRequest: [],
	withEoFilters: true,
	withInsituFilters: true,
	eoFilters: [],
	insituFilters: []
};
