/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react';

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	PointElement,
	LineElement,
	LineController,
	BarController,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';
import { Col, Row } from 'react-bootstrap';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	PointElement,
	LineElement,
	LineController,
	BarController
);

const BOUNDARY_PRECISSION=3

export function DataProductQuicklookInSitu({ product }) {

	const numericChannels = product.channels.filter((c) => ["FLOAT", "INTEGER", "DOUBLE"].includes(c.channelType)).slice(0,4)

	const colCnt = 12 / Math.max(numericChannels.length, 1)

	return <Row className="row" style={{ "height": "350px", "maxHeight": "350px" , overflow : "scroll" }} >
		{numericChannels.map((c) => <Col key={c.channelName} xxl={colCnt} className="py-3 border border-3" title={c.channelName + "[" + c.channelUnit + "]"}>
			<div><span>{c.channelName.substring(0,7)}</span></div>
			<Bar style={{ "height": "300px", "maxHeight": "300px" }} data={
				{
					labels: [c.min.toPrecision(BOUNDARY_PRECISSION)],
					datasets: [
						{
							type: 'bar',
							label: c.channelName,
							data: [[c.min, c.max]],
							borderColor: 'rgba(255, 99, 132, 1)',
							borderWidth: { left: 0, right: 0, top: 2, bottom: 2 },
							borderSkipped: false
						},
						{
							label: "",
							type: 'line',
							data: [c.avg],
							radius: 10,
							backgroundColor: 'rgba(255, 99, 132, 1)',
						}
					],

				}
			} options={{
				scales: {
					y: {
						display: false }
				},
				layout: {
					autoPadding: false
				},
				
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						enabled: false
					},
					title: {
						text: c.max.toPrecision(BOUNDARY_PRECISSION) ,
						display: true,
					},
				},
				maintainAspectRatio: false
			}} />
		</Col>
		)}
	</Row>

}

