import { BoundingBox, ProductFilter } from "./types"

export enum AttributesEnum {
    productName='productName',
    productType='productType',
    missionProgram='missionProgram',
    missionName='missionName',
    satellite='satellite',
    polarization='polarization',
    sensingOrbitDirection='sensingOrbitDirection',
    sensingDatatakeId='sensingDatatakeId',
    sensingCycle='sensingCycle',
    sensingRelativeOrbitNumber='sensingRelativeOrbitNumber',
    sensingAbsoluteOrbitNumber='sensingAbsoluteOrbitNumber',
    coveragePercentCloud='coveragePercentCloud',
    association_productName='association.productName',
    networkName='networkName',
    temporalCoverageStart='temporalCoverageStart',
    temporalCoverageEnd='temporalCoverageEnd',
    thingId='thingId',
    thingInfo='thingInfo',
    thingName='thingName',
    thingLabel='thingLabel',
    productSizeInBytes='productSizeInBytes',
    published='published',
    numObservations='numObservations',
    networkLabel='networkLabel',
    spatialCoverage='spatialCoverage',
    productClass='productClass',
    aggregationLevels='aggregationLevels',
    associations='associations',
    ownerName='ownerName'
}

/*

timeFilter : {
		//publishedAfter: null,
		//publishedBefore: null,
		//lastModifiedAfter: null,
		//lastModifiedBefore: null,
		intervalStart: null,
		intervalEnd: null,
		relIntervalStart: null,
		relIntervalEnd: null,
	},

	boundingBox: null,
	pageNumber: 0,
	pageSize: 50,
	sortRequest: ["temporalCoverageStart DESC", "productType ASC", "productName ASC"],
	eoFilters: [],
	insituFilters: []

*/

// export interface BoundingBox {
//     west: number
//     east: number
//     north: number
//     south: number
// }

export interface UiQuery {
    timeFilter: {
        intervalStart: number | null
        intervalEnd: number | null
        relIntervalStart: number | null
        relIntervalEnd: number | null
    }
    boundingBox: BoundingBox
    pageNumber: number
    pageSize: number
    sortRequest: Array<string>
    eoFilters: Array<ProductFilter>
    insituFilters:  Array<ProductFilter>

}