import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { PetabiteLogoImage } from "./PetabiteLogoImage"

export function PetabiteLogoImageHeader() {
    return (<Container className="container-fluid" >
        <Row className="justify-content-center" >
            <Col className="col-auto" >
                <PetabiteLogoImage />
            </Col>
        </Row>
    </Container>)
}