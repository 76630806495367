import L from 'leaflet'

export interface BoundingBoxType {
	east:number
	west:number
	north:number
	south:number
}

export function toLeafletLatLngBounds(boundingBox:BoundingBoxType, margin = 0) {
	var west = boundingBox.west - margin;
	while (west < -180) { west += 360 }
	while (west > 180) { west -= 360 }

	var east = boundingBox.east + margin;

	while (east < -180) { east += 360 }
	while (east > 180) { east -= 360 }
	if (east < west) { east += 360 } //it's ok to go bejond 180 here!

	var north = boundingBox.north + margin;
	if (north > 90) { north = 90 }

	var south = boundingBox.south - margin;
	if (south < -90) { south = -90 }

	return L.latLngBounds([south, west], [north, east]);
}

export function leafletMapBoundsToBoundingBox(mapBounds:any) {
	return {
		west: mapBounds.getWest(),
		east: mapBounds.getEast(),
		north: mapBounds.getNorth(),
		south: mapBounds.getSouth()
	}
}

