/* tslint:disable */
/* eslint-disable */
/**
 * p:scout Search API
 * This is the Search API of p:scout. build version is @@@CI_BUILD_VERSION@@@
 *
 * OpenAPI spec version: @@@CI_COMMIT_REF_NAME@@@
 * Contact: info@petabite.eu
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * operator to use for comparison   * `<`: `key` is less than `value`    * integer    * number    * timestamp  * `<=`: `key` is less than or equal to `value`    * integer    * number    * timestamp  * `>`: `key` is greater than `value`    * integer    * number    * timestamp  * `>=`: `key` is greater than or equal to `value`    * integer    * number    * timestamp  * `==`: `key` is equal to `value`    * integer    * number    * timestamp    * boolean    * keyword  * `!=`: `key` is not equal to `value`    * integer    * number    * timestamp    * boolean    * keyword  * `IN`: `key` is one of `values`    * integer    * number    * timestamp    * keyword  * `NOT_IN`: `key` is not one of `values`    * integer    * number    * timestamp    * keyword  * `CONTAINS`: `key` contains all of `values`    * list of integer    * list of number    * list of keyword  * `NOT_CONTAINS`: `key` contains none of `values`    * list of integer    * list of number    * list of keyword  * `LIKE`: `key` matches pattern of `value`    * keyword    * text  * `NOT_LIKE`: `key` not matches pattern of `value`    * keyword    * text  * `lt`: alias for `<`  * `lte`: alias for `<=`  * `gt`: alias for `>`  * `gte`: alias for `>=`  * `eq`: alias for `==`  * `neq`: alias for `!=`  * `<>`: alias for `!=` 
 * @export
 * @enum {string}
 */
export enum ProductFilterOperator {
    LessThan = '<',
    LessThanOrEqualTo = '<=',
    GreaterThan = '>',
    GreaterThanOrEqualTo = '>=',
    // = '==',
    NotEqual = '!=',
    // = '<>',
    IN = 'IN',
    NOTIN = 'NOT_IN',
    CONTAINS = 'CONTAINS',
    NOTCONTAINS = 'NOT_CONTAINS',
    LIKE = 'LIKE',
    NOTLIKE = 'NOT_LIKE',
    Lt = 'lt',
    Lte = 'lte',
    Gt = 'gt',
    Gte = 'gte',
    Eq = 'eq',
    Neq = 'neq'
}

