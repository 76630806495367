/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { Fig } from '../components/Figure'
import { Tbl, TblHead, TblBody, TblCell, TblRow } from '../components/DocTable'

import WebMapFigure from './web-map-component.png'
import { ArcticProjection, MercatorProjection, AntarcticProjection, layersIcon,allZoomIcon,selectionZoomIcon } from '../../common/Icons/Icons'
import { Helmet } from "react-helmet";

export default function WebMap() {
	return (<>
	<Helmet>
			<title>home / doc / map</title>
		</Helmet>
		<h1>Map view</h1>

		<p>The map view allows to easily understand the geolocation of the products of the result set.</p>

		<Fig src={WebMapFigure} label="Map component" description="Map component that shows the map controls and a few product footprints." />

		<Tbl caption="Map elements">
			<TblHead labels={["Item", "Description"]} />
			<TblBody>
				<TblRow>
					<TblCell>	<ArcticProjection /></TblCell>
					<TblCell> A projection suitable for viewing arctic regions, with limited distortions around the North pole.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>	<MercatorProjection /></TblCell>
					<TblCell> A reasonable projection for viewing most parts of the planet. Except for polar regions.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>	<AntarcticProjection /></TblCell>
					<TblCell> A projection suitable for viewing antarctic regions, with limited distortions around the South pole.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>	{selectionZoomIcon()}</TblCell>
					<TblCell> Reposition the map view around the selected search result elements.</TblCell>
				</TblRow>
				
				<TblRow>
					<TblCell>	{allZoomIcon()}</TblCell>
					<TblCell> Reposition the map view around the search result elements.</TblCell>
				</TblRow>
				
				
				<TblRow>
					<TblCell> Map area </TblCell>
					<TblCell> The map area shows the product footprints on top of selectable map background.
					The map background selection options depend on the chosen projection and are
					available through the map controls in the upper right corner of the map view.
 	            Selected products are highlighted.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>+/-</TblCell>
					<TblCell>Zoom in and out of the map.</TblCell>
				</TblRow>
				<TblRow>
					<TblCell>{layersIcon()}</TblCell>
					<TblCell>Select from the available background map layers.</TblCell>
				</TblRow>
				

			</TblBody>
		</Tbl>
	</>)
}




