/* tslint:disable */
/* eslint-disable */
/**
 * p:scout Search API
 * This is the Search API of p:scout. build version is @@@CI_BUILD_VERSION@@@
 *
 * OpenAPI spec version: @@@CI_COMMIT_REF_NAME@@@
 * Contact: info@petabite.eu
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * type of the channel
 * @export
 * @enum {string}
 */
export enum ObservationChannelType {
    BOOLEAN = 'BOOLEAN',
    INTEGER = 'INTEGER',
    LONG = 'LONG',
    FLOAT = 'FLOAT',
    DOUBLE = 'DOUBLE',
    KEYWORD = 'KEYWORD',
    STRING = 'STRING',
    TAGS = 'TAGS',
    TIME = 'TIME',
    ENUMERATION = 'ENUMERATION',
    POSITION = 'POSITION'
}

