/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { DOCUMENTATION_URL } from '../../../pbconstants';
import { SN } from '../components/ServiceName'
import { Helmet } from "react-helmet";

export default function Api() {
	const apiDocUrl = DOCUMENTATION_URL + "/pb-ieoto-public-api"
	const notebooksUrl = DOCUMENTATION_URL + "/pb-ieoto-demo"
	return (<>
		<Helmet>
			<title>home / doc / api</title>
		</Helmet>
		<h1>API functionality</h1>
		<p>
			<SN /> comes with a comprehensive REST API that allows to integrate the service 
			functionality programmatically.
		</p>
		<p>Through the API you can within the limit of your chosen account level:</p>
		<ul>
			<lii>Search and download data</lii>
			<lii>Define in-situ/IoT networks</lii>
			<lii>Manage in-situ sensors and IoT things networks</lii>
			<lii>Submit in-situ data</lii>
		</ul>

		<p>The API documentation is provided in a separate versioned repository at &nbsp;
			<a href={apiDocUrl} target='_blank' rel="noreferrer">{apiDocUrl}</a>.
		</p>

		<p>Some public jupyter notebooks are available that demonstrate the usage of the <SN /> API &nbsp;
			<a href={notebooksUrl} target='_blank' rel="noreferrer">{notebooksUrl}</a>.
		</p>

	</>
	)
}
