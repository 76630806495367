/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react';
import Container from 'react-bootstrap/Container'
import { DataProductListEntry } from '../DataProduct/DataProductListEntry'
import { toImage } from '../../../state/store.dataProducts'
import { BookmarkCtrl } from '../../../utils/BookmarkCtrl';
import { Col, Row } from 'react-bootstrap';
import { SupportEmail } from '../../doc/components/ServiceName';

export function DataProductList({ dispatch, products, selectedProductIds = [],
	productFilter, setProductFilter, showOnlySelected, access_token, isBookmarklistFull = false,
	bookmarkedProductIs = [] }) {

	var theProductsToShow = products
	if (showOnlySelected) {
		theProductsToShow = products.filter(product => selectedProductIds.includes(product.id))
	}

	if (products.length > 0) {
		return <Container>
			{theProductsToShow.map(product => {
				try {
					return <DataProductListEntry
						dispatch={dispatch}
						key={product.id}
						product={product}
						access_token={access_token}
						selected={selectedProductIds.includes(product.id)}
						thumbnailImage={toImage(product.thumbnailURL, access_token)}
						quicklookImage={toImage(product.quicklookURL, access_token)}
						productFilter={productFilter}
						setProductFilter={setProductFilter}
						bookmarkCtrl={new BookmarkCtrl(bookmarkedProductIs.includes(product.id),
							isBookmarklistFull)}
					/>
				} catch (e) {
					return <Row>
						<Col >Sorry, something went wrong, this entry cannot be visualised. Try reload, if the problem persists, please inform us via <SupportEmail body={"Could not render " + JSON.stringify(product)}/></Col>
					</Row>
				}
			})}
		</Container>
	}
	else {
		return <Container>
			<p>Nothing to show yet. Expand your search or bookmark search results.
			</p>
		</Container>
	}
}
