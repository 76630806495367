/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import './LoadingSpinner.css'


export function LoadingSpinner({ loading=false, children }) {
	if (loading) {
		return <Container className="h-100" fluid>
			<Row className="h-100">
				<Col></Col>
				<Col xs="auto" className="my-auto" >
					<Spinner animation="border text-secondary" role="status">
						<span className="visually-hidden">Loading...</span>
					</Spinner>
				</Col>
				<Col></Col>
			</Row>
		</Container>
	} else {
		return children;
	}
}