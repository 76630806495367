/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { SN } from '../doc/components/ServiceName'
//import { Tbl, TblHead, TblBody, TblRow } from '../doc/components/DocTable'
import Button from 'react-bootstrap/Button'
import { login, register } from "../../state/session"
import FeatureInfoCard from './FeatureInfoCard'

export const FEATURES = [
	{ 'Description': 'Allows to search in-situ, IoT and earth observation data through a common search function on a variety of metadata.', 'FunctionArea': 'Discovery', 'Label': 'Data discovery' },
	{ 'Description': 'Download directly from the service interfaces. Convenient link to Copernicus integrity check service.', 'FunctionArea': 'Access', 'Label': 'Data download' },
	{ 'Description': 'Results can be exported as csv lists for future reference.', 'FunctionArea': 'Discovery', 'Label': 'Search result export' },
	{ 'Description': 'Allows to store own in-situ or IoT data. Data streams can be stored in configurable channels. The stored data is subsequently available for search by the user. ', 'FunctionArea': 'Storage', 'Label': 'In-situ and IoT data submission' },
	{ 'Description': 'Processing resources can be booked in a flexible way and a basic option for occasional processing and testing is available. ', 'FunctionArea': 'Processing', 'Label': 'Flexible booking of processing resources' },
	{ 'Description': 'The service can not only be used comfortably on a desktop but equally well on tablets and smart phones.', 'FunctionArea': 'Access', 'Label': 'Responsive design of the user interface' },
	{ 'Description': 'Users can store their own in-situ data economically.', 'FunctionArea': 'Storage', 'Label': 'Generous storing quota' },
	{ 'Description': 'Covers a wide range of free and open earth observation data that can be downloaded directly from the application. The current focus is on the Copernicus Sentinels missions.', 'FunctionArea': 'Data', 'Label': 'EO Data Index' },
	{ 'Description': 'Most functions that are available via the user interface can also be used via clear and well documented REST API interfaces.', 'FunctionArea': 'Access', 'Label': 'API access' },
	{ 'Description': 'For ease of communication on earth observation data we provide convenient short links that can be easily shared. The link can be opened by anyone.', 'FunctionArea': 'Discovery', 'Label': 'Ultra short links to data pages' },
	{ 'Description': 'Offers a selection of in-situ data available for download to payed subscribtions. Available data includes ship position data (AIS), Planes position data (ADS-B) as well es selected other sensors.', 'FunctionArea': 'Data', 'Label': 'In-situ data' },
	{ 'Description': 'Hosted processing is supported with configurable workflows using the Common Workflow Language (CWL).', 'FunctionArea': 'Processing', 'Label': 'Hosted processing function' },
	{ 'Description': 'Support functions to find in-situ data that is relevant for a given earth observation data product.', 'FunctionArea': 'Discovery', 'Label': 'In-situ data earth observation product association' },
	{ 'Description': 'Interesting searches that you may want to repeat in the future against an updated service content can conveniently be stored and recovered.', 'FunctionArea': 'Discovery', 'Label': 'Stored searches' },
	{ 'Description': 'Supporting IoT or in-situ data submission there is a node-red plugin that can be used to pass data seamlessly from IoT networks.', 'FunctionArea': 'Storage', 'Label': 'Node-Red Plugin for data submission' },
	{ 'Description': 'Usage information is readily available from within the service.', 'FunctionArea': 'Access', 'Label': 'Integrated online help and documentation' }]


export default function HomePageContent() {
	return (<>
		<h2> Welcome to <SN /></h2>

		<p><SN /> supports you in finding, accessing and managing the data you need in a convenient
			way across many platforms.</p>
		<p>
			Please <Button variant="primary" onClick={register}>Register</Button> or <Button variant="primary" onClick={login}>Login</Button> to get productive.
		</p>
		<p>&nbsp;</p>

		<h2>Features</h2>
		<div className='d-flex flex-wrap align-items-stretch justify-content-between'>
			{FEATURES.map((f, i) => <div className="m-3 bg-white" key={i} ><FeatureInfoCard feature={f} /></div>)}
		</div>

	</>)
}