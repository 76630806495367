/* tslint:disable */
/* eslint-disable */
/**
 * p:scout Search API
 * This is the Search API of p:scout. build version is @@@CI_BUILD_VERSION@@@
 *
 * OpenAPI spec version: @@@CI_COMMIT_REF_NAME@@@
 * Contact: info@petabite.eu
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { AggregatedChannel } from './aggregated-channel';
import { Association } from './association';
import { BoundingBox } from './bounding-box';
import { DisplayChannel } from './display-channel';
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    published?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    lastModified?: Date;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    productClass?: ProductProductClassEnum;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    productType?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    productName?: string;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    temporalCoverageStart?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    temporalCoverageEnd?: Date;
    /**
     * a string containing GeoJson. Use e.g. javascripts `JSON.parse(spatialCoverage)` to parse
     * @type {string}
     * @memberof Product
     */
    spatialCoverageGeoJson?: string;
    /**
     * 
     * @type {BoundingBox}
     * @memberof Product
     */
    spatialCoverageBoundingBox?: BoundingBox;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    productSizeInBytes?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Product
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Array<Association>}
     * @memberof Product
     */
    associations?: Array<Association>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    thumbnailURL?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    quicklookURL?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    shortLinkURL?: string;
    /**
     * @type {Array<AggregatedChannel>}
     * @memberof Product
     */
    channels?: Array<AggregatedChannel>;

    /**
     * @type {Array<string>}
     * @memberof Product
     */
    aggregationLevels?: Array<ProductAggregationLevelsEnum>;

    /**
     * @type {DisplayChannel}
     * @memberof Product
     */
    displayChannel?: DisplayChannel;

    /**
     * @type {{ [key: string]: string | Date | number; }}
     * @memberof Product
     */
    properties?: { [key: string]: string | Date | number; };
}

/**
    * @export
    * @enum {string}
    */
export enum ProductProductClassEnum {
    EO = 'EO',
    InSitu = 'InSitu'
}
/**
 * @export
 * @enum {string}
 */
export enum ProductAggregationLevelsEnum {
    HOUR = 'HOUR',
    DAY = 'DAY',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
    ALLTIME = 'ALLTIME'
}

