import { useEffect, useState } from "react";
import { Button, CardBody, CardHeader, Col, Row } from "react-bootstrap";
import { NetworkConfiguration } from "./NetworkConfiguration";
import { Card } from "react-bootstrap";
import { networkIcon } from "../data/DataProduct/DataProductAttributeIcons";
import { pscoutApi } from '../../state/pscoutApi';
import { ObservationChannel, ObservationChannelType, ObservationNetwork } from "../../utils/types";

interface NetworkCardProps {
    network: ObservationNetwork
    setNetwork: (n: ObservationNetwork) => void
}

function NetworkCard({ network, setNetwork }: NetworkCardProps) {
    const selectHandler = (p: any) => {
        setNetwork(network)
    }
    const MAX_SHOW_CHANNELS = 5
    const notShownChannels = network.observationType?.channels?.length - MAX_SHOW_CHANNELS

    return <Card className="card bg-white p-2 m-3" onClick={selectHandler}>
        <CardHeader>
            {networkIcon} {network.networkName}
        </CardHeader>
        <CardBody>
            <h4>{network.networkLabel}</h4>
            <p>Observations of type: <span className="fw-bold">{network.observationType?.typeName} - {network.observationType?.typeVersion}</span></p>
            <p>
                Channels: {network.observationType?.channels?.slice(0, MAX_SHOW_CHANNELS).map((c, index) => <span className="fw-bold" key={c.name}>{index > 0 ? ", " : ""} {c.name}</span>)}
                {notShownChannels > 0 && <><span> and </span> <span className="fw-bold">{notShownChannels}</span><span> more </span>  </>}
            </p>
            {network.observationType?.emergingThingType && <p>This is a network of <span className="fw-bold">emerging things</span>.</p>}
        </CardBody>
    </Card>
}


export function newChannelType(channelName: string) {
    return {
        name: channelName,
        label: "",
        description: "",
        channelType: ObservationChannelType.FLOAT,
    } as ObservationChannel
}



interface NetworkConfigurationsProps {
    userProfile: any
}

export function NetworkConfigurations({ userProfile }: NetworkConfigurationsProps) {
    const [selectedNetwork, setSelectedNetwork] = useState(null as unknown as ObservationNetwork | null)
    const [publicNetworks, setPublicNetworks] = useState([] as Array<ObservationNetwork>)
    const [ownerNetworks, setOwnerNetworks] = useState([] as Array<ObservationNetwork>)
    useEffect(() => {
        const getInfo = async () => {
            pscoutApi.getPublicNetworkInfo().then(networksInfo => {
                setPublicNetworks(networksInfo.items)
            }
            ).catch(error => {
                console.log(error)
            })
            pscoutApi.getNetworkInfo(userProfile.id).then(networksInfo => {
                setOwnerNetworks(networksInfo.items)
            }
            ).catch(error => {
                console.log(error)
            })


        }
        getInfo()

    }, [userProfile.id])


    if (selectedNetwork) {
        return <div>
            <Row className="p-3">
                <Col><h2>{selectedNetwork.networkName}</h2></Col>
                <Col className="col-auto"><Button onClick={(p) => setSelectedNetwork(null)}> Exit</Button></Col>
            </Row>
            <Row className="p-3">
                <NetworkConfiguration networkDefinition={selectedNetwork} editable={false} />
            </Row>
        </div>
    }
    else {
        return <>
            <Row className="p-2"><h2>Public networks (information only)</h2></Row>
            <Row className="p-2">
                {publicNetworks.map((n) => <Col xs={12} md={6} lg={3} ><NetworkCard key={n.networkName} network={n} setNetwork={setSelectedNetwork} /></Col>)}
            </Row>

            <Row className="p-2"><h2>Your networks (Owner: {userProfile.username})</h2></Row>
            <Row>
                {ownerNetworks.map((n) => <Col xs={12} md={6} lg={3} ><NetworkCard key={n.networkName} network={n} setNetwork={setSelectedNetwork} /></Col>)}
            </Row>

        </>
    }
}