import { pscoutApi } from "./pscoutApi";

export interface ieotoApiLink {
    href: string
    rel: string
    type: string
    title: string

}
export interface Process {
    description: string
    id: string
    jobControlOptions: string
    links: Array<ieotoApiLink>
    mutable: boolean
    outputTransmission: string
    title: string
    version: string
}

export interface InputDescriptorType {
    title: string
    description: string,
    maxOccurs: number
    schema: {
        type: string
        default: any
    }
}

export interface OutputDescriptorType {
    title: string
    schema: {
        type: string
        default: any
    }
}

export interface WorkflowDetailsType {
    id: string
    title: string
    description: string
    mutable: boolean
    version: string
    outputTransmission: any
    jobControlOptions: Array<string>
    links: Array<ieotoApiLink>
    inputs: { [key: string]: InputDescriptorType }
    outputs: { [key: string]: OutputDescriptorType }
}

/*

{"jobID":"7cb704da-20d2-11ee-95c5-a6e5fa1e49b8","type":"process","processID":"test-app-1_0_0","status":"successful","message":"ZOO-Kernel successfully run your service!","links":[{"title":"Status location","rel":"status","type":"application/json","href":"/processing/f0b6a356-96e1-465f-879d-87f834ea993f/wps3/jobs/7cb704da-20d2-11ee-95c5-a6e5fa1e49b8"},{"title":"Result location","rel":"http://www.opengis.net/def/rel/ogc/1.0/results","type":"application/json","href":"/processing/f0b6a356-96e1-465f-879d-87f834ea993f/wps3/jobs/7cb704da-20d2-11ee-95c5-a6e5fa1e49b8/results"}]}

*/

export interface JobType {
    jobID: string
    type: string
    processID: string
    status: string
    message: string
    links: Array<ieotoApiLink>
}

export interface JobStatusType {

    jobID: string
    type: string
    processID: string
    status: string
    message: string
    links: Array<ieotoApiLink>
}

export interface JobResultsType {
    output_catalog: string
}

export function hasProcessingRights(userId: string) {
    return true
}

const excludeIds = ['GetStatus', 'DeployProcess', 'UndeployProcess']

/**
 * 
{'links': [{'href': '/processing/f0b6a356-96e1-465f-879d-87f834ea993f/wps3/processes',
            'rel': 'self',
            'type': 'application/json'}],
 'numberTotal': 4,
 'processes': [{'description': 'Create an ExecuteResponse document from a sid '
                               'in background. ',
                'id': 'GetStatus',
                'jobControlOptions': ['sync-execute',
                                      'async-execute',
                                      'dismiss'],
                'links': [{'href': '/processing/f0b6a356-96e1-465f-879d-87f834ea993f/wps3/processes/GetStatus',
                           'rel': 'self',
                           'title': 'Process Description',
                           'type': 'application/json'}],
                'mutable': False,
                'outputTransmission': ['value', 'reference'],
                'title': 'Produce an updated ExecuteResponse document. ',
                'version': '1.0.0'},
               {'description': 'Pscout dummy processor',
                'id': 'test-app-1_0_0',
                'jobControlOptions': ['async-execute'],
                'links': [{'href': '/processing/f0b6a356-96e1-465f-879d-87f834ea993f/wps3/processes/test-app-1_0_0',
                           'rel': 'self',
                           'title': 'Process Description',
                           'type': 'application/json'}],
                'mutable': True,
                'outputTransmission': ['value', 'reference'],
                'title': 'pb-ades-test-processor',
                'version': '1.0.0'}]}
 * 
 * 
 * 
 * @param userId 
 */
export async function getAvailableWorkflows(userId: string) {
    const requestPath = `/processing/${userId}/wps3/processes`
    const rval = await pscoutApi.apiGetRequest(requestPath)
    return (rval['processes'] as Array<Process>).filter((process) => !excludeIds.includes(process.id)) as Array<Process>
}


export async function getWorkflowDetails(detailsLink: ieotoApiLink) {
    const rval = await pscoutApi.apiGetRequest(detailsLink.href)
    return rval as WorkflowDetailsType
}

export async function getWorkflowResults(resultsLink: ieotoApiLink) {
    const rval = await pscoutApi.apiGetRequest(resultsLink.href)
    return rval as JobResultsType
}

export async function getJobs(userId: string) {
    const requestPath = `/processing/${userId}/wps3/jobs`
    const rval = await pscoutApi.apiGetRequest(requestPath)
    //return rval['jobs'] as Array<JobType>
    return (rval['jobs'] as Array<JobType>).filter((job) => !excludeIds.includes(job.processID)) as Array<JobType>
}
export async function getJobStatus(resultsLink: ieotoApiLink) {
    const rval = await pscoutApi.apiGetRequest(resultsLink.href)
    return rval as JobStatusType
}