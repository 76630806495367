import { Form } from "react-bootstrap";
import { ExtendedConfigEditorProps } from "./ChannelConfiguration";


export function PositionConfigEditor({ editable, channelName, channel, updateChannel }: ExtendedConfigEditorProps) {
    return <>
        <span>Latitude Channel</span>
        <Form.Control disabled={!editable} type="text" value={channel.latitudeName ?? ""} onChange={(e) => {
            channel.latitudeName = e.target.value
            console.log(channel)
            updateChannel(channelName, channel)
        }}>

        </Form.Control>
        <span>Longitude Channel</span>
        <Form.Control disabled={!editable} type="text" value={channel.longitudeName ?? ""} onChange={(e) => {
            channel.longitudeName = e.target.value
            updateChannel(channelName, channel)
        }}>
        </Form.Control>

    </>
}