/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import Button from 'react-bootstrap/Button'
import { hintIcon } from '../Icons/Icons'
import { DEFAULT_VARIANT } from '../Icons/IconButton'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import PopoverBody from 'react-bootstrap/PopoverBody'

export function PopupInfoButton({ children }) {

	return (
		<OverlayTrigger trigger="click" placement="left" rootClose={true} className="px-0 w-100" overlay={<Popover id="popover-basic" >

			<PopoverBody className="bg-primary">
				<div className="bg-light rounded p-1">{children}</div>
			</PopoverBody>
		</Popover>}  >
			<Button variant={DEFAULT_VARIANT}>{hintIcon}</Button>
		</OverlayTrigger>
	);
}