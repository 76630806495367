/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown'
import './SafetyButton.scss'

export function SafetyButton({ onClick, icon, label }) {
	return (
		<Dropdown drop='down' >
			<Dropdown.Toggle variant="white" className='safetyButton' id="dropdown-basic">{icon} {label}</Dropdown.Toggle>
			<Dropdown.Menu>
				<Dropdown.Item onClick={onClick}>{label}</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	)
}
